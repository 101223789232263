import React from 'react';
import { equals } from 'ramda';

import LoadingSpinner from '../../../components/LoadingSpinner';
import ErrorAlert from '../../../components/ErrorAlert';
import useAbility from '../../../hooks/useAbility';
import useTenant from '../../../hooks/useTenant';
import useQueryTenantClusters from '../../../hooks/useQueryTenantClusters';
import useQueryAllClusters from '../../../hooks/useQueryAllClusters';
import SearchableTable from '../../../components/SearchableTable';
import { VIEW_ANY_CLUSTER, VIEW_ORG_CLUSTER } from '../../../system/abilities';
import {
    anyIsLoading,
    isEmptyOrNil,
    isNotEmptyOrNil,
} from '../../../utils/common';
import columns from './Clusters-columns';
import PageContainer from '../../../components/PageContainer';

function compareClustersByName(a, b) {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    let comparison = 0;
    if (nameA > nameB) comparison = 1;
    else if (nameA < nameB) comparison = -1;
    return comparison;
}

export function AllClusters() {
    const { data, loading, error } = useQueryAllClusters();

    if (loading) return <LoadingSpinner />;

    if (error) return <ErrorAlert error={error} />;

    const sortedData = data.slice().sort(compareClustersByName);

    return <SearchableTable columns={columns} data={sortedData} allowSearch />;
}

export function OrganizationClusters({ organizationId }) {
    const { data, loading, error } = useQueryTenantClusters(organizationId);

    if (loading) return <LoadingSpinner />;

    if (error) return <ErrorAlert error={error} />;

    const sortedData = data.slice().sort(compareClustersByName);

    return <SearchableTable columns={columns} data={sortedData} allowSearch />;
}

export default function Clusters() {
    const [tenant] = useTenant();
    const canViewAnyClusterResponse = useAbility(VIEW_ANY_CLUSTER);
    const canViewOrgClusterResponse = useAbility(VIEW_ORG_CLUSTER);

    if (anyIsLoading([canViewAnyClusterResponse, canViewOrgClusterResponse])) {
        return <LoadingSpinner />;
    }

    if (isEmptyOrNil(tenant)) {
        if (canViewAnyClusterResponse.data) {
            return (
                <PageContainer>
                    <AllClusters />
                </PageContainer>
            );
        } else {
            return <ErrorAlert />;
        }
    }

    if (isNotEmptyOrNil(tenant)) {
        if (canViewOrgClusterResponse.data) {
            return (
                <PageContainer>
                    <OrganizationClusters
                        organizationId={tenant.id}
                        organizationName={tenant.name}
                    />
                </PageContainer>
            );
        }
        if (equals(false, canViewOrgClusterResponse.data)) {
            return <ErrorAlert />;
        }
    }

    return null;
}
