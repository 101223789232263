import React from 'react';
import { Button, Pane, Text, TextInputField } from 'evergreen-ui';
import { equals } from 'ramda';
import styled from 'styled-components';

import { useStateContext } from '../../../../context/createStateReducerContext';

const InputContainer = styled(Pane)`
    border: 1px solid #ef5350;
    padding: 2rem;
`;

const DeleteButton = styled(Button)`
    background: #ef5350;
    color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    &:hover,
    &:active {
        background: #f27573 !important;
    }

    &:focus {
        box-shadow: 0 0 0 3px #ee534f3d, inset 0 0 0 1px #ee534f3d,
            inset 0 -1px 1px 0 #ee534f3d !important;
    }
`;

const DangerZoneSection = ({ handleRemove }) => {
    const state = useStateContext();
    const [value, setValue] = React.useState('');
    const setInputValue = (e) => setValue(e.target.value);

    const disabled = !equals(value, state.name);

    return (
        <Pane>
            <Pane marginBottom="1rem">
                <Text>
                    To delete this forwarder confirm the name in the input
                    below.
                </Text>
            </Pane>
            <InputContainer>
                <TextInputField
                    label={`Enter Value To Delete: ${state.name}`}
                    value={value}
                    onChange={setInputValue}
                />
                <DeleteButton
                    intent="danger"
                    appearance="primary"
                    disabled={disabled}
                    onClick={handleRemove}
                >
                    Delete
                </DeleteButton>
            </InputContainer>
        </Pane>
    );
};

export default DangerZoneSection;
