import { pathOr, propOr } from 'ramda';
import { Button } from 'evergreen-ui';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import CommonForwarderFormInputs from '../../../../components/CommonForwarderFormInputs';
import DestinationTagMenuInput from '../../../../components/DestinationTagMenuInput';
import ErrorAlert from '../../../../components/ErrorAlert';
import LoadingSpinner from '../../../../components/LoadingSpinner';
// import withRequireAbility from '../../hocs/withRequireAbility';
import useCreateCDPForwarderMutation from '../../../../hooks/useCreateCDPForwarderMutation';
import useTenant from '../../../../hooks/useTenant';
import useQueryTenantClusters from '../../../../hooks/useQueryTenantClusters';
import useQueryTenantCdpIdentites from '../../../../hooks/useQueryTenantCdpIdentities';
import {
    anyIsLoading,
    anyHasError,
    filterValueFromArray,
    isNotEmptyOrNil,
} from '../../../../utils/common';
// import { CREATE_ANY_CDP_FORWARDER } from '../../system/abilities';

function DeployCDPCyclopsForwarder() {
    const [tenant] = useTenant();
    const tenantId = propOr('', 'id', tenant);

    const queryCdpIdentitesResponse = useQueryTenantCdpIdentites(tenantId);
    const queryClustersResponse = useQueryTenantClusters(tenantId);
    const identities = propOr([], 'data', queryCdpIdentitesResponse);
    const clusters = propOr([], 'data', queryClustersResponse);

    const [name, setName] = useState('');
    const [destinationRef, setDestinationRef] = useState([]);
    const [selectedIdentity, setSelectedIdentity] = useState('');
    const [selectedCluster, setSelectedCluster] = useState('');

    const onRemoveDestinationRefTag = (value) =>
        setDestinationRef(filterValueFromArray(value, destinationRef));
    const deselectDestinationRef = ({ value }) =>
        setDestinationRef(filterValueFromArray(value, destinationRef));
    const selectDestinationRef = ({ value }) => {
        const refsWithValue = [...destinationRef, value];
        setDestinationRef(refsWithValue);
    };
    const syslog = {
        port: 31000,
        tlsEnabled: null,
        tlsRawCert: null,
        tlsRawKey: null,
    };

    /* mutation props */
    const baseCollector = {
        syslog,
        tagId: uuidv1(),
        collectorType: 'syslog',
        common: {
            batchEveryBytes: 1048576,
            batchEverySeconds: 10,
            batchFullThreshold: 0.8,
            flushThreadCount: 8,
            comment: null,
            dataHint: null,
            dataType: 'CYDERES_TEST_EVENT',
            enabled: true,
            destinationRef: [],
        },
    };
    const configuration = {
        collectors: [baseCollector],
        infrastructure: {
            replicaCount: 2,
            workerCount: 1,
            bufferTime: '20s',
            batchFullThreshold: 0.8,
            flushThreadCount: 8,
        },
        destinationRef,
    };

    const [
        createCDPForwarder,
        createCDPForwarderResponse,
    ] = useCreateCDPForwarderMutation({
        clusterId: selectedCluster,
        forwarderName: name,
        forwarderConfiguration: configuration,
        identityId: selectedIdentity,
        organizationId: tenantId,
    });

    const apolloResponses = [
        queryCdpIdentitesResponse,
        queryClustersResponse,
        createCDPForwarderResponse,
    ];

    if (anyIsLoading(apolloResponses)) {
        return <LoadingSpinner />;
    }

    if (anyHasError(apolloResponses)) {
        const [error] = apolloResponses.filter((res) =>
            isNotEmptyOrNil(res.error),
        );
        return <ErrorAlert error={error} />;
    }

    if (createCDPForwarderResponse.data.deployment) {
        const deploymentLogId = pathOr(
            '',
            ['data', 'deployment', 'cdpForwarder', 'deployCDPApp', 'id'],
            createCDPForwarderResponse,
        );
        const forwarderId = pathOr(
            '',
            ['data', 'cdpForwarder', 'createCDPForwarder', 'id'],
            createCDPForwarderResponse,
        );
        return (
            <Redirect
                to={`/admin/forwarders/cdp/${tenantId}/${deploymentLogId}/${forwarderId}`}
            />
        );
    }

    return (
        <>
            <CommonForwarderFormInputs
                name={name}
                setName={setName}
                clusters={clusters}
                selectedCluster={selectedCluster}
                setSelectedCluster={setSelectedCluster}
                identities={identities}
                selectedIdentity={selectedIdentity}
                setSelectedIdentity={setSelectedIdentity}
            />
            <DestinationTagMenuInput
                onDeselect={deselectDestinationRef}
                onSelect={selectDestinationRef}
                onRemove={onRemoveDestinationRefTag}
                selected={destinationRef}
            />
            <Button
                disabled={destinationRef.length < 1}
                marginTop="2vh"
                onClick={createCDPForwarder}
            >
                Deploy
            </Button>
        </>
    );
}

export default DeployCDPCyclopsForwarder;
