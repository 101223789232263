import { useQuery } from '@apollo/client';
import { flatten, propOr } from 'ramda';

import { QUERY_FORWARDER_IDENTITIES } from '../ForwarderIdentities-graphql';

function useQueryAllForwarderIdentities() {
    const { data, loading, error } = useQuery(QUERY_FORWARDER_IDENTITIES);
    const cdpForwarderIdentities = propOr([], 'cdpForwarderIdentities', data);
    const chronicleForwarderIdentities = propOr(
        [],
        'chronicleForwarderIdentities',
        data,
    );

    let _data = [];
    if (data) {
        _data = flatten([cdpForwarderIdentities, chronicleForwarderIdentities]);
    }

    const response = {
        data: _data,
        loading,
        error,
    };

    return response;
}

export default useQueryAllForwarderIdentities;
