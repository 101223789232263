import { useMutation } from '@apollo/client';
import { toaster } from 'evergreen-ui';
import { prop } from 'ramda';

import useQueryAllOrganizations from '../../../hooks/useQueryAllOrganizations';
import useQuerySelector from '../../../hooks/useQuerySelector';
import { QUERY_ALL_CLUSTERS } from '../../../graphql/queries';
import { anyIsLoading } from '../../../utils/common';

import { QUERY_CLUSTER, MUTATE_CLUSTER_ORG } from './Cluster-graphql';

const useMutateCluster = (id, organizationId) => {
    const [mutate, response] = useMutation(MUTATE_CLUSTER_ORG, {
        variables: {
            id,
            props: { organizationId },
        },
        onError: () => {
            toaster.danger('ERROR: Unable to update cluster.');
        },
        onCompleted: () => {
            toaster.success('Success!');
        },
        refetchQueries: [{ query: QUERY_ALL_CLUSTERS }],
    });

    return [mutate, response];
};

const useQueryClusterAndOrganizations = (id) => {
    const clusterResponse = useQuerySelector(
        QUERY_CLUSTER,
        { variables: { id } },
        prop('cluster'),
    );
    const organizationsResponse = useQueryAllOrganizations();

    let error = null;
    if (clusterResponse.error) {
        error = clusterResponse.error;
    }
    if (organizationsResponse.error) {
        error = organizationsResponse.error;
    }

    return {
        data: {
            cluster: clusterResponse.data,
            organizations: organizationsResponse.data,
        },
        loading: anyIsLoading([organizationsResponse, clusterResponse]),
        error,
    };
};

export { useMutateCluster, useQueryClusterAndOrganizations };
