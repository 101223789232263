import React from 'react';
import { TextInputField } from 'evergreen-ui';
import PropTypes from 'prop-types';

const FormInputField = ({ label, error, register, ...rest }) => (
    <TextInputField
        label={label}
        isInvalid={Boolean(error)}
        validationMessage={error}
        ref={register}
        {...rest}
    />
);

FormInputField.propTypes = {
    label: PropTypes.string,
    register: PropTypes.func.isRequired,
    error: PropTypes.string,
};

FormInputField.defaultProps = {
    label: '',
    error: null,
};

export default FormInputField;
