import { gql } from '@apollo/client';

export const QUERY_ALL_ORG_CLOUD_FORWARDERS = gql`
    query queryOrgCloudCollectors($id: ID!) {
        organization(id: $id) {
            # chronicleCloudSyslogForwarders(cursor: { limit: 0 }) {
            #     id
            #     name
            #     configuration {
            #         syslog {
            #             port
            #             common {
            #                 dataType
            #                 enabled
            #             }
            #         }
            #     }
            # }
            chronicleCloudPcapForwarders(cursor: { limit: 0 }) {
                id
                name
                configuration {
                    pcap {
                        common {
                            dataType
                            enabled
                        }
                    }
                }
            }
            # chronicleCloudSplunkForwarders(cursor: { limit: 0 }) {
            #     id
            #     name
            #     configuration {
            #         splunk {
            #             common {
            #                 dataType
            #                 enabled
            #             }
            #         }
            #     }
            # }
            cdpCloudForwarders(cursor: { limit: 0 }) {
                id
                name
                configuration {
                    collectors {
                        port
                        common {
                            dataType
                            enabled
                        }
                    }
                    destinationRef
                }
            }
        }
    }
`;
