import { gql } from '@apollo/client';
import { ascend, compose, prop, map, sortWith } from 'ramda';

import useQuerySelector from '../useQuerySelector';

const QUERY_DATA_TYPES = gql`
    query queryDataTypes {
        logTypes(cursor: { limit: 0 }) {
            logType
        }
    }
`;

const shimArray = (values, valuesToAdd) => [...values, ...valuesToAdd];
const logTypeProp = prop('logType');
const sortAlphabetically = sortWith([ascend(logTypeProp)]);
const getAllLogTypes = map(logTypeProp);
const shimAndSortLogTypes = compose(
    getAllLogTypes,
    sortAlphabetically,
    shimArray,
);

function useFetchDataTypes() {
    const response = useQuerySelector(QUERY_DATA_TYPES, {}, prop('logTypes'));

    const missingLogTypes = [
        { logType: 'PCAP_DNS' },
        { logType: 'PCAP_DHCP' },
        { logType: 'CATCH_ALL' },
        { logType: 'NETFLOW' },
        { logType: 'CWT_YEEGO' },
        { logType: 'CWT_AQUA' },
        { logType: 'CWT_BOOK2GO' },
        { logType: 'CWT_CCRECON' },
        { logType: 'CWT_GLOBAL_MATRIX' },
        { logType: 'CWT_MDF' },
        { logType: 'CWT_PORTRAIT' },
        { logType: 'CWT_POWER_BASE' },
        { logType: 'CWT_POWER_EXPRESS' },
        { logType: 'CWT_POWER_LIBRARY' },
        { logType: 'CWT_POWER_TURBO' },
        { logType: 'CWT_POWER_VAULT' },
        { logType: 'CWT_TRAVCOM' },
        { logType: 'CWT_CXP' },
        { logType: 'CWT_FINANCE' },
        { logType: 'CWT_GPM' },
        { logType: 'CWT_MYCWTCHINA_OBT' },
        { logType: 'CWT_MYCWT_MOBILE' },
        { logType: 'CWT_MYCWT_WEB' },
        { logType: 'CWT_POWER_HOTEL' },
        { logType: 'CWT_PROTEGRITY' },
        { logType: 'CWT_ROOMIT' },
        { logType: 'CWT_SATOTRAVEL_FORMS' },
        { logType: 'CWT_SATOVACATIONS' },
        { logType: 'CWT_SATOVACATIONSEUROPE' },
        { logType: 'CWT_TEAMMATE' },
        { logType: 'CWT_VACATIONS' },
        { logType: 'CWT_VERINT' },
        { logType: 'CWT_WINGS' },
        { logType: 'CWT_WORLDSPAN' },
        { logType: 'MOOM_AMUI_IAM' },
        { logType: 'MOOM_SDR' },
        { logType: 'MOOM_WEBBILLPAY' },
        { logType: 'ANDERSONS_LTAS_ERROR' },
        { logType: 'ANDERSONS_RMS_ERROR' },
        { logType: 'BAND_BACKOFFICE' },
        { logType: 'BAND_VCC' },
        { logType: 'BAND_EVS_DASHBOARD' },
        { logType: 'BAND_ONE_ID' },
        { logType: 'BAND_ASTERISK' },
        { logType: 'BAND_IRIS_WEB_PORTAL' },
        { logType: 'BAND_WCS' },
        { logType: 'NOMI_BRIDGEKEEPER' },
        { logType: 'DERIVED' },
        { logType: 'OCSP' },
        { logType: 'KRS' },
        { logType: 'CYDERES_TEST_EVENT' },
        { logType: 'ENUF_POSTGRES' },
        { logType: 'ENUF_SECURITY_MGR' },
        { logType: 'ENUF_IDAAS' },
        { logType: 'ENUF_DERIVED' },
        { logType: 'ENUF_OCSP' },
        { logType: 'ENUF_KRS' },
        { logType: 'ENTR_KCAAS' },
        { logType: 'CODE42_INCYDR' },
        { logType: 'CWT_SCA' },
        { logType: 'CWT_NDC' },
        { logType: 'CWT_CIP' },
        { logType: 'CTS_ONLINE_BANKING_LOGIN' },
        { logType: 'ANDERSONS_LMS_ERROR' },
        { logType: 'WSI_OAUTH' },
        { logType: 'TRADE_APP_LOGS' },
        { logType: 'JUMP_CMD_REPLACEMENT' },
        { logType: 'F9_VCC_FDM' },
        { logType: 'EVIDOS_FIREWALL' },
        { logType: 'OPENVAS' },
        { logType: 'SEQ' },
        { logType: 'RABBITMQ' },
        { logType: 'REDIS' },
        { logType: 'ASCERTIA' },
        { logType: 'GCP_SECURITYCENTER_VULNERABILITY' },
        { logType: 'TTPORTAL_DB38' },
    ];

    let dataTypes = [];
    if (response.data) {
        dataTypes = shimAndSortLogTypes(response.data, missingLogTypes);
    }

    return dataTypes;
}

export default useFetchDataTypes;
