import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { toaster } from 'evergreen-ui';

import { QUERY_ALL_ORG_FORWARDERS } from '../../graphql/queries';
import { anyIsLoading } from '../../utils/common';

export const CREATE_CDP_CONFIG = gql`
    mutation createCdpConfig($props: CreateCDPForwarderInput!) {
        createCDPForwarder(props: $props) {
            id
            name
        }
    }
`;

export const DEPLOY_CDP_FORWARDER = gql`
    mutation deployCdpForwarder(
        $appName: String!
        $clusterId: ID!
        $cdpForwarderId: ID!
        $cdpForwarderIdentityId: ID!
    ) {
        cdpForwarder(id: $cdpForwarderId) {
            deployCDPApp(
                props: {
                    name: $appName
                    environmentId: $clusterId
                    cdpForwarderIdentityId: $cdpForwarderIdentityId
                }
            ) {
                id
                name
                status
                error {
                    code
                    status
                    message
                }
            }
        }
    }
`;

const useCreateCDPForwarderMutation = ({
    clusterId,
    forwarderName,
    forwarderConfiguration,
    identityId,
    organizationId,
}) => {
    const [newForwarderId, setNewForwarderId] = useState(null);

    const [deployCDPForwarder, deployCDPForwarderRes] = useMutation(
        DEPLOY_CDP_FORWARDER,
        {
            variables: {
                cdpForwarderId: newForwarderId,
                clusterId: clusterId,
                cdpForwarderIdentityId: identityId,
                appName: forwarderName,
            },
            refetchQueries: [
                {
                    query: QUERY_ALL_ORG_FORWARDERS,
                    variables: {
                        id: organizationId,
                    },
                },
            ],
            awaitRefetchQueries: true,
            onError: () =>
                toaster.danger('Unable to perform action at this time.'),
            onCompleted: () =>
                toaster.success(
                    'Successfully created new forwarder. Please wait for status update before making any additional changes.',
                ),
        },
    );
    const [createCDPForwarder, createCDPForwarderRes] = useMutation(
        CREATE_CDP_CONFIG,
        {
            variables: {
                props: {
                    organizationId: organizationId,
                    name: forwarderName,
                    configuration: forwarderConfiguration,
                },
            },
            onError: () =>
                toaster.danger('Unable to perform action at this time.'),
            onCompleted: async (data) => {
                await setNewForwarderId(data.createCDPForwarder.id);
                deployCDPForwarder();
            },
        },
    );

    let error;
    if (createCDPForwarderRes.error) {
        error = createCDPForwarderRes.error;
    }
    if (deployCDPForwarderRes.error) {
        error = deployCDPForwarderRes.error;
    }

    const response = {
        data: {
            deployment: deployCDPForwarderRes.data,
            cdpForwarder: createCDPForwarderRes.data,
        },
        loading: anyIsLoading([createCDPForwarderRes, deployCDPForwarderRes]),
        error,
    };

    return [createCDPForwarder, response];
};

export default useCreateCDPForwarderMutation;
