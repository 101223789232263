import { gql, useQuery } from '@apollo/client';
import { pathOr, prop } from 'ramda';

// import { tenantReactiveVar } from '../../cache';

export const CURRENT_TENANT = gql`
    query tenant {
        tenant @client
    }
`;

// const updateTenant = (tenant) => tenantReactiveVar(tenant);

export default function useTenant() {
    const request = useQuery(CURRENT_TENANT, {}, prop('tenant'));
    const tenant = pathOr(null, ['data', 'tenant'], request);
    function setTenant(tenant) {
        request.client.writeQuery({ query: CURRENT_TENANT, data: { tenant } });
    }
    return [tenant, setTenant, request];
}
