import React from 'react';
import { TextInputField, SelectField } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { always } from 'ramda';

import {
    CLUSTER_PROPTYPES,
    FORWARDER_IDENTITY_PROPTYPES,
} from '../../propTypes';
import { withEventTargetAsValue } from '../../utils/common';

function CommonForwarderFormInputs({
    name,
    setName,
    clusters,
    selectedCluster,
    setSelectedCluster,
    identities,
    selectedIdentity,
    setSelectedIdentity,
}) {
    return (
        <>
            <TextInputField
                label="Forwarder Name"
                value={name}
                onChange={withEventTargetAsValue(setName)}
                description="Enter a name for this forwarder"
                placeholder="e.g. cyderes-syslog-kcmo"
                required
            />
            <SelectField
                label="Cluster"
                description="Which cluster will this be deployed on?"
                value={selectedCluster}
                onChange={withEventTargetAsValue(setSelectedCluster)}
                required
            >
                <option value={null}>please select to continue</option>
                {clusters.map((cluster) => (
                    <option key={cluster.id} value={cluster.id}>
                        {cluster.name}
                    </option>
                ))}
            </SelectField>
            <SelectField
                label="Secret Keys"
                description="Which keys should we use when sending the data?"
                value={selectedIdentity}
                onChange={withEventTargetAsValue(setSelectedIdentity)}
                required
            >
                <option value={null}>please select to continue</option>
                {identities.map((key) => (
                    <option key={key.id} value={key.id}>
                        {key.name}
                    </option>
                ))}
            </SelectField>
        </>
    );
}

export default CommonForwarderFormInputs;

CommonForwarderFormInputs.propTypes = {
    name: PropTypes.string,
    setName: PropTypes.func,
    clusters: PropTypes.arrayOf(CLUSTER_PROPTYPES),
    selectedCluster: PropTypes.string,
    setSelectedCluster: PropTypes.func,
    identities: PropTypes.arrayOf(FORWARDER_IDENTITY_PROPTYPES),
    selectedIdentity: PropTypes.string,
    setSelectedIdentity: PropTypes.func,
};

CommonForwarderFormInputs.defaultProps = {
    name: '',
    setName: always(null),
    clusters: [],
    selectedCluster: '',
    setSelectedCluster: always(null),
    identities: [],
    selectedIdentity: '',
    setSelectedIdentity: always(null),
};
