import { curry } from 'ramda';
import React from 'react';
import {
    Combobox,
    Heading,
    Label,
    Pane,
    PlusIcon,
    Switch,
    TextInput,
    TrashIcon,
} from 'evergreen-ui';

import ActionButton from '../../../../components/ActionButton';
import {
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';
import useFetchDataTypes from '../../../../hooks/useFetchDataTypes';
import { withEventTargetAsValue } from '../../../../utils/common';
import { actions } from '../models/reducer';
import { CollectorMetadataInputs } from './MetadataInputs';
import { noUniqueCharsRegex } from './utils';

function PcapCollectorInputs({
    collector,
    handleRemove,
    updateBpfValue,
    updateCommentValue,
    updateDataType,
    updateInterfaceValue,
}) {
    const [showMetadata, setShowMetadata] = React.useState(false);

    const dataTypes = useFetchDataTypes();
    const { common, pcap } = collector;

    const commentValid =
        noUniqueCharsRegex().test(common.comment) ||
        common.comment.length === 0 ||
        !common.comment;

    return (
        <Pane
            key={collector.tagId}
            data-testid="table-form-row"
            borderBottom="1px solid #d8dae5"
            paddingTop="1.25rem"
            paddingBottom="1.25rem"
        >
            <Pane display="flex">
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        Data Type
                    </Label>
                    <Combobox
                        width="85%"
                        openOnFocus
                        items={dataTypes}
                        value={common.dataType}
                        isInvalid={!common.dataType}
                        initialSelectedItem={common.dataType}
                        onChange={updateDataType}
                        border={!common.dataType && '1px solid #D14343'}
                        borderRadius="5.5px"
                    />
                    {!common.dataType && (
                        <Label display="block" color="#D14343">
                            Required
                        </Label>
                    )}
                </Pane>
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        Comment
                    </Label>
                    <TextInput
                        width="85%"
                        value={common.comment}
                        onChange={updateCommentValue}
                        isInvalid={!commentValid}
                        required
                    />
                    {!commentValid && (
                        <Label display="block" color="#D14343">
                            No special characters
                        </Label>
                    )}
                </Pane>
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        BPF
                    </Label>
                    <TextInput
                        width="85%"
                        value={pcap.bpf}
                        isInvalid={!pcap.bpf}
                        onChange={updateBpfValue}
                    />
                    {!pcap.bpf && (
                        <Label display="block" color="#D14343">
                            Required
                        </Label>
                    )}
                </Pane>
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        Interface
                    </Label>
                    <TextInput
                        width="85%"
                        value={pcap.interface}
                        isInvalid={!pcap.interface}
                        onChange={updateInterfaceValue}
                    />
                    {!pcap.interface && (
                        <Label display="block" color="#D14343">
                            Required
                        </Label>
                    )}
                </Pane>
                <Pane
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="1rem"
                >
                    <ActionButton
                        appearance="primary"
                        data-testid="delete-collector-btn"
                        icon={<TrashIcon color="#fff" />}
                        intent="danger"
                        height={40}
                        onClick={handleRemove}
                        shadow="show"
                        shape="circle"
                        type="icon"
                    />
                </Pane>
            </Pane>
            <Pane display="flex">
                <Pane width="22.5%">
                    <Pane
                        display="flex"
                        marginTop={commentValid ? '1rem' : '2rem'}
                    >
                        <Switch
                            checked={showMetadata}
                            onChange={() => setShowMetadata(!showMetadata)}
                            marginRight="0.5rem"
                            id={`switch-${collector.tagId}`}
                            marginLeft="0.175rem"
                        />
                        <Label htmlFor={`switch-${collector.tagId}`}>
                            Configure Metadata
                        </Label>
                    </Pane>
                </Pane>
            </Pane>
            <Pane>
                {showMetadata && (
                    <CollectorMetadataInputs collector={collector} />
                )}
            </Pane>
        </Pane>
    );
}

function PcapCollectorsSection({ showFormFields }) {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const updateState = curry((collector, actionType, value) =>
        dispatch({ type: actionType, payload: { collector, value } }),
    );
    const insertCollectorInState = updateState(
        null,
        actions.INSERT_PCAP_COLLECTOR,
    );

    return (
        <>
            <Pane data-testid="pcap-config-form">
                <Heading size={700} marginTop="1rem">
                    Data Sources
                </Heading>
                <Pane>
                    {state.collectors.map((collector) => {
                        const updateCollectorValueInState = updateState(
                            collector,
                        );
                        const handleRemove = updateCollectorValueInState(
                            actions.REMOVE_COLLECTOR,
                        );
                        const updateDataType = updateCollectorValueInState(
                            actions.UPDATE_COMMON_COLLECTOR_DATA_TYPE,
                        );
                        const updateBpfValue = withEventTargetAsValue(
                            updateCollectorValueInState(
                                actions.UPDATE_PCAP_BPF,
                            ),
                        );
                        const updateCommentValue = withEventTargetAsValue(
                            updateCollectorValueInState(
                                actions.UPDATE_COMMON_COLLECTOR_COMMENT,
                            ),
                        );
                        const updateInterfaceValue = withEventTargetAsValue(
                            updateCollectorValueInState(
                                actions.UPDATE_PCAP_INTERFACE,
                            ),
                        );

                        return (
                            <PcapCollectorInputs
                                collector={collector}
                                handleRemove={handleRemove}
                                updateBpfValue={updateBpfValue}
                                updateCommentValue={updateCommentValue}
                                updateDataType={updateDataType}
                                updateInterfaceValue={updateInterfaceValue}
                                key={`form-row-${collector.tagId}`}
                            />
                        );
                    })}
                    <Pane display="flex" alignItems="center">
                        <Pane>
                            <ActionButton
                                aria-label="add data type"
                                appearance="primary"
                                data-testid="add-data-btn"
                                height={40}
                                icon={<PlusIcon color="#fff" />}
                                intent="action"
                                marginTop="1rem"
                                onClick={insertCollectorInState}
                                shadow="show"
                                shape="circle"
                                type="icon"
                            />
                        </Pane>
                    </Pane>
                </Pane>
            </Pane>
        </>
    );
}

export default PcapCollectorsSection;
