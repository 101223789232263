import React from 'react';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { resolve } from '@specialblend/react-process-env';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import LoadingSpinner from '../components/LoadingSpinner';
import AppProviders from '../providers/AppProviders';
import { redirects } from '../routes';
import * as theme from '../theme';

const AdminApp = React.lazy(() => import('./Admin'));

const redirectUri = `${window.location.origin}/implicit/callback`;
const issuer =
    resolve('REACT_APP_OKTA_ISSUER', process.env) ||
    'https://cyderes.okta.com/oauth2/default';
const clientId =
    resolve('REACT_APP_OKTA_CLIENT_ID', process.env) || '0oak4mg2rkSsjvpWc356';

export default function App() {
    const oktaAuth = new OktaAuth({
        clientId,
        issuer,
        redirectUri,
    });

    const restoreOrignalUri = async (_oktaAuth, originalUri) => {
        window.location.replace(
            toRelativeUrl(originalUri || '/', window.location.origin),
        );
    };

    return (
        <React.Suspense fallback={<LoadingSpinner />}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Security
                        oktaAuth={oktaAuth}
                        restoreOriginalUri={restoreOrignalUri}
                    >
                        <AppProviders>
                            <Switch>
                                {redirects}
                                <SecureRoute
                                    path="/admin"
                                    component={AdminApp}
                                />
                                <Route
                                    path="/implicit/callback"
                                    component={LoginCallback}
                                />
                                <Redirect to="/admin" />
                            </Switch>
                        </AppProviders>
                    </Security>
                </Router>
            </ThemeProvider>
        </React.Suspense>
    );
}
