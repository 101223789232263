import React from 'react';
import { and, equals } from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'evergreen-ui';

import ErrorAlert from '../../../components/ErrorAlert';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SearchableTable from '../../../components/SearchableTable';
import useAbility from '../../../hooks/useAbility';
import useTenant from '../../../hooks/useTenant';
import {
    VIEW_ANY_CDP_FW_IDENTITY,
    VIEW_ANY_CHRONICLE_FW_IDENTITY,
} from '../../../system/abilities';
import {
    anyIsLoading,
    isEmptyOrNil,
    isNotEmptyOrNil,
} from '../../../utils/common';
import PageContainer from '../../../components/PageContainer';
import { sortByProp } from '../../../utils/common';

import columns from './ForwarderIdentities-columns';
import {
    useQueryAllForwarderIdentities,
    useQueryAllTenantForwarderIdentities,
} from './ForwarderIdentities-hooks';

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2vh;
`;

function WithLinkToCreateNewIdentity({ children, ...props }) {
    return (
        <>
            <ButtonContainer {...props}>
                <Link to="/admin/pipeline-secrets/new">
                    <Button iconBefore="plus">New</Button>
                </Link>
            </ButtonContainer>
            {children}
        </>
    );
}

function AllForwarderIdentities() {
    const { data, loading, error } = useQueryAllForwarderIdentities();

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <ErrorAlert error={error} />;
    }

    return (
        <PageContainer>
            <WithLinkToCreateNewIdentity data-testid="all-identities-wrapper">
                <SearchableTable
                    columns={columns}
                    data={sortByProp('name', data)}
                    allowSearch
                />
            </WithLinkToCreateNewIdentity>
        </PageContainer>
    );
}

function TenantForwarderIdentities({ tenantId }) {
    const { data, loading, error } = useQueryAllTenantForwarderIdentities(
        tenantId,
    );

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <ErrorAlert error={error} />;
    }

    return (
        <PageContainer>
            <WithLinkToCreateNewIdentity data-testid="tenant-identities-wrapper">
                <SearchableTable
                    columns={columns}
                    data={sortByProp('name', data)}
                    allowSearch
                />
            </WithLinkToCreateNewIdentity>
        </PageContainer>
    );
}

function ForwarderIdentities() {
    const [tenant] = useTenant();
    const canViewCdpIdentitiesResponse = useAbility(VIEW_ANY_CDP_FW_IDENTITY);
    const canViewChronicleFwIdentitiesResponse = useAbility(
        VIEW_ANY_CHRONICLE_FW_IDENTITY,
    );

    const loading = anyIsLoading([
        canViewCdpIdentitiesResponse,
        canViewChronicleFwIdentitiesResponse,
    ]);
    const canViewAnyIdentity = and(
        canViewCdpIdentitiesResponse.data,
        canViewChronicleFwIdentitiesResponse.data,
    );

    if (loading) {
        return <LoadingSpinner />;
    }

    if (isEmptyOrNil(tenant) && canViewAnyIdentity) {
        return <AllForwarderIdentities />;
    }

    if (isNotEmptyOrNil(tenant)) {
        if (canViewAnyIdentity) {
            return <TenantForwarderIdentities tenantId={tenant.id} />;
        }

        if (equals(false, canViewAnyIdentity)) {
            return <div>403: You are not supposed to be here!</div>;
        }
    }

    return <ErrorAlert />;
}

export { AllForwarderIdentities, TenantForwarderIdentities };
export default ForwarderIdentities;
