import { defaultTo, filter, path, propOr } from 'ramda';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import HandleQueryResponse from '../../../components/HandleQueryResponse';
import PageContainer from '../../../components/PageContainer';
import SearchableTable from '../../../components/SearchableTable';
import useTenant from '../../../hooks/useTenant';
import { sortByProp } from '../../../utils/common';
import useCloudForwarders from '../CloudDataSources/CloudDataSourcesTable-hooks';
import { resolveCloudCollectorResponse } from '../CloudDataSources/CloudDataSourcesTable-resolver';
import useCyclopsForwarders from '../CyclopsDataSources/CyclopsDataSourcesTable-hooks';
import { resolveCyclopsCollectorResponse } from '../CyclopsDataSources/CyclopsDataSourcesTable-resolver';
import { buildDataSourceColumns } from './ForwarderDataSourcesTable-columns';

const getFilteredCollectors = (query, resolver, collectorFilter) => {
    const collectors = propOr([], 'data', query);
    const resolvedCollectors = resolver(collectors);
    const [filteredCollectors] = filter(collectorFilter, resolvedCollectors);
    return filteredCollectors;
};

const DataSourceTable = () => {
    const { organizationId, dataSource } = useParams();
    const [tenant] = useTenant();
    const orgId = propOr(organizationId, 'id', tenant);
    const defaultToEmpty = defaultTo('');
    const id = defaultToEmpty(orgId);
    const history = useHistory();
    const route = path(['location', 'pathname'], history);

    const queryOrgCyclopsForwarders = useCyclopsForwarders({
        id,
    });
    const queryOrgCloudForwarders = useCloudForwarders({
        id,
    });
    const filterByDatasource = (collector) => collector.name === dataSource;

    const filteredCollectors = route.includes('/cyclops/')
        ? getFilteredCollectors(
              queryOrgCyclopsForwarders,
              resolveCyclopsCollectorResponse,
              filterByDatasource,
          )
        : getFilteredCollectors(
              queryOrgCloudForwarders,
              resolveCloudCollectorResponse,
              filterByDatasource,
          );
    const { collectors } = filteredCollectors;
    const columns = buildDataSourceColumns();

    return (
        <PageContainer>
            <h2>{filteredCollectors.name}</h2>
            <HandleQueryResponse response={queryOrgCyclopsForwarders}>
                <SearchableTable
                    columns={columns}
                    data={sortByProp('forwarderName', collectors)}
                    data-testid="dataSource-table"
                    allowSearch
                />
            </HandleQueryResponse>
        </PageContainer>
    );
};

export default DataSourceTable;
