import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { dimensions } from '../../theme';

const Container = styled.div`
    ${(props) => {
        if (props.enableMargins) {
            return `margin: ${dimensions.pageMargin.medium};
            @media (min-width: 700) {
                  margin: ${dimensions.pageMargin.large};
            }`;
        }
    }}
`;

PageContainer.propTypes = {
    children: PropTypes.node,
    enableMargins: PropTypes.bool.isRequired,
};

PageContainer.defaultProps = {
    enableMargins: true,
};

export default function PageContainer({ enableMargins, children }) {
    return <Container enableMargins={enableMargins}>{children}</Container>;
}
