import {
    assocPath,
    curry,
    dissoc,
    dissocPath,
    findIndex,
    map,
    mergeDeepLeft,
    omit,
    pathEq,
    propEq,
    remove,
    when,
    update,
} from 'ramda';
import { v1 as uuidv1 } from 'uuid';

import { createReducer } from '../../../../reducers/utils';
import { filterValueFromArray } from '../../../../utils/common';
import {
    dissocTypename,
    dissocTypenamesFromCollectorsArray,
    filterCollectorsWithTls,
} from '../../../../utils/forwarders';

export const initialState = {
    collectors: [],
    collectorType: null,
    forwarderId: null,
    infrastructure: {
        replicaCount: 1,
    },
    metadata: {
        labels: [],
        namespace: '',
    },
    name: null,
    splunkPassword: null,
    splunkUsername: null,
    tlsCert: null,
    tlsKey: null,
    isValid: true,
};

/* base collector values when adding new collector to state */
const common = {
    enabled: true,
    dataType: null,
    dataHint: null,
    batchEveryBytes: 1048576,
    batchEverySeconds: 10,
    comment: '',
    metadata: {
        labels: [],
        namespace: '',
    },
};
const pcap = {
    bpf: '',
    interface: 'any',
};
const splunk = {
    queryString: '',
    url: '',
    ignoreCerts: true,
};
const syslog = {
    port: '',
    tlsEnabled: false,
    tlsRawCert: null,
    tlsRawKey: null,
};
const basePcapCollector = {
    collectorType: 'pcap',
    common,
    pcap,
};
const baseSplunkCollector = {
    collectorType: 'splunk',
    common,
    splunk,
};
const baseSyslogCollector = {
    collectorType: 'syslog',
    common,
    syslog,
};

/****** HELPERS ******/
export const addUniqueTagId = (baseValues) => {
    const tagId = uuidv1();
    const valuesWithTagId = mergeDeepLeft(baseValues, { tagId });
    return valuesWithTagId;
};
export const mergeCollectorsWithSplunkCredentials = (
    username,
    password,
    collectors,
) => {
    const credentials = {
        splunk: {
            username,
            password,
        },
    };
    const mergeWithCredentials = mergeDeepLeft(credentials);
    const collectorsWithCredentials = map(mergeWithCredentials, collectors);
    return collectorsWithCredentials;
};
export const mergeEnabledCollectorsWithTlsCredentials = (
    tlsRawCert,
    tlsRawKey,
    collectors,
) => {
    const tlsCreds = {
        syslog: {
            tlsRawCert,
            tlsRawKey,
        },
    };
    const mergeWithTlsCreds = mergeDeepLeft(tlsCreds);
    const isTlsEnabled = pathEq(['syslog', 'tlsEnabled'], true);
    const condMergeWithCreds = when(isTlsEnabled, mergeWithTlsCreds);
    const updatedCollectors = map(condMergeWithCreds, collectors);
    return updatedCollectors;
};

/****** REDUCER FUNCTIONS ******/
export const resetState = () => {
    return initialState;
};

export const mapRemoteForwarderToState = (state, action) => {
    const { id, configuration, name } = action.payload;
    const forwarderId = id;
    const infrastructure = dissocTypename(configuration.infrastructure);
    const namespace = configuration?.metadata?.namespace || '';
    const _labels = configuration?.metadata?.labels || [];
    const labels = _labels.map((label) => omit(['__typename'], label));
    const metadata = { labels, namespace };
    const collectors = dissocTypenamesFromCollectorsArray(
        configuration.collectors,
    ).map((_collector) => {
        const collector = dissocPath(
            ['common', 'metadata', '__typename'],
            _collector,
        );
        const _labels = collector.common?.metadata?.labels || [];
        const labels = _labels.map((label) => dissoc('__typename', label));
        const metadata = {
            namespace: collector.common?.metadata?.namespace,
            labels,
        };
        return mergeDeepLeft({ common: { metadata } }, collector);
    });
    const collectorsWithTlsEnabled = filterCollectorsWithTls(collectors);

    let collectorType = null;
    if (collectors.length) {
        const [collector] = collectors;
        collectorType = collector.collectorType;
    }

    let tlsCert = null;
    let tlsKey = null;
    if (collectorsWithTlsEnabled.length) {
        const [collectorWithTlsValues] = collectorsWithTlsEnabled;
        tlsCert = collectorWithTlsValues.syslog.tlsRawCert;
        tlsKey = collectorWithTlsValues.syslog.tlsRawKey;
    }

    let splunkPassword = null;
    let splunkUsername = null;
    if (collectors.length && collectors[0].collectorType === 'splunk') {
        const [splunkCollector] = collectors;
        splunkPassword = splunkCollector.splunk.password;
        splunkUsername = splunkCollector.splunk.username;
    }

    const remoteValues = {
        name,
        collectors,
        collectorType,
        forwarderId,
        infrastructure,
        metadata,
        splunkPassword,
        splunkUsername,
        tlsCert,
        tlsKey,
    };

    return mergeDeepLeft(remoteValues, state);
};

export const updateStateByPath = curry((path, state, action) => {
    const value = assocPath(path, action.payload, {});
    const newState = mergeDeepLeft(value, state);
    return newState;
});

export const insertCollectorInState = curry((baseCollector, state) => {
    const collector = addUniqueTagId(baseCollector);
    const collectors = [...state.collectors, collector];
    return mergeDeepLeft({ collectors }, state);
});

export const removeCollectorFromState = (state, action) => {
    const { collector } = action.payload;
    const { tagId } = collector;
    const collectors = filterValueFromArray(tagId, state.collectors, ['tagId']);
    return mergeDeepLeft({ collectors }, state);
};

export const updateCollector = curry((key, subKey, state, action) => {
    const { collector, value } = action.payload;
    const updatedCollector = mergeDeepLeft(
        {
            [key]: {
                [subKey]: value,
            },
        },
        collector,
    );
    const propEqsTagId = propEq('tagId', collector.tagId);
    const collectorIndex = findIndex(propEqsTagId, state.collectors);
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
});

export const addCredentialsToCollectors = (state) => {
    const { collectorType } = state;

    if (collectorType === 'splunk') {
        const collectors = mergeCollectorsWithSplunkCredentials(
            state.splunkUsername,
            state.splunkPassword,
            state.collectors,
        );
        return mergeDeepLeft({ collectors }, state);
    }

    if (collectorType === 'syslog') {
        const collectors = mergeEnabledCollectorsWithTlsCredentials(
            state.tlsCert,
            state.tlsKey,
            state.collectors,
        );
        return mergeDeepLeft({ collectors }, state);
    }

    return state;
};

export const updateForwarderNamespace = (state, action) => {
    const { value } = action.payload;
    const _metadata = state?.metadata || {};
    const metadata = mergeDeepLeft({ namespace: value }, _metadata);
    return mergeDeepLeft({ metadata }, state);
};
export const updateCollectorNamespace = (state, action) => {
    const { collector, value } = action.payload;
    const _metadata = collector.common?.metadata || {};
    const metadata = mergeDeepLeft({ namespace: value }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const addForwarderLabel = (state, action) => {
    const label = { key: '', value: '' };
    const _labels = state?.metadata?.labels || [];
    const labels = [..._labels, label];

    return mergeDeepLeft({ metadata: { labels } }, state);
};
export const removeForwarderLabel = (state, action) => {
    const { index } = action.payload;
    const labels = remove(index, 1, state.metadata.labels);

    return mergeDeepLeft({ metadata: { labels } }, state);
};
export const updateForwarderLabel = (state, action) => {
    const { index, field, value } = action.payload;
    const _labels = state.metadata.labels;
    const label = mergeDeepLeft(
        {
            [field]: value,
        },
        _labels[index],
    );
    const labels = update(index, label, _labels);

    return mergeDeepLeft({ metadata: { labels } }, state);
};
export const addCollectorLabel = (state, action) => {
    const { collector } = action.payload;
    const currentLabels = collector.common?.metadata?.labels || [];
    const updatedLabels = [...currentLabels, { key: '', value: '' }];
    const _metadata = collector.common?.metadata || {};
    const metadata = mergeDeepLeft({ labels: updatedLabels }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const removeCollectorLabel = (state, action) => {
    const { collector, index } = action.payload;
    const labels = remove(index, 1, collector.common.metadata.labels);
    const _metadata = collector.common?.metadata || {};
    const metadata = mergeDeepLeft({ labels }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const updateCollectorLabel = (state, action) => {
    const { collector, index, field, value } = action.payload;
    const _labels = collector.common.metadata.labels;
    const label = mergeDeepLeft(
        {
            [field]: value,
        },
        _labels[index],
    );
    const labels = update(index, label, _labels);
    const _metadata = collector.common.metadata;
    const metadata = mergeDeepLeft({ labels }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};

export const setValidity = (state, action) => {
    const { valid } = action.payload;
    return {
        ...state,
        isValid: valid,
    };
};

/* insert collectors */
export const insertPcapCollector = insertCollectorInState(basePcapCollector);
export const insertSplunkCollector = insertCollectorInState(
    baseSplunkCollector,
);
export const insertSyslogCollector = insertCollectorInState(
    baseSyslogCollector,
);

/* updating common collector values */
export const updateCollectorCommonValues = updateCollector('common');
export const updateCollectorDataType = updateCollectorCommonValues('dataType');
export const updateCollectorComment = updateCollectorCommonValues('comment');

export const updateReplicaCount = updateStateByPath([
    'infrastructure',
    'replicaCount',
]);

/* updating pcap collector values */
export const updatePcapCollectorValues = updateCollector('pcap');
export const updatePcapBpfValue = updatePcapCollectorValues('bpf');
export const updatePcapInterfaceValue = updatePcapCollectorValues('interface');

/* updating splunk collector values */
export const updateSplunkCollectorValues = updateCollector('splunk');
export const updateSplunkQueryStringValue = updateSplunkCollectorValues(
    'queryString',
);
export const updateSplunkUrlValue = updateSplunkCollectorValues('url');
export const updateSplunkPassword = updateStateByPath(['splunkPassword']);
export const updateSplunkUsername = updateStateByPath(['splunkUsername']);

/* updating syslog collector values */
export const updateSyslogCollectorValues = updateCollector('syslog');
export const updateSyslogPortValue = updateSyslogCollectorValues('port');
export const updateSyslogTlsEnabledValue = updateSyslogCollectorValues(
    'tlsEnabled',
);
export const updateTlsCertValue = updateStateByPath(['tlsCert']);
export const updateTlsKeyValue = updateStateByPath(['tlsKey']);

/****** ACTIONS ******/
export const actions = {
    ADD_CREDENTIALS_TO_COLLECTORS: Symbol('ADD_CREDENTIALS_TO_COLLECTORS'),
    ADD_COLLECTOR_LABEL: Symbol('ADD_COLLECTOR_LABEL'),
    ADD_FORWARDER_LABEL: Symbol('ADD_FORWARDER_LABEL'),
    INSERT_PCAP_COLLECTOR: Symbol('INSERT_PCAP_COLLECTOR'),
    INSERT_SPLUNK_COLLECTOR: Symbol('INSERT_SPLUNK_COLLECTOR'),
    INSERT_SYSLOG_COLLECTOR: Symbol('INSERT_SYSLOG_COLLECTOR'),
    MAP_REMOTE_FORWARDER_TO_STATE: Symbol('MAP_REMOTE_FORWARDER_TO_STATE'),
    REMOVE_COLLECTOR: Symbol('REMOVE_COLLECTOR'),
    REMOVE_COLLECTOR_LABEL: Symbol('REMOVE_COLLECTOR_LABEL'),
    REMOVE_FORWARDER_LABEL: Symbol('REMOVE_FORWARDER_LABEL'),
    RESET_STATE: Symbol('RESET_STATE'),
    SET_VALIDITY: Symbol('SET_VALIDITY'),
    UPDATE_COMMON_COLLECTOR_COMMENT: Symbol('UPDATE_COMMON_COLLECTOR_COMMENT'),
    UPDATE_COMMON_COLLECTOR_DATA_TYPE: Symbol(
        'UPDATE_COMMON_COLLECTOR_DATA_TYPE',
    ),
    UPDATE_COMMON_COLLECTOR_LABEL: Symbol('UPDATE_COMMON_COLLECTOR_LABEL'),
    UPDATE_COMMON_COLLECTOR_NAMESPACE: Symbol(
        'UPDATE_COMMON_COLLECTOR_NAMESPACE',
    ),
    UPDATE_FORWARDER_LABEL: Symbol('UPDATE_FORWARDER_LABEL'),
    UPDATE_FORWARDER_NAMESPACE: Symbol('UPDATE_FORWARDER_NAMESPACE'),
    UPDATE_PCAP_BPF: Symbol('UPDATE_PCAP_BPF'),
    UPDATE_PCAP_INTERFACE: Symbol('UPDATE_PCAP_INTERFACE'),
    UPDATE_REPLICA_COUNT: Symbol('UPDATE_REPLICA_COUNT'),
    UPDATE_SPLUNK_PASSWORD: Symbol('UPDATE_SPLUNK_PASSWORD'),
    UPDATE_SPLUNK_QUERY_STRING: Symbol('UPDATE_SPLUNK_QUERY_STRING'),
    UPDATE_SPLUNK_URL: Symbol('UPDATE_SPLUNK_URL'),
    UPDATE_SPLUNK_USERNAME: Symbol('UPDATE_SPLUNK_USERNAME'),
    UPDATE_SYSLOG_COLLECTOR_PORT: Symbol('UPDATE_SYSLOG_COLLECTOR_PORT'),
    UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED: Symbol(
        'UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED',
    ),
    UPDATE_TLS_CERT: Symbol('UPDATE_TLS_CERT'),
    UPDATE_TLS_KEY: Symbol('UPDATE_TLS_KEY'),
};

/****** ACTION HANDLERS ******/
export const handlers = {
    [actions.ADD_CREDENTIALS_TO_COLLECTORS]: addCredentialsToCollectors,
    [actions.ADD_COLLECTOR_LABEL]: addCollectorLabel,
    [actions.ADD_FORWARDER_LABEL]: addForwarderLabel,
    [actions.INSERT_PCAP_COLLECTOR]: insertPcapCollector,
    [actions.INSERT_SPLUNK_COLLECTOR]: insertSplunkCollector,
    [actions.INSERT_SYSLOG_COLLECTOR]: insertSyslogCollector,
    [actions.MAP_REMOTE_FORWARDER_TO_STATE]: mapRemoteForwarderToState,
    [actions.REMOVE_COLLECTOR]: removeCollectorFromState,
    [actions.REMOVE_COLLECTOR_LABEL]: removeCollectorLabel,
    [actions.REMOVE_FORWARDER_LABEL]: removeForwarderLabel,
    [actions.RESET_STATE]: resetState,
    [actions.SET_VALIDITY]: setValidity,
    [actions.UPDATE_COMMON_COLLECTOR_COMMENT]: updateCollectorComment,
    [actions.UPDATE_COMMON_COLLECTOR_DATA_TYPE]: updateCollectorDataType,
    [actions.UPDATE_COMMON_COLLECTOR_LABEL]: updateCollectorLabel,
    [actions.UPDATE_COMMON_COLLECTOR_NAMESPACE]: updateCollectorNamespace,
    [actions.UPDATE_FORWARDER_LABEL]: updateForwarderLabel,
    [actions.UPDATE_FORWARDER_NAMESPACE]: updateForwarderNamespace,
    [actions.UPDATE_PCAP_BPF]: updatePcapBpfValue,
    [actions.UPDATE_PCAP_INTERFACE]: updatePcapInterfaceValue,
    [actions.UPDATE_REPLICA_COUNT]: updateReplicaCount,
    [actions.UPDATE_SPLUNK_PASSWORD]: updateSplunkPassword,
    [actions.UPDATE_SPLUNK_QUERY_STRING]: updateSplunkQueryStringValue,
    [actions.UPDATE_SPLUNK_USERNAME]: updateSplunkUsername,
    [actions.UPDATE_SPLUNK_URL]: updateSplunkUrlValue,
    [actions.UPDATE_SYSLOG_COLLECTOR_PORT]: updateSyslogPortValue,
    [actions.UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED]: updateSyslogTlsEnabledValue,
    [actions.UPDATE_TLS_CERT]: updateTlsCertValue,
    [actions.UPDATE_TLS_KEY]: updateTlsKeyValue,
};

/****** REDUCER ******/
export const reducer = createReducer(initialState, handlers);
