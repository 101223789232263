import React from 'react';
import decodeJwt from 'jwt-decode';
import { always, tryCatch } from 'ramda';
import { useOktaAuth } from '@okta/okta-react';

import { parseIdToken } from '../../../utils/auth';
import PageContainer from '../../../components/PageContainer';

const parseJwt = tryCatch(decodeJwt, always({}));

export default function Me() {
    const okta = useOktaAuth();
    const idToken = parseIdToken(okta);
    const jwt = parseJwt(idToken);
    return (
        <PageContainer>
            <div>
                <h3>ID Token</h3>
                <pre
                    style={{
                        padding: '1rem',
                        backgroundColor: '#eee',
                        overflow: 'scroll',
                    }}
                    data-testid="jest-me-idToken"
                >
                    {idToken}
                </pre>
            </div>
            <div>
                <h3>Parsed JWT</h3>
                <pre data-testid="jest-me-parsed-jwt">
                    {JSON.stringify(jwt, null, 4)}
                </pre>
            </div>
        </PageContainer>
    );
}
