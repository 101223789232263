import React from 'react';
import { Combobox, Label, Pane, TextInput } from 'evergreen-ui';

import {
    withEventTargetAsValue,
    withEventTargetAsFloat,
} from '../../../../utils/common';
import { actions } from '../models/reducer';
import {
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';

function InfrastructureSection() {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const handleChangeReplicaCount = (value) =>
        dispatch({ type: actions.UPDATE_REPLICA_COUNT, payload: value });
    const handleChangeWorkerCount = (value) =>
        dispatch({ type: actions.UPDATE_WORKER_COUNT, payload: value });
    const handleChangeBufferTime = (value) =>
        dispatch({ type: actions.UPDATE_BUFFER_TIME, payload: value });
    const handleChangeBatchFullThreshold = (value) =>
        dispatch({
            type: actions.UPDATE_BATCH_FULL_THRESHOLD,
            payload: value,
        });
    const handleChangeFlushThreadCount = (value) =>
        dispatch({
            type: actions.UPDATE_FLUSH_THREAD_COUNT,
            payload: value,
        });

    return (
        <Pane>
            <Pane marginBottom="1rem">
                <Label
                    id="replica-count"
                    marginBottom="0.25rem"
                    display="block"
                >
                    Replica Count
                </Label>
                <Combobox
                    items={[2, 3, 4, 5, 6, 7, 8, 9, 10]}
                    value={state.infrastructure.replicaCount}
                    selectedItem={state.infrastructure.replicaCount}
                    initialSelectedItem={state.infrastructure.replicaCount}
                    onChange={handleChangeReplicaCount}
                    aria-labelledby="replica-count"
                />
            </Pane>
            <Pane marginBottom="1rem">
                <Label id="worker-count" marginBottom="0.25rem" display="block">
                    Worker Count
                </Label>
                <Combobox
                    items={[1, 2, 3, 4, 5]}
                    value={state.infrastructure.workerCount}
                    selectedItem={state.infrastructure.workerCount}
                    initialSelectedItem={state.infrastructure.workerCount}
                    onChange={handleChangeWorkerCount}
                    aria-labelledby="worker-count"
                />
            </Pane>
            <Pane marginBottom="1rem">
                <Label marginBottom="0.25rem" display="block">
                    Buffer Time
                </Label>
                <TextInput
                    // label="Buffer Time"
                    value={state.infrastructure.bufferTime}
                    onChange={withEventTargetAsValue(handleChangeBufferTime)}
                    width="240px"
                />
            </Pane>
            <Pane marginBottom="1rem">
                <Label marginBottom="0.25rem" display="block">
                    Batch Full Threshold
                </Label>
                <TextInput
                    type="number"
                    step="0.05"
                    min="0"
                    max="1"
                    value={state.infrastructure.batchFullThreshold}
                    onChange={withEventTargetAsFloat(
                        handleChangeBatchFullThreshold,
                    )}
                    width="240px"
                />
            </Pane>
            <Pane marginBottom="1rem">
                <Label marginBottom="0.25rem" display="block">
                    Flush Thread Count
                </Label>
                <TextInput
                    type="number"
                    value={state.infrastructure.flushThreadCount}
                    onChange={withEventTargetAsValue(
                        handleChangeFlushThreadCount,
                    )}
                    width="240px"
                />
            </Pane>
        </Pane>
    );
}

export default InfrastructureSection;
