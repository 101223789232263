import React from 'react';
import { FormField, SelectMenu, TagInput } from 'evergreen-ui';

import destinationRefs, {
    azure,
    chronicle,
    cdpAlerting,
    itd,
    gcs,
    filtering,
} from '../../system/destinationRefs';

const createTagProps = (tagValue) => {
    const styles = {
        [azure]: {
            color: 'lavender',
            isSolid: false,
        },
        [chronicle]: {
            color: 'orange',
            isSolid: false,
        },
        [cdpAlerting]: {
            color: 'blue',
            isSolid: false,
        },
        [itd]: {
            color: 'purple',
            isSolid: true,
        },
        [gcs]: {
            color: 'red',
            isSolid: false,
        },
        [filtering]: {
            color: 'yellow',
            isSolid: false,
        },
    };

    if (styles[tagValue]) {
        return styles[tagValue];
    }

    return {};
};

function DestinationTagMenuInput({
    disabled,
    error,
    options = destinationRefs,
    onDeselect,
    onSelect,
    onRemove,
    selected,
    required = true,
    showLabel = true,
    ...rest
}) {
    return (
        <SelectMenu
            isMultiSelect
            selected={selected}
            options={options.map((option) => ({
                value: option,
                label: option,
                disabled: disabled ? true : false,
            }))}
            onDeselect={onDeselect}
            onSelect={onSelect}
            {...rest}
        >
            <FormField
                label={showLabel && 'Destination References'}
                labelFor="destination-tag-input"
                validationMessage={error}
            >
                <TagInput
                    values={selected}
                    id="destination-tag-input"
                    data-testid="tags"
                    disabled={disabled ? true : false}
                    width="100%"
                    onRemove={onRemove}
                    inputProps={{ placeholder: 'select destinations...' }}
                    tagProps={createTagProps}
                />
            </FormField>
        </SelectMenu>
    );
}

export default DestinationTagMenuInput;
