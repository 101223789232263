import { Button, IconButton, Icon } from 'evergreen-ui';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const StyledActionIconButton = styled(IconButton)`
    ${(props) => {
        let styles = '';

        // props.shape - circle or default evergreen styles
        if (props.shape === 'circle') {
            styles = styles.concat(css`
                border-radius: 50%;
            `);
        }

        // props.shadow - if true set box-shadow
        if (props.shadow === 'show') {
            styles = styles.concat(css`
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            `);
        }

        if (!props.shadow || props.shadow === 'hide') {
            styles = styles.concat(css`
                box-shadow: none;
            `);
        }

        // props.intent - extend to add "action" and alter "danger" styles
        if (props.intent === 'action' && props.appearance === 'primary') {
            styles = styles.concat(css`
              background: #265a7f;
              color: #fff;
              svg: #fff;

              &:hover, &:active {
                background: #517b98 !important; # not ideal
              }
          `);
        }

        if (props.intent === 'danger' && props.appearance === 'primary') {
            styles = styles.concat(css`
                background: #ef5757;
                color: #fff;
                svg: #fff;

                &:hover,
                &:active {
                    background: #f27573 !important;
                }

                &:focus {
                    box-shadow: 0 0 0 3px #ee534f3d, inset 0 0 0 1px #ee534f3d,
                        inset 0 -1px 1px 0 #ee534f3d !important;
                }
            `);
        }

        return styles;
    }}
`;

export const StyledActionButton = styled(Button)`
    ${(props) => {
        let styles = ``;

        // props.shadow - if true set box-shadow
        if (props.shadow === 'show') {
            styles = styles.concat(`
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            `);
        }

        if (!props.shadow || props.shadow === 'hide') {
            styles = styles.concat(`
                box-shadow: none;
            `);
        }

        // props.intent - extend to add "action" and alter "danger" styles
        if (props.intent === 'action' && props.appearance === 'primary') {
            styles = styles.concat(`
                  background: #265a7f;
                  color: #fff;
                  svg: #fff;
  
                  &:hover, &:active {
                    background: #517b98 !important; # not ideal
                  }
              `);
        }

        if (props.intent === 'danger' && props.appearance === 'primary') {
            styles = styles.concat(`
                  background: #ef5757;
                  color: #fff;
                  svg: #fff;

                  &:hover,
                  &:active {
                      background: #f27573 !important;
                  }

                  &:focus {
                      box-shadow: 0 0 0 3px #ee534f3d, inset 0 0 0 1px #ee534f3d,
                          inset 0 -1px 1px 0 #ee534f3d !important;
                  }
                `);
        }

        return styles;
    }}
`;

const ActionButton = ({ type, icon, ...rest }) =>
    type === 'icon' && icon ? (
        <StyledActionIconButton icon={icon} {...rest} />
    ) : (
        <StyledActionButton {...rest} />
    );

export default ActionButton;

// only sets proptypes for custom or extended props
// see https://evergreen.segment.com/components/buttons for Evergreen Button API docs
ActionButton.propTypes = {
    intent: PropTypes.oneOf(['action', 'danger', 'success']),
    icon: PropTypes.instanceOf(Icon),
    shape: PropTypes.oneOf(['circle']),
    shadow: PropTypes.oneOf(['hide', 'show']),
    type: PropTypes.oneOf(['button', 'icon']),
};
