import React from 'react';
import { Button, Pane, toaster, AddIcon } from 'evergreen-ui';
import { prop, propOr } from 'ramda';
import { Link } from 'react-router-dom';

import HandleQueryResponse from '../../../components/HandleQueryResponse';
import SearchableTable from '../../../components/SearchableTable';
import { QUERY_ALL_ORGANIZATIONS } from '../../../graphql/queries';
import useQuerySelector from '../../../hooks/useQuerySelector';
import PageContainer from '../../../components/PageContainer';
import { sortByProp } from '../../../utils/common';

const columns = [
    {
        title: 'Organization Name',
        path: ['name'],
        render: (data) => (
            <Link to={`/admin/organizations/${data.id}`}>{data.name}</Link>
        ),
    },
    {
        title: 'Contact',
        path: ['pointOfContact', 'name'],
    },
    {
        title: 'Contact Email',
        path: ['pointOfContact', 'email'],
    },
    {
        title: 'Contact Phone',
        render: (data) => data.pointOfContact.phone,
    },
];

function Organizations() {
    const response = useQuerySelector(
        QUERY_ALL_ORGANIZATIONS,
        {},
        prop('organizations'),
    );
    const data = propOr([], 'data', response);

    const refetchData = propOr(
        () => {
            toaster.danger('ERROR: Cannot refresh data.');
        },
        'refetch',
        response,
    );

    const refreshData = () => {
        refetchData();
    };

    return (
        <PageContainer>
            <HandleQueryResponse response={response}>
                <Pane width="100%" display="flex" justifyContent="flex-end">
                    <Button
                        onClick={refreshData}
                        appearance="default"
                        intent="none"
                        marginRight="2vh"
                    >
                        Refresh
                    </Button>
                    <Link to="/admin/organizations/create">
                        <Button
                            iconBefore={AddIcon}
                            marginBottom="2vh"
                            appearance="primary"
                            intent="success"
                        >
                            New
                        </Button>
                    </Link>
                </Pane>
                <SearchableTable
                    columns={columns}
                    data={sortByProp('name', data)}
                    data-testid="organizations-table"
                    allowSearch
                />
            </HandleQueryResponse>
        </PageContainer>
    );
}

export default Organizations;
