import { gql } from '@apollo/client';

export const DELETE_CHRONICLE_FORWARDER = gql`
    mutation deleteChronicleFw($id: ID!) {
        forwarder(id: $id) {
            remove
        }
    }
`;

export const QUERY_CHRONICLE_DEPLOYMENT = gql`
    query queryChronicleDeployment($id: ID!) {
        deploymentLog(id: $id) {
            id
            deploymentAppStatus {
                state
                errorMessage
            }
            cluster {
                name
            }
        }
    }
`;

export const QUERY_CHRONICLE_FORWARDER = gql`
    query queryChronicleForwarder($id: ID!) {
        forwarder(id: $id) {
            id
            name
            configuration {
                infrastructure {
                    replicaCount
                }
                collectors {
                    tagId
                    collectorType
                    common {
                        dataType
                        enabled
                        dataHint
                        batchEverySeconds
                        batchEveryBytes
                        comment
                        metadata {
                            labels {
                                key
                                value
                            }
                            namespace
                        }
                    }
                    syslog {
                        port
                        tlsEnabled
                        tlsRawCert
                        tlsRawKey
                    }
                    splunk {
                        username
                        password
                        url
                        queryString
                        ignoreCerts
                    }
                    pcap {
                        interface
                        bpf
                    }
                }
                metadata {
                    labels {
                        key
                        value
                    }
                    namespace
                }
            }
        }
    }
`;

export const UPDATE_CHRONICLE_FORWARDER_CONFIG = gql`
    mutation updateForwarderConfig($id: ID!, $props: UpdateForwarderInput!) {
        forwarder(id: $id) {
            update(props: $props) {
                id
                name
                configuration {
                    infrastructure {
                        replicaCount
                    }
                    collectors {
                        tagId
                        collectorType
                        common {
                            dataType
                            enabled
                            dataHint
                            batchEverySeconds
                            batchEveryBytes
                            comment
                            metadata {
                                labels {
                                    key
                                    value
                                }
                                namespace
                            }
                        }
                        syslog {
                            port
                            tlsEnabled
                            tlsRawCert
                            tlsRawKey
                        }
                        splunk {
                            username
                            password
                            url
                            queryString
                            ignoreCerts
                        }
                        pcap {
                            interface
                            bpf
                        }
                    }

                    metadata {
                        labels {
                            key
                            value
                        }
                        namespace
                    }
                }
            }
        }
    }
`;

export const UPGRADE_CHRONICLE_DEPLOYMENT = gql`
    mutation upgradeForwarderDeployment($id: ID!) {
        deploymentLog(id: $id) {
            upgradeApp {
                id
            }
        }
    }
`;

export const UNDEPLOY_CHORNICLE_APP = gql`
    mutation undeployChronicleApp($id: ID!) {
        deploymentLog(id: $id) {
            undeployApp {
                id
            }
        }
    }
`;
