import Technologies from '../admin-portal/help/Technologies';
import AdminToken from '../admin-portal/help/AdminToken';

import { baseAdminPath } from './home-routes';

const helpAdminPath = `${baseAdminPath}/help`;

const routes = {};

routes.Technologies = {
    path: `${helpAdminPath}/technologies`,
    exact: true,
    admin: true,
    secure: true,
    component: Technologies,
};

routes.AdminToken = {
    path: `${helpAdminPath}/admin-token`,
    exact: true,
    admin: false,
    secure: true,
    component: AdminToken,
};

export default routes;
