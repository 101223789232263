import { gql } from '@apollo/client';
import { prop } from 'ramda';

import useQuerySelector from '../useQuerySelector';

export const CHECK_HAS_ABILITY = gql`
    query checkUserHasAbility($ability: Ability) {
        hasAbility(ability: $ability)
    }
`;

export default function useAbility(ability) {
    return useQuerySelector(
        CHECK_HAS_ABILITY,
        { variables: { ability } },
        prop('hasAbility'),
    );
}
