import React from 'react';
import { Alert } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { is } from 'ramda';

import LoadingSpinner from '../LoadingSpinner';

const HandleQueryResponse = ({ response, errorTitle, children }) => {
    if (response.loading) return <LoadingSpinner />;

    if (response.error)
        return (
            <Alert title={errorTitle} intent="danger">
                {response.error.message}
            </Alert>
        );

    return is(Function, children) ? children(response.data) : children;
};

HandleQueryResponse.propTypes = {
    response: PropTypes.shape({
        data: PropTypes.any,
        loading: PropTypes.bool,
        error: PropTypes.object,
    }).isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    errorTitle: PropTypes.string,
};

HandleQueryResponse.defaultProps = {
    errorTitle: 'ERROR',
};

export default HandleQueryResponse;
