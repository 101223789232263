import { useOktaAuth } from '@okta/okta-react';
import decodeToken from 'jwt-decode';
import { isNil, unless } from 'ramda';

import { parseIdToken } from '../../utils/auth';

export default function useJwt() {
    const query = useOktaAuth();
    const token = parseIdToken(query);
    const jwt = unless(isNil, decodeToken)(token);
    return [{ jwt, token }, query];
}
