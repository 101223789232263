import { gql } from '@apollo/client';

export const CDP_CLOUD_FW_FRAGMENT = gql`
    fragment CDPCloudFwFragment on CDPCloudForwarder {
        configuration {
            collectors {
                port
                cloudTls
                connectionTimeoutSec
                common {
                    batchEveryBytes
                    batchEverySeconds
                    comment
                    dataType
                    enabled
                    metadata {
                        namespace
                        labels {
                            key
                            value
                        }
                    }
                }
            }
            destinationRef
            infrastructure {
                autoscaling {
                    enabled
                }
                batchFullThreshold
                bufferTime
                environment
                flushThreadCount
                hostname
                image {
                    dockerConfig
                    repository
                }
                resources {
                    limits {
                        cpu
                        memory
                    }
                    requests {
                        cpu
                        memory
                    }
                }
                replicaCount
                revisionHistoryLimit
                serviceType
                workerCount
            }
            metadata {
                namespace
                labels {
                    key
                    value
                }
            }
        }
        kubernetesService
        identityId
        organizationId
        name
    }
`;

export const CREATE_CDP_CLOUD_FW = gql`
    mutation createCdpCloudFw($props: CDPCloudForwarderInput!) {
        createCdpCloudForwarder(props: $props) {
            id
            ...CDPCloudFwFragment
        }
    }
    ${CDP_CLOUD_FW_FRAGMENT}
`;

export const REMOVE_CDP_CLOUD_FW = gql`
    mutation deleteCdpCloudFw($id: ID!) {
        removeCdpCloudForwarder(id: $id)
    }
`;

export const UPDATE_CDP_CLOUD_FW = gql`
    mutation updateCdpCloudFw($id: ID!, $props: CDPCloudForwarderInput!) {
        updateCdpCloudForwarder(id: $id, props: $props) {
            id
            ...CDPCloudFwFragment
        }
    }
    ${CDP_CLOUD_FW_FRAGMENT}
`;

export const QUERY_CDP_CLOUD_FW = gql`
    query cdpCloudFw($id: ID!) {
        cdpCloudForwarder(id: $id) {
            id
            ...CDPCloudFwFragment
        }
    }
    ${CDP_CLOUD_FW_FRAGMENT}
`;
