import React, { useState } from 'react';
import { Button, SelectField } from 'evergreen-ui';
import { pathOr, propOr } from 'ramda';
import { Redirect } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import CommonForwarderFormInputs from '../../../components/CommonForwarderFormInputs';
import ErrorAlert from '../../../components/ErrorAlert';
import LoadingSpinner from '../../../components/LoadingSpinner';
import withRequireAbility from '../../../hocs/withRequireAbility';
import useCreateChronicleForwarderMutation from '../../../hooks/useCreateChronicleForwarderMutation';
import useQueryTenantChronicleIdentities from '../../../hooks/useQueryTenantChronicleIdentities';
import useQueryTenantClusters from '../../../hooks/useQueryTenantClusters';
import {
    anyIsLoading,
    anyHasError,
    isNotEmptyOrNil,
    withEventTargetAsValue,
} from '../../../utils/common';
import useTenant from '../../../hooks/useTenant';
import { CREATE_ANY_FORWARDER } from '../../../system/abilities';

function DeployChronicleForwarder() {
    const [tenant] = useTenant();
    const tenantId = propOr('', 'id', tenant);

    const queryTenantChronicleIdentitesResponse = useQueryTenantChronicleIdentities(
        tenantId,
    );
    const queryTenantClustersResponse = useQueryTenantClusters(tenantId);
    const identities = propOr(
        [],
        'data',
        queryTenantChronicleIdentitesResponse,
    );
    const clusters = propOr([], 'data', queryTenantClustersResponse);

    const [name, setName] = useState('');
    const [selectedIdentity, setSelectedIdentity] = useState('');
    const [selectedCluster, setSelectedCluster] = useState('');
    const [collectorType, setCollectorType] = useState('syslog');

    const collectorTypeOptions = ['pcap', 'splunk', 'syslog'];

    const baseReplicas = collectorType === 'splunk' ? 1 : 2;

    const baseCollectorValues = {
        pcap: {
            bpf: null,
            interface: 'any',
        },
        splunk: {
            ignoreCerts: true,
            password: '',
            queryString: '',
            url: '',
            username: '',
        },
        syslog: {
            port: 31000,
            tlsEnabled: null,
            tlsRawCert: null,
            tlsRawKey: null,
        },
    };

    /* mutation props */
    const baseCollector = {
        collectorType,
        [collectorType]: baseCollectorValues[collectorType],
        tagId: uuidv1(),
        common: {
            batchEveryBytes: 1048576,
            batchEverySeconds: 10,
            comment: null,
            dataHint: null,
            dataType: 'CYDERES_TEST_EVENT',
            enabled: true,
        },
    };
    const configuration = {
        collectors: [baseCollector],
        infrastructure: {
            replicaCount: baseReplicas,
        },
    };

    const [
        createForwarder,
        createForwarderResponse,
    ] = useCreateChronicleForwarderMutation({
        clusterId: selectedCluster,
        forwarderName: name,
        forwarderConfiguration: configuration,
        identityId: selectedIdentity,
        organizationId: tenantId,
    });

    const apolloResponses = [
        queryTenantChronicleIdentitesResponse,
        queryTenantClustersResponse,
        createForwarderResponse,
    ];

    if (anyHasError(apolloResponses)) {
        const [res] = apolloResponses.filter((res) =>
            isNotEmptyOrNil(res.error),
        );
        return <ErrorAlert error={res.error} />;
    }

    if (anyIsLoading(apolloResponses)) {
        return <LoadingSpinner />;
    }

    if (isNotEmptyOrNil(createForwarderResponse.data.deployment)) {
        const deploymentLogId = pathOr(
            '',
            ['data', 'deployment', 'forwarder', 'deployApp', 'id'],
            createForwarderResponse,
        );
        const forwarderId = pathOr(
            '',
            ['data', 'forwarder', 'createForwarder', 'id'],
            createForwarderResponse,
        );
        return (
            <Redirect
                to={`/admin/forwarders/chronicle/${tenantId}/${deploymentLogId}/${forwarderId}`}
            />
        );
    }

    return [
        <CommonForwarderFormInputs
            name={name}
            setName={setName}
            clusters={clusters}
            selectedCluster={selectedCluster}
            setSelectedCluster={setSelectedCluster}
            identities={identities}
            selectedIdentity={selectedIdentity}
            setSelectedIdentity={setSelectedIdentity}
        />,
        <SelectField
            required
            label="Type"
            value={collectorType}
            onChange={withEventTargetAsValue(setCollectorType)}
            description="What type of logs is this forwarder collecting?"
            children={collectorTypeOptions.map((type, index) => (
                <option key={`${type}-${index}`} value={type}>
                    {type}
                </option>
            ))}
        />,
        <Button onClick={createForwarder}>Deploy</Button>,
    ];
}

export { DeployChronicleForwarder };
export default withRequireAbility(
    DeployChronicleForwarder,
    CREATE_ANY_FORWARDER,
);
