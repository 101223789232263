import React from 'react';
import { Pane, Spinner } from 'evergreen-ui';

export default function LoadingSpinner() {
    return (
        <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={400}
            data-testid="loading-spinner"
        >
            <Spinner size={56} />
        </Pane>
    );
}
