import { curry } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import {
    Combobox,
    Label,
    Heading,
    Pane,
    PlusIcon,
    Switch,
    TextInput,
    TextInputField,
    TrashIcon,
} from 'evergreen-ui';

import ActionButton from '../../../../components/ActionButton';
import {
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';
import useFetchDataTypes from '../../../../hooks/useFetchDataTypes';
import { withEventTargetAsValue } from '../../../../utils/common';
import { actions } from '../models/reducer';
import { CollectorMetadataInputs } from './MetadataInputs';
import { noUniqueCharsRegex } from './utils';

const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justify || 'space-between'};
    align-items: ${(props) => props.align};
    margin: ${(props) => props.margin || 0};
    width: ${(props) => props.width || '100%'};
`;
const FlexSection = styled.div`
    width: 47%;
`;

function SplunkCollectorInputs({
    collector,
    handleRemove,
    updateCommentValue,
    updateDataType,
    updateQueryString,
    updateUrl,
}) {
    const [showMetadata, setShowMetadata] = React.useState(false);

    const dataTypes = useFetchDataTypes();
    const { common, splunk } = collector;

    const commentValid =
        noUniqueCharsRegex().test(common.comment) ||
        common.comment.length === 0 ||
        !common.comment;

    return (
        <Pane
            key={collector.tagId}
            data-testid="table-form-row"
            borderBottom="1px solid #d8dae5"
            paddingTop="1.25rem"
            paddingBottom="1.25rem"
        >
            <Pane display="flex">
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        Data Type
                    </Label>
                    <Combobox
                        width="85%"
                        openOnFocus
                        items={dataTypes}
                        value={common.dataType}
                        initialSelectedItem={common.dataType}
                        onChange={updateDataType}
                        border={!common.dataType && '1px solid #D14343'}
                        borderRadius="5.5px"
                    />
                    {!common.dataType && (
                        <Label display="block" color="#D14343">
                            Required
                        </Label>
                    )}
                </Pane>
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        Comment
                    </Label>
                    <TextInput
                        width="85%"
                        value={common.comment}
                        onChange={updateCommentValue}
                        isInvalid={!commentValid}
                        required
                    />
                    {!commentValid && (
                        <Label display="block" color="#D14343">
                            No special characters
                        </Label>
                    )}
                </Pane>
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        Query String
                    </Label>
                    <TextInput
                        width="85%"
                        value={splunk.queryString}
                        isInvalid={!splunk.queryString}
                        onChange={updateQueryString}
                    />
                    {!splunk.queryString && (
                        <Label display="block" color="#D14343">
                            Required
                        </Label>
                    )}
                </Pane>
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        URL
                    </Label>
                    <TextInput
                        width="85%"
                        value={splunk.url}
                        isInvalid={!splunk.url}
                        onChange={updateUrl}
                    />
                    {!splunk.url && (
                        <Label display="block" color="#D14343">
                            Required
                        </Label>
                    )}
                </Pane>
                <Pane
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="1rem"
                >
                    <ActionButton
                        appearance="primary"
                        data-testid="delete-collector-btn"
                        icon={<TrashIcon color="#fff" />}
                        intent="danger"
                        height={40}
                        onClick={handleRemove}
                        shadow="show"
                        shape="circle"
                        type="icon"
                    />
                </Pane>
            </Pane>
            <Pane display="flex">
                <Pane width="22.5%">
                    <Pane
                        display="flex"
                        marginTop={commentValid ? '1rem' : '2rem'}
                    >
                        <Switch
                            checked={showMetadata}
                            onChange={() => setShowMetadata(!showMetadata)}
                            marginRight="0.5rem"
                            id={`switch-${collector.tagId}`}
                            marginLeft="0.175rem"
                        />
                        <Label htmlFor={`switch-${collector.tagId}`}>
                            Configure Metadata
                        </Label>
                    </Pane>
                </Pane>
            </Pane>
            <Pane>
                {showMetadata && (
                    <CollectorMetadataInputs collector={collector} />
                )}
            </Pane>
        </Pane>
    );
}

function SplunkCollectorsSection({ showFormFields }) {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const updateState = curry((collector, actionType, value) =>
        dispatch({ type: actionType, payload: { collector, value } }),
    );
    const insertCollectorInState = updateState(
        null,
        actions.INSERT_SPLUNK_COLLECTOR,
    );
    const handleUpdateSplunkUsername = (event) =>
        dispatch({
            type: actions.UPDATE_SPLUNK_USERNAME,
            payload: event.target.value,
        });
    const handleUpdateSplunkPassword = (event) =>
        dispatch({
            type: actions.UPDATE_SPLUNK_PASSWORD,
            payload: event.target.value,
        });

    return (
        <>
            <Heading size={700} marginBottom="1rem">
                Splunk Credentials
            </Heading>
            <FlexBox>
                {/* required param adds an asterisk (*) to label this may affect testing queries */}
                <FlexSection>
                    <TextInputField
                        label="Username"
                        required
                        disabled={showFormFields ? false : true}
                        value={state.splunkUsername}
                        isInvalid={!state.splunkUsername}
                        onChange={handleUpdateSplunkUsername}
                    />
                    {!state.splunkUsername && (
                        <Label
                            color="#D14343"
                            position="relative"
                            bottom="1rem"
                        >
                            Required
                        </Label>
                    )}
                </FlexSection>
                <FlexSection>
                    <TextInputField
                        label="Password"
                        type={showFormFields ? 'text' : 'password'}
                        disabled={showFormFields ? false : true}
                        value={state.splunkPassword}
                        isInvalid={!state.splunkPassword}
                        onChange={handleUpdateSplunkPassword}
                        required
                    />
                    {!state.splunkPassword && (
                        <Label
                            color="#D14343"
                            position="relative"
                            bottom="1rem"
                        >
                            Required
                        </Label>
                    )}
                </FlexSection>
            </FlexBox>
            <Heading size={700} marginBottom="1rem">
                Data Sources
            </Heading>
            <Pane data-testid="splunk-config-form">
                {state.collectors.map((collector) => {
                    const updateCollectorValueInState = updateState(collector);
                    const handleRemove = updateCollectorValueInState(
                        actions.REMOVE_COLLECTOR,
                    );
                    const updateDataType = updateCollectorValueInState(
                        actions.UPDATE_COMMON_COLLECTOR_DATA_TYPE,
                    );
                    const updateCommentValue = withEventTargetAsValue(
                        updateCollectorValueInState(
                            actions.UPDATE_COMMON_COLLECTOR_COMMENT,
                        ),
                    );
                    const updateQueryString = withEventTargetAsValue(
                        updateCollectorValueInState(
                            actions.UPDATE_SPLUNK_QUERY_STRING,
                        ),
                    );
                    const updateUrl = withEventTargetAsValue(
                        updateCollectorValueInState(actions.UPDATE_SPLUNK_URL),
                    );

                    return (
                        <SplunkCollectorInputs
                            collector={collector}
                            handleRemove={handleRemove}
                            updateCommentValue={updateCommentValue}
                            updateDataType={updateDataType}
                            updateQueryString={updateQueryString}
                            updateUrl={updateUrl}
                            key={`form-row-${collector.tagId}`}
                        />
                    );
                })}

                <Pane display="flex" alignItems="center">
                    <Pane>
                        <ActionButton
                            aria-label="add data type"
                            appearance="primary"
                            data-testid="add-data-btn"
                            height={40}
                            icon={<PlusIcon color="#fff" />}
                            intent="action"
                            marginTop="1rem"
                            onClick={insertCollectorInState}
                            shadow="show"
                            shape="circle"
                            type="icon"
                        />
                    </Pane>
                </Pane>
            </Pane>
        </>
    );
}

export default SplunkCollectorsSection;
