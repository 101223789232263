import React, { Suspense } from 'react';
import { Heading, Text, toaster } from 'evergreen-ui';
import { Redirect } from 'react-router-dom';

import HandleQueryResponse from '../../../../components/HandleQueryResponse';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import withRequireTenant from '../../../../hocs/withRequireTenant';
import useTenant from '../../../../hooks/useTenant';

import { useCloudPcapForwarder } from '../models/hooks';

const PageContainer = React.lazy(() =>
    import('../../../../components/PageContainer'),
);
const CloudPcapForwarderForm = React.lazy(() => import('../Form'));

const CreateChronicleCloudPcapForwarder = () => {
    const [tenant] = useTenant();
    const { meta, models, operations } = useCloudPcapForwarder();

    const { loading, errors, success } = meta;
    const { pcapForwarder } = models;
    const { create } = operations;

    const onSubmit = (values) =>
        create({ ...values, organizationId: tenant.id });

    React.useEffect(() => {
        if (errors.create) toaster.danger(`Error: ${errors.create.message}`);
        if (success.create) toaster.success('Successfully created!');
    }, [errors, success]);

    if (success.create) {
        return (
            <Redirect
                to={`/admin/forwarders/cloud/chronicle/pcap/${pcapForwarder.id}`}
            />
        );
    }

    return (
        <Suspense fallback={<LoadingSpinner />}>
            <HandleQueryResponse response={{ loading, error: errors.create }}>
                <PageContainer enableMargins>
                    <Heading paddingLeft="1rem" size={600}>
                        New Cloud PCAP Forwarder
                    </Heading>
                    <Heading paddingLeft="1rem" marginTop="0.5rem" size={400}>
                        <Text>Organization:</Text> {tenant?.name}
                    </Heading>
                    <CloudPcapForwarderForm onSubmit={onSubmit} />
                </PageContainer>
            </HandleQueryResponse>
        </Suspense>
    );
};

export default withRequireTenant(CreateChronicleCloudPcapForwarder);
