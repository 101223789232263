import { gql } from '@apollo/client';

export const QUERY_CDP_FW_IDENTITY = gql`
    query cdpFwIdentity($id: ID!) {
        cdpForwarderIdentity(id: $id) {
            id
            name
            createdAt
            createdBy
            updatedAt
            updatedBy
            collectorId
            customerId
            customerName
            secretKey {
                type
                projectId
                privateKeyId
                privateKey
                clientEmail
                clientId
                authUri
                tokenUri
                authProviderCertUrl
                clientCertUrl
            }
            organization {
                id
                name
            }
        }
    }
`;

export const UPDATE_CDP_FW_IDENTITY = gql`
    mutation updateCdpFwIdentity(
        $id: ID!
        $props: UpdateCDPForwarderIdentityInput!
    ) {
        cdpForwarderIdentity(id: $id) {
            update(props: $props) {
                id
                name
                createdAt
                createdBy
                updatedAt
                updatedBy
                collectorId
                customerId
                customerName
                secretKey {
                    type
                    projectId
                    privateKeyId
                    privateKey
                    clientEmail
                    clientId
                    authUri
                    tokenUri
                    authProviderCertUrl
                    clientCertUrl
                }
            }
        }
    }
`;

export const DELETE_CDP_FW_IDENTITY = gql`
    mutation deleteCDPFwIdentity($id: ID!) {
        cdpForwarderIdentity(id: $id) {
            remove
        }
    }
`;
