import React from 'react';
import { equals } from 'ramda';
import { Alert } from 'evergreen-ui';

import useAbility from '../../hooks/useAbility';
import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorAlert from '../../components/ErrorAlert';

const withRequireAbility = (Component, ability) => (props) => {
    const { data, loading, error } = useAbility(ability);
    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <ErrorAlert error={error} />;
    }

    if (equals(false, data)) {
        return (
            <Alert
                intent="warning"
                appearance="card"
                title="INVALID PERMISSIONS"
            >
                You do not have permission to perform the requested action.
            </Alert>
        );
    }

    return <Component {...props} />;
};

export default withRequireAbility;
