import { gql } from '@apollo/client';

export const QUERY_ALL_DEPLOYMENTS = gql`
    query queryAllForwarders {
        forwarders(cursor: { limit: 0 }) {
            id
            forwarderType @client
            organization {
                id
                name
            }
            deploymentStatus {
                id
                name
                cluster {
                    id
                    isActive
                }
                createdAt
                createdBy
            }
        }

        cdpForwarders(cursor: { limit: 0 }) {
            id
            forwarderType @client
            organization {
                id
                name
            }
            cdpDeploymentStatus {
                id
                name
                cluster {
                    id
                    isActive
                }
                createdAt
                createdBy
            }
        }
    }
`;

export const QUERY_ORG_DEPLOYMENTS = gql`
    query queryAllTenantForwarders($id: ID!) {
        organization(id: $id) {
            id

            forwarders(cursor: { limit: 0 }) {
                id
                forwarderType @client
                organization {
                    id
                    name
                }
                deploymentStatus {
                    id
                    name
                    cluster {
                        id
                        isActive
                    }
                    createdAt
                    createdBy
                }
            }

            cdpForwarders(cursor: { limit: 0 }) {
                id
                forwarderType @client
                organization {
                    id
                    name
                }
                cdpDeploymentStatus {
                    id
                    name
                    cluster {
                        id
                        isActive
                    }
                    createdAt
                    createdBy
                }
            }
        }
    }
`;
