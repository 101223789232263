import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'ramda';
import { useParams } from 'react-router-dom';

import {
    QUERY_FORWARDER_IDENTITIES,
    QUERY_TENANT_FORWARDER_IDENTITIES,
} from '../ForwarderIdentities/ForwarderIdentities-graphql';
import {
    QUERY_CDP_FW_IDENTITY,
    UPDATE_CDP_FW_IDENTITY,
    DELETE_CDP_FW_IDENTITY,
} from './CDPForwarderIdentity-graphql';

const useQueryCdpForwarderIdentity = (id) => {
    const response = useQuery(QUERY_CDP_FW_IDENTITY, {
        variables: { id },
    });
    return response;
};

const useUpdateCdpForwarderIdentity = () => {
    const [mutate, response] = useMutation(UPDATE_CDP_FW_IDENTITY);
    return [mutate, response];
};

const useDeleteCdpForwarderIdentity = () => {
    const [mutate, response] = useMutation(DELETE_CDP_FW_IDENTITY);
    return [mutate, response];
};

function useCDPForwarderIdentity() {
    const { identityId } = useParams();
    const queryCdpForwarderIdentityResponse = useQueryCdpForwarderIdentity(
        identityId,
    );

    const [
        updateIdentity,
        updateIdentityResponse,
    ] = useUpdateCdpForwarderIdentity();
    const [
        deleteIdentity,
        deleteIdentityResponse,
    ] = useDeleteCdpForwarderIdentity();

    // models
    const cdpForwarderIdentity = pathOr(
        {},
        ['data', 'cdpForwarderIdentity'],
        queryCdpForwarderIdentityResponse,
    );
    const models = { cdpForwarderIdentity };

    //operations
    const update = (values) => {
        updateIdentity({
            variables: {
                id: identityId,
                props: values,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: QUERY_FORWARDER_IDENTITIES,
                },
                {
                    query: QUERY_TENANT_FORWARDER_IDENTITIES,
                    variables: {
                        id: cdpForwarderIdentity.organization.id,
                    },
                },
            ],
        });
    };
    const deleteKey = () => {
        deleteIdentity({
            variables: {
                id: identityId,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: QUERY_FORWARDER_IDENTITIES,
                },
                {
                    query: QUERY_TENANT_FORWARDER_IDENTITIES,
                    variables: {
                        id: cdpForwarderIdentity.organization.id,
                    },
                },
            ],
        });
    };
    const operations = { update, deleteKey };

    // meta
    const loading = queryCdpForwarderIdentityResponse.loading;
    const loadError = queryCdpForwarderIdentityResponse.error;
    const saving = updateIdentityResponse.loading;
    const saveError = updateIdentityResponse.error;
    const saveSuccess = Boolean(updateIdentityResponse.data);
    const deleting = deleteIdentityResponse.loading;
    const deleteError = deleteIdentityResponse.error;
    const deleteSuccess = Boolean(deleteIdentityResponse.data);
    const meta = {
        loading,
        loadError,
        saving,
        saveSuccess,
        saveError,
        deleting,
        deleteError,
        deleteSuccess,
    };

    return {
        meta,
        models,
        operations,
    };
}

export default useCDPForwarderIdentity;
