import { gql } from '@apollo/client';
import { path } from 'ramda';

import useQuerySelector from '../useQuerySelector';

const QUERY_TENANT_CDP_ID = gql`
    query queryCdpForwarderIds($id: ID!) {
        organization(id: $id) {
            id
            cdpForwarderIdentities {
                id
                name
                createdAt
                createdBy
                updatedAt
                updatedBy
                collectorId
                customerId
                secretKey {
                    type
                    projectId
                    privateKeyId
                    privateKey
                    clientEmail
                    clientId
                    authUri
                    tokenUri
                    authProviderCertUrl
                    clientCertUrl
                }
            }
        }
    }
`;

function useQueryTenantCdpIdentities(id) {
    const queryVariables = {
        variables: {
            id,
        },
    };
    const response = useQuerySelector(
        QUERY_TENANT_CDP_ID,
        queryVariables,
        path(['organization', 'cdpForwarderIdentities']),
    );

    if (!id) {
        return {
            data: null,
            loading: null,
            error: {
                message:
                    'MISSING ORGANIZATION ID! Please verify an organization is selected. Location useQueryTenantCdpIdentities.',
            },
        };
    }

    return response;
}

export default useQueryTenantCdpIdentities;
