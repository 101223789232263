import { useMutation, useQuery } from '@apollo/client';
import { lensPath, over } from 'ramda';

import { anyIsLoading } from '../../../../utils/common';
import { QUERY_TENANT_CLOUD_FORWARDERS } from '../../CloudForwardersTable/graphql';
import {
    CREATE_SPLUNK_CLOUD_FW,
    REMOVE_SPLUNK_CLOUD_FW,
    UPDATE_SPLUNK_CLOUD_FW,
    QUERY_SPLUNK_CLOUD_FW,
} from './graphql';
import { injectDefaults } from './utils';

const retrieveOrganization = (cache, id) =>
    cache.readQuery({
        query: QUERY_TENANT_CLOUD_FORWARDERS,
        variables: {
            id,
        },
    });

const updateOrganizationCache = (cache, id, data) =>
    cache.writeQuery({
        query: QUERY_TENANT_CLOUD_FORWARDERS,
        variables: {
            id,
        },
        data,
    });

const cacheInsert = (cache, response) => {
    const forwarder = response.data.createChronicleCloudSplunkForwarder;
    const organizationId = forwarder?.organizationId;
    const organization = retrieveOrganization(cache, organizationId);

    const insert = (values = []) => [...values, forwarder];
    const collectorLens = lensPath([
        'organization',
        'chronicleCloudSplunkForwarders',
    ]);
    const data = over(collectorLens, insert, organization);

    updateOrganizationCache(cache, organization?.id, data);
};

const cacheRemove = (cache, response) => {
    const id = response.data.removeChronicleCloudSplunkForwarder;
    cache.evict({
        id: cache.identify({ id, __typename: 'ChronicleCloudSplunkForwarder' }),
    });
};

export function useCloudSplunkForwarder(id) {
    const props = { onError: () => {} };
    const [_create, createResponse] = useMutation(
        CREATE_SPLUNK_CLOUD_FW,
        props,
    );
    const [_update, updateResponse] = useMutation(
        UPDATE_SPLUNK_CLOUD_FW,
        props,
    );
    const [_remove, removeResponse] = useMutation(
        REMOVE_SPLUNK_CLOUD_FW,
        props,
    );
    const query = useQuery(QUERY_SPLUNK_CLOUD_FW, {
        variables: { id },
        skip: !id,
    });

    const meta = {
        loading: anyIsLoading([
            query,
            createResponse,
            updateResponse,
            removeResponse,
        ]),
        errors: {
            query: query.error,
            create: createResponse.error,
            update: updateResponse.error,
            remove: removeResponse.error,
        },
        success: {
            create: !!createResponse?.data,
            update: !!updateResponse?.data,
            remove: !!removeResponse?.data,
        },
    };

    const models = {
        splunkForwarder:
            createResponse?.data?.createChronicleCloudSplunkForwarder ||
            updateResponse?.data?.updateChronicleCloudSplunkForwarder ||
            query?.data?.chronicleCloudSplunkForwarder ||
            null,
    };

    const create = (props, ...rest) =>
        _create({
            variables: {
                props: injectDefaults(props),
                ...rest,
            },
            update: cacheInsert,
        });
    const update = (props, ...rest) =>
        _update({ variables: { id, props: injectDefaults(props) }, ...rest });
    const remove = () => _remove({ variables: { id }, update: cacheRemove });
    const operations = {
        create,
        update,
        remove,
    };

    return { meta, models, operations };
}
