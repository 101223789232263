import { useEffect } from 'react';

import useAuthContext from '../hooks/useAuthContext';
import useTenant from '../hooks/useTenant';
import { isAuthedOrganizationUserOrAdmin } from '../utils/auth';
import { isNotEmptyOrNil } from '../utils/common';

export default function TenantProvider({ children }) {
    const { data: authContext } = useAuthContext();
    const [_, setTenant] = useTenant();

    useEffect(() => {
        if (
            authContext &&
            isNotEmptyOrNil(authContext) &&
            isAuthedOrganizationUserOrAdmin(authContext)
        ) {
            const { organization } = authContext;
            setTenant({
                id: organization.id,
                name: organization.name,
            });
        }
    }, [authContext, setTenant]);

    return children;
}
