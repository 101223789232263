/* eslint-disable */
import { gql } from '@apollo/client';

export const QUERY_LOG_TYPES = gql`
    query fetchAllLogTypes {
        logTypes(cursor: { limit: 0, order: ASC }) {
            logType
            description
        }
    }
`;
