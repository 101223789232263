import { prop } from 'ramda';

import useQuerySelector from '../../../hooks/useQuerySelector';
import { QUERY_ALL_ORG_CYCLOPS_FORWARDERS } from './CyclopsDataSourcesTable-graphql';

const useCyclopsForwarders = (queryVariables) => {
    const response = useQuerySelector(
        QUERY_ALL_ORG_CYCLOPS_FORWARDERS,
        { variables: queryVariables },
        prop('organization'),
    );
    return response;
};

export default useCyclopsForwarders;
