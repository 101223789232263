import {
    allPass,
    equals,
    filter,
    path,
    pipe,
    dissoc,
    is,
    map,
    replace,
    when,
} from 'ramda';

import { isNotEmptyOrNil } from './common';

const syslog = 'syslog';
const pcap = 'pcap';
const splunk = 'splunk';
export const isTypeSyslog = equals(syslog);
export const isTypePcap = equals(pcap);
export const isTypeSplunk = equals(splunk);
export const hasTlsRawCert = pipe(
    path(['syslog', 'tlsRawCert']),
    isNotEmptyOrNil,
);
export const hasTlsRawKey = pipe(
    path(['syslog', 'tlsRawKey']),
    isNotEmptyOrNil,
);
export const hasTlsCertAndKey = allPass([hasTlsRawCert, hasTlsRawKey]);
export const filterCollectorsWithTls = filter(hasTlsCertAndKey);
export const dissocTypename = when(is(Object), dissoc('__typename'));
export const dissocTypenameFromEachObject = map(dissocTypename);
export const dissocTypenamesFromCollectorsArray = pipe(
    dissocTypenameFromEachObject,
    map(dissocTypenameFromEachObject),
);

/**
 * Trims a str of text before the first '-' and after the last '-', ex. cyderes-my-deployment-inappropriateUCN => my-deployment
 * @param {*} str
 */
export const trimDeploymentUCN = (str) => {
    const startTrim = new RegExp(/^(\w*[-])/gm, 'i');
    const endTrim = new RegExp(/[-]\w*$/gm, 'i');
    return str.replace(startTrim, '').replace(endTrim, '');
};
export const trimCDPDeploymentPrefix = replace(/^(cdp[-])/gm, '');
