import { flatten, groupBy, isEmpty, map, mergeLeft } from 'ramda';

const normalizeCDPCloudCollector = (collector) => {
    const { port, common } = collector;
    const { dataType, enabled } = common;
    return { port, dataType, enabled };
};

// const normalizeChronicleCloudSyslogCollector = (collector) => {
//     const { port, common } = collector;
//     const { dataType, enabled } = common;
//     return { port, dataType, enabled };
// };
const normalizeChronicleCloudPcapCollector = (collector) => {
    const { common } = collector;
    const { dataType, enabled } = common;
    const port = 'see configuration';
    return { port, dataType, enabled };
};
// const normalizeChronicleCloudSplunkCollector = (collector) => {
//     const { common } = collector;
//     const { dataType, enabled } = common;
//     const port = 'see configuration';
//     return { port, dataType, enabled };
// };

const addForwarderMetadata = (id, name, collectorType, destinationRef) => ({
    id,
    forwarderName: name,
    chronicle: true,
    collectorType,
    destinationRef,
});

const normalizeCDPCloudForwarder = (cdpCloudForwarder) => {
    const { id, name, configuration } = cdpCloudForwarder;
    const { collectors, destinationRef } = configuration;
    const normalizedCollectors = map(normalizeCDPCloudCollector, collectors);
    const deployments = flatten(normalizedCollectors);
    const withMetadata = addForwarderMetadata(
        id,
        name,
        'CDP Cloud Forwarder',
        destinationRef,
    );
    return map(mergeLeft(withMetadata), deployments);
};

// const normalizeChronicleCloudSyslogForwarders = (
//     chronicleCloudSyslogForwarders,
// ) => {
//     const { id, name, configuration } = chronicleCloudSyslogForwarders;
//     const { syslog: collectors } = configuration;
//     const destinationRef = ['chronicle'];

//     const normalizedCollectors = map(
//         normalizeChronicleCloudSyslogCollector,
//         collectors,
//     );
//     const deployments = flatten(normalizedCollectors);
//     const withMetadata = addForwarderMetadata(
//         id,
//         name,
//         'Chronicle Cloud Syslog Forwarder',
//         destinationRef,
//     );
//     return map(mergeLeft(withMetadata), deployments);
// };
const normalizeChronicleCloudPcapForwarders = (
    chronicleCloudPcapForwarders,
) => {
    const { id, name, configuration } = chronicleCloudPcapForwarders;
    const { pcap: collectors } = configuration;
    const destinationRef = ['chronicle'];

    const normalizedCollectors = map(
        normalizeChronicleCloudPcapCollector,
        collectors,
    );
    const deployments = flatten(normalizedCollectors);
    const withMetadata = addForwarderMetadata(
        id,
        name,
        'Chronicle Cloud Pcap Forwarder',
        destinationRef,
    );
    return map(mergeLeft(withMetadata), deployments);
};
// const normalizeChronicleCloudSplunkForwarders = (
//     chronicleCloudSplunkForwarders,
// ) => {
//     const { id, name, configuration } = chronicleCloudSplunkForwarders;
//     const { splunk: collectors } = configuration;
//     const destinationRef = ['chronicle'];

//     const normalizedCollectors = map(
//         normalizeChronicleCloudSplunkCollector,
//         collectors,
//     );
//     const deployments = flatten(normalizedCollectors);
//     const withMetadata = addForwarderMetadata(
//         id,
//         name,
//         'Chronicle Cloud Splunk Forwarder',
//         destinationRef,
//     );
//     return map(mergeLeft(withMetadata), deployments);
// };

const normalizeCloudForwarders = (cloudForwarders) => {
    const {
        // chronicleCloudSyslogForwarders,
        chronicleCloudPcapForwarders,
        // chronicleCloudSplunkForwarders,
        cdpCloudForwarders,
    } = cloudForwarders;
    const normalizedCDPCloudForwarders = flatten(
        map(normalizeCDPCloudForwarder, cdpCloudForwarders),
    );
    // const normalizedChronicleCloudSyslogForwarders = flatten(
    //     map(
    //         normalizeChronicleCloudSyslogForwarders,
    //         chronicleCloudSyslogForwarders,
    //     ),
    // );
    const normalizedChronicleCloudPcapForwarders = flatten(
        map(
            normalizeChronicleCloudPcapForwarders,
            chronicleCloudPcapForwarders,
        ),
    );
    // const normalizedChronicleCloudSplunkForwarders = flatten(
    //     map(
    //         normalizeChronicleCloudSplunkForwarders,
    //         chronicleCloudSplunkForwarders,
    //     ),
    // );
    return [
        ...normalizedCDPCloudForwarders,
        // ...normalizedChronicleCloudSyslogForwarders,
        ...normalizedChronicleCloudPcapForwarders,
        // ...normalizedChronicleCloudSplunkForwarders,
    ];
};

const groupByDatatype = groupBy((collector) => {
    const { dataType } = collector;
    return dataType === undefined ? 'UNKNOWN' : dataType;
});

export const resolveCloudCollector = (queryData) => {
    const {
        // chronicleCloudSyslogForwarders,
        chronicleCloudPcapForwarders,
        // chronicleCloudSplunkForwarders,
        cdpCloudForwarders,
    } = queryData;
    const normalizedCloudForwardersArray = normalizeCloudForwarders({
        // chronicleCloudSyslogForwarders,
        chronicleCloudPcapForwarders,
        // chronicleCloudSplunkForwarders,
        cdpCloudForwarders,
    });
    const dataTypes = groupByDatatype(normalizedCloudForwardersArray);
    const dataTypeArray = Object.entries(dataTypes);
    const normalizedDataTypesArray = dataTypeArray.map(([key, value]) => {
        const dataType = {};
        dataType['name'] = key;
        dataType['collectors'] = value;

        return dataType;
    });
    return normalizedDataTypesArray;
};

export const resolveCloudCollectorResponse = (data) => {
    if (isEmpty(data)) {
        return [];
    }
    const response = resolveCloudCollector(data);
    return response;
};
