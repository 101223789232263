import { gql } from '@apollo/client';

export const QUERY_ALL_ORG_CYCLOPS_FORWARDERS = gql`
    query queryOrganizationCollectors($id: ID!) {
        organization(id: $id) {
            forwarders(cursor: { limit: 0 }) {
                id
                name
                deploymentStatus {
                    status
                    chronicleForwarderConfiguration {
                        collectors {
                            syslog {
                                port
                            }
                            collectorType
                            common {
                                dataType
                                enabled
                            }
                        }
                    }
                }
            }
            cdpForwarders(cursor: { limit: 0 }) {
                id
                name
                cdpDeploymentStatus {
                    status
                    cdpForwarderConfiguration {
                        destinationRef
                        collectors {
                            collectorType
                            syslog {
                                port
                            }
                            common {
                                dataType
                                enabled
                                destinationRef
                            }
                        }
                    }
                }
            }
        }
    }
`;
