import { propOr } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'evergreen-ui';
import dayjs from 'dayjs';

import { isEmptyOrNil, isNotEmptyOrNil } from '../../../utils/common';
import { CHRONICLE, CDP } from '../../../utils/constants';

const LinkToIdentityDashboard = ({ id, forwarderType, name }) => {
    return (
        <Link
            to={`/admin/pipeline-secrets/${forwarderType}/${id}`}
            data-testid="identity-dashboard-link"
        >
            {name}
        </Link>
    );
};

function IdentityTypeBadge({ forwarderType }) {
    const badgeStyles = {
        [CDP]: 'blue',
        [CHRONICLE]: 'orange',
    };
    const color = propOr('yellow', forwarderType, badgeStyles);

    return (
        <Badge color={color} data-testid={`identity-badge-${color}`}>
            {forwarderType}
        </Badge>
    );
}

const MostRecentUpdateDate = ({ createdAt, updatedAt }) => {
    let mostRecentUpdateDate = createdAt;

    if (isNotEmptyOrNil(updatedAt)) {
        mostRecentUpdateDate = updatedAt;
    }

    if (isEmptyOrNil(mostRecentUpdateDate)) {
        return <span>no updates</span>;
    }

    const userFriendlyDateString = dayjs(mostRecentUpdateDate).format(
        'MM/DD/YYYY',
    );

    return <span>{userFriendlyDateString}</span>;
};

const LastUserToUpdate = ({ createdBy, updatedBy }) => {
    let lastUserToUpdate = createdBy;

    if (isNotEmptyOrNil(updatedBy)) {
        lastUserToUpdate = updatedBy;
    }

    if (isEmptyOrNil(lastUserToUpdate)) {
        lastUserToUpdate = 'N/A';
    }

    return <span>{lastUserToUpdate}</span>;
};

const withRowDataAsProps = (Component) => (props) => {
    return <Component {...props} />;
};

const columns = [
    {
        title: 'Name',
        path: ['name'],
        render: withRowDataAsProps(LinkToIdentityDashboard),
    },
    {
        title: 'Pipeline',
        path: ['forwarderType'],
        render: withRowDataAsProps(IdentityTypeBadge),
    },
    {
        title: 'Organization',
        path: ['organization', 'name'],
    },
    {
        title: 'Last Updated',
        render: withRowDataAsProps(MostRecentUpdateDate),
    },
    {
        title: 'Updated By',
        render: withRowDataAsProps(LastUserToUpdate),
    },
];

export {
    LinkToIdentityDashboard,
    IdentityTypeBadge,
    MostRecentUpdateDate,
    LastUserToUpdate,
    withRowDataAsProps,
};
export default columns;
