import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { map, pipe, values } from 'ramda';

import withRequireAdmin from '../hocs/withRequireAdmin';

export const buildSecureRoute = (route) => {
    const { admin, component, exact, path } = route;

    let Component = component;
    if (admin) {
        Component = withRequireAdmin(component);
    }

    return (
        <SecureRoute
            path={path}
            exact={exact}
            component={Component}
            key={`${path}-secure-route`}
        />
    );
};

export const buildInsecureRoute = (route) => {
    const { component, exact, path } = route;

    const Component = component;

    return (
        <Route
            exact={exact}
            path={path}
            component={Component}
            key={`${path}-insecure-route`}
        />
    );
};

export const buildRoute = (route) => {
    const { secure } = route;

    if (secure) {
        return buildSecureRoute(route);
    }

    return buildInsecureRoute(route);
};

const buildRedirectRoute = (route) => {
    const { old } = route;

    if (!route.component) {
        return (
            <SecureRoute
                path={old}
                exact
                render={() => <Redirect to={route.redirect} />}
                key={`redirect-${old}-${route.redirect}`}
            />
        );
    }
    return (
        <SecureRoute
            path={old}
            component={route.component}
            key={`redirect-${old}`}
        />
    );
};

export const buildRoutes = map(buildRoute);
export const buildRoutesArray = pipe(buildRoutes, values);
export const buildRedirectRoutes = map(buildRedirectRoute);
export const buildRedirectRoutesArray = pipe(buildRedirectRoutes, values);
