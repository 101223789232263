import React, { Suspense } from 'react';
import { Heading, Pane, Text, toaster } from 'evergreen-ui';
import { Redirect, useParams } from 'react-router-dom';

import HandleQueryResponse from '../../../../components/HandleQueryResponse';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import withRequireTenant from '../../../../hocs/withRequireTenant';
import useTenant from '../../../../hooks/useTenant';
import { isEmptyOrNil } from '../../../../utils/common';

import { useCloudSyslogForwarder } from '../models/hooks';

const PageContainer = React.lazy(() =>
    import('../../../../components/PageContainer'),
);
const CloudSyslogForwarderForm = React.lazy(() => import('../Form'));

export const MISSING_ID_MSG = 'Error: Missing ID. Cannot retrieve forwarder.';

const ManageChronicleCloudSyslogForwarder = () => {
    const [tenant] = useTenant();
    const params = useParams();
    const id = params.id;
    const { meta, models, operations } = useCloudSyslogForwarder(id);

    const { loading, errors, success } = meta;
    const { syslogForwarder } = models;
    const { update, remove } = operations;

    const [error] = [errors.remove, errors.update].filter(
        (value) => value?.message && value,
    );

    const onSubmit = (values) =>
        update({
            ...values,
            organizationId: syslogForwarder?.organizationId,
        });

    React.useEffect(() => {
        if (error) toaster.danger(`Error: ${error.message}`);
        if (success.update) toaster.success('Successfully updated!');
        if (success.remove) toaster.success('Successfully removed!');
        if (isEmptyOrNil(id) || id === 'undefined' || id === 'null')
            toaster.danger(MISSING_ID_MSG);
    }, [error, id, success]);

    if (isEmptyOrNil(id) || id === 'undefined' || id === 'null') {
        return <Redirect to="/admin/forwarders/cloud" />;
    }

    if (success.remove) {
        return <Redirect to="/admin/forwarders/cloud" />;
    }

    return (
        <Suspense fallback={<LoadingSpinner />}>
            <HandleQueryResponse response={{ loading, error: errors.create }}>
                <PageContainer enableMargins>
                    <Heading paddingLeft="1rem" size={600}>
                        {syslogForwarder?.name}
                    </Heading>
                    <Pane display="flex" paddingLeft="1rem">
                        <Heading marginTop="0.5rem" size={400}>
                            <Text>ID:</Text> {syslogForwarder?.id}
                        </Heading>
                        <Heading
                            paddingLeft="1rem"
                            marginTop="0.5rem"
                            size={400}
                        >
                            <Text>Organization:</Text> {tenant?.name}
                        </Heading>
                    </Pane>
                    <CloudSyslogForwarderForm
                        onSubmit={onSubmit}
                        onRemove={remove}
                        values={syslogForwarder}
                    />
                </PageContainer>
            </HandleQueryResponse>
        </Suspense>
    );
};

export default withRequireTenant(ManageChronicleCloudSyslogForwarder);
