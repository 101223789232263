const colors = {
    primary: '#5195d44f',
    secondary: '#1B3247',
    white: '#fff',
    black: '#000',
    orange: '#f4af58',
    lightblue: '#78b0d6',
};

export default colors;
