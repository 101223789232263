import { prop } from 'ramda';

import useQuerySelector from '../../../hooks/useQuerySelector';
import { QUERY_ALL_ORG_CLOUD_FORWARDERS } from './CloudDataSourcesTable-graphql';

const useCloudForwarders = (queryVariables) => {
    const response = useQuerySelector(
        QUERY_ALL_ORG_CLOUD_FORWARDERS,
        { variables: queryVariables },
        prop('organization'),
    );
    return response;
};

export default useCloudForwarders;
