import { match, equals, path, not } from 'ramda';
import { resolve } from '@specialblend/react-process-env';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { useEffect, useCallback } from 'react';

import useAuthContext from '../hooks/useAuthContext';

const GAID = resolve('REACT_APP_GA_ID', process.env) || 'UA-147335924-3';

export default function WithAnalytics({ children }) {
    const history = useHistory();
    const authContext = useAuthContext();

    const isNotFishtech = (email) => {
        const matchEmailDomainRegex = /(?:@)(\w+)/gm;
        const [emailDomain] = match(matchEmailDomainRegex, email);
        return not(equals(emailDomain, '@fishtech'));
    };

    const sendPageView = useCallback((location) => {
        ReactGA.set({
            page: location.pathname,
        });
        return ReactGA.pageview(location.pathname);
    }, []);
    if (authContext && authContext.data) {
        const userEmail = path(['data', 'user', 'username'], authContext);
        const organizationId = path(
            ['data', 'organization', 'id'],
            authContext,
        );
        if (isNotFishtech(userEmail)) {
            ReactGA.set({
                userId: organizationId,
            });
        }
    }

    useEffect(() => {
        ReactGA.initialize(GAID);
        sendPageView(history.location);
        return history.listen(sendPageView);
    }, [history, sendPageView]);

    return children;
}

WithAnalytics.propTypes = {
    children: PropTypes.node.isRequired,
};
