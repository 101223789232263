import React from 'react';
import { Link } from 'react-router-dom';

import withRowDataAsProps from '../../../hocs/withRowDataAsProps';

const buildUrl = (cluster) => {
    const base = `/admin/clusters`;
    const dynamic = `${cluster.id}`;
    const url = `${base}/${dynamic}`;
    return url;
};

const NameCell = (cluster) => {
    const url = buildUrl(cluster);
    const { name } = cluster;

    return <Link to={url}>{name}</Link>;
};

const columns = [
    {
        title: 'Name',
        path: ['name'],
        render: withRowDataAsProps(NameCell),
    },
    {
        title: 'Organization',
        path: ['organization', 'name'],
    },
    {
        title: 'ID',
        path: ['id'],
    },
    {
        title: 'UCN',
        path: ['ucn'],
    },
    {
        title: 'Last Updated',
        path: ['updatedAt'],
    },
    {
        title: 'Updated By',
        path: ['updatedBy'],
    },
];

export default columns;
