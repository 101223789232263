import {
    AddIcon,
    Button,
    Heading,
    IconButton,
    Label,
    Pane,
    Text,
    TextInput,
    TrashIcon,
} from 'evergreen-ui';
import React from 'react';

import {
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';
import { actions } from '../models/reducer';

import { rfc1123DnsRegex, noUniqueCharsRegex } from './utils';

export function MetadataInputs({
    updateNamespace,
    addLabel,
    updateLabel,
    removeLabel,
    metadata,
}) {
    const namespace = metadata?.namespace || '';
    const labels = metadata?.labels || [];

    const namespaceIsValid = namespace
        ? rfc1123DnsRegex().test(namespace)
        : true;

    return (
        <Pane marginBottom="1rem" width="100%">
            <Label marginTop="1rem" display="block" marginBottom="0.25rem">
                Namespace
            </Label>
            <TextInput
                width="calc(50% - 0.75rem)"
                value={namespace}
                onChange={updateNamespace}
                isInvalid={!namespaceIsValid}
            />
            {!namespaceIsValid && (
                <Text marginLeft="0.25rem" color="#D14343" fontWeight="500">
                    Invalid characters
                </Text>
            )}
            <Pane width="65%" marginTop="1rem">
                <Label display="block" marginBottom="0.25rem">
                    Labels
                </Label>
                {labels.map((label, index) => {
                    const keyIsValid = rfc1123DnsRegex().test(label.key);
                    const valueIsValid = noUniqueCharsRegex().test(label.value);

                    return (
                        <Pane
                            key={`global-label-${index}`}
                            display="flex"
                            marginTop="0.5rem"
                        >
                            <TextInput
                                width="37.5%"
                                value={label.key}
                                placeholder="Enter label key"
                                marginRight="0.25rem"
                                onChange={updateLabel(index, 'key')}
                                isInvalid={!keyIsValid}
                            />
                            <TextInput
                                width="37.5%"
                                value={label.value}
                                placeholder="Enter label value"
                                marginLeft="0.25rem"
                                onChange={updateLabel(index, 'value')}
                                isInvalid={!valueIsValid}
                            />
                            <IconButton
                                icon={TrashIcon}
                                marginLeft="0.5rem"
                                onClick={removeLabel(index)}
                            />
                            <Pane
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {(!keyIsValid || !valueIsValid) && (
                                    <Text
                                        marginLeft="0.25rem"
                                        color="#D14343"
                                        fontWeight="500"
                                    >
                                        {!label.key || !label.value
                                            ? 'Required'
                                            : 'Invalid characters'}
                                    </Text>
                                )}
                            </Pane>
                        </Pane>
                    );
                })}
                <Button
                    marginTop="1rem"
                    onClick={addLabel}
                    iconBefore={AddIcon}
                >
                    Add a Label
                </Button>
            </Pane>
        </Pane>
    );
}

export function ForwarderMetadataInputs() {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const updateNamespace = (event) =>
        dispatch({
            type: actions.UPDATE_FORWARDER_NAMESPACE,
            payload: { value: event.target.value },
        });
    const addLabel = () => dispatch({ type: actions.ADD_FORWARDER_LABEL });
    const updateLabel = (index, field) => (event) =>
        dispatch({
            type: actions.UPDATE_FORWARDER_LABEL,
            payload: { index, field, value: event.target.value },
        });
    const removeLabel = (index) => () =>
        dispatch({ type: actions.REMOVE_FORWARDER_LABEL, payload: { index } });

    return (
        <>
            <Heading size={700} id="global-metadata" marginBottom="0.75rem">
                Metadata
            </Heading>
            <MetadataInputs
                updateNamespace={updateNamespace}
                addLabel={addLabel}
                updateLabel={updateLabel}
                removeLabel={removeLabel}
                destinations={state.destinationRef}
                metadata={state.metadata}
            />
        </>
    );
}

export function CollectorMetadataInputs({ collector }) {
    const dispatch = useDispatchContext();
    const { common } = collector;
    const metadata = common?.metadata;
    const destinations = common?.destinationRef;

    const addLabel = () =>
        dispatch({ type: actions.ADD_COLLECTOR_LABEL, payload: { collector } });
    const removeLabel = (index) => () => {
        dispatch({
            type: actions.REMOVE_COLLECTOR_LABEL,
            payload: {
                collector,
                index,
            },
        });
    };
    const updateLabel = (index, field) => ({ target: { value } }) => {
        dispatch({
            type: actions.UPDATE_COMMON_COLLECTOR_LABEL,
            payload: {
                collector,
                index,
                field,
                value,
            },
        });
    };
    const updateNamespace = ({ target: { value } }) =>
        dispatch({
            type: actions.UPDATE_SYSLOG_COLLECTOR_NAMESPACE,
            payload: { collector, value },
        });

    return (
        <MetadataInputs
            updateNamespace={updateNamespace}
            addLabel={addLabel}
            updateLabel={updateLabel}
            removeLabel={removeLabel}
            destinations={destinations}
            metadata={metadata}
        />
    );
}
