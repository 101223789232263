import { flatten } from 'ramda';

/**
 * Normalize cdp or chronicle forwarder deployment logs for display in the ForwardersTable.
 * The forwarder objects are just configurations so use the deployment logs to indicate what is currently active.
 */
const normalizeDeployment = (
    deployment,
    forwarderId,
    forwarderType,
    organization,
) => {
    const { cluster, createdAt, createdBy, id, name } = deployment;
    return {
        createdAt,
        createdBy,
        id,
        forwarderId,
        forwarderType,
        isActive: cluster?.isActive || false,
        name,
        organization,
    };
};

const normalizeDeployments = (forwarder, deployments) => {
    const { forwarderType, id: forwarderId, organization } = forwarder;
    return deployments.map((deployment) =>
        normalizeDeployment(
            deployment,
            forwarderId,
            forwarderType,
            organization,
        ),
    );
};

const normalizeChronicleForwarder = (forwarder) =>
    normalizeDeployments(forwarder, forwarder.deploymentStatus);

const normalizeCdpForwarder = (cdpForwarder) =>
    normalizeDeployments(cdpForwarder, cdpForwarder.cdpDeploymentStatus);

/**
 * Use to help filter out forwarders previously deployed to now inactive clusters.
 * Ideally, in the future, this would somehow be handled in the API.
 */
const isActive = (normalizedForwarder) =>
    normalizedForwarder?.isActive || false;

/**
 * Grab, flatten, and normalize all currently active chronicle forwarder deployments
 */
export const normalizeChronicleForwarders = (forwarders) =>
    flatten(forwarders.map(normalizeChronicleForwarder)).filter(isActive);

/**
 * Grab, flatten, and normalize all currently active cdp forwarder deployments
 */
export const normalizeCdpForwarders = (cdpForwarders) =>
    flatten(cdpForwarders.map(normalizeCdpForwarder)).filter(isActive);
