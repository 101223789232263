import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Label, Pane, Switch, Tab, Tablist } from 'evergreen-ui';
import styled from 'styled-components';
import { equals } from 'ramda';

import ActionButton from '../../../../components/ActionButton';
import { isNotEmptyOrNil } from '../../../../utils/common';
import {
    isTypePcap,
    isTypeSplunk,
    isTypeSyslog,
} from '../../../../utils/forwarders';
import {
    actions,
    addCredentialsToCollectors,
    initialState,
    reducer,
} from '../models/reducer';
import InfrastructureSection from './InfrastructureSection';
import PcapCollectorsSection from './PcapCollectorsSection';
import SplunkCollectorsSection from './SplunkCollectorsSection';
import SyslogCollectorsSection from './SyslogCollectorsSection';
import DangerZoneSection from './DangerZoneSection';

import { ForwarderMetadataInputs } from './MetadataInputs';
import {
    StateReducerProvider,
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';
import useTenant from '../../../../hooks/useTenant';
import { validateState } from './utils';

const FlexBoxBtnContainer = styled.div`
    width: 100%;
    margin: 1vh 0;
    display: flex;
    justify-content: flex-end;
`;

const StyledTab = styled(Tab)`
    &:focus {
        box-shadow: none;
        outline: none;
    }
`;

const Section = ({ isDisplayed, children }) => (
    <Pane display={isDisplayed ? 'block' : 'none'}>{children}</Pane>
);

function Form({ isLoading, handleSubmit, handleRemove, handleClone, values }) {
    const [tenant] = useTenant();
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const [showMetadata, setShowMetadata] = useState(false);
    const [invalidTls, setInvalidTls] = useState(false);

    const [tab, setTab] = useState(0);
    const tabEquals = equals(tab);
    const selectTab = (value) => () => setTab(value);

    const mapRemoteForwarderToState = useCallback(
        () =>
            dispatch({
                type: actions.MAP_REMOTE_FORWARDER_TO_STATE,
                payload: values,
            }),
        [values, dispatch],
    );

    const _variables = useMemo(() => addCredentialsToCollectors(state), [
        state,
    ]);

    const handleSaveBtnOnClick = async () => {
        let tlsInvalid = false;
        const vars = {
            variables: {
                id: _variables.forwarderId,
                props: {
                    name: _variables.name,
                    organizationId: tenant.id,
                    configuration: {
                        infrastructure: _variables.infrastructure,
                        collectors: _variables.collectors,
                        metadata: _variables.metadata,
                    },
                },
            },
        };
        vars.variables.props.configuration.collectors.forEach((x) => {
            if (isTypeSyslog(state.collectorType)) {
                if (
                    x.syslog.tlsEnabled &&
                    (!x.syslog.tlsRawCert || !x.syslog.tlsRawKey)
                ) {
                    tlsInvalid = true;
                }
            }
        });
        setInvalidTls(tlsInvalid);

        if (!tlsInvalid) {
            handleSubmit(vars);
        }
    };

    useEffect(() => {
        if (isNotEmptyOrNil(values)) {
            mapRemoteForwarderToState();
        }
    }, [dispatch, values, mapRemoteForwarderToState]);

    return (
        <Pane data-testid="chronicle-forwarder-form">
            <FlexBoxBtnContainer>
                <Button onClick={handleClone}>Clone</Button>
                <ActionButton
                    appearance="primary"
                    disabled={
                        isLoading ||
                        !state.collectors.length ||
                        !validateState(state)
                    }
                    intent="action"
                    isLoading={isLoading}
                    marginLeft="1rem"
                    onClick={handleSaveBtnOnClick}
                    shadow="show"
                >
                    Save
                </ActionButton>
            </FlexBoxBtnContainer>
            <Pane>
                <Tablist borderBottom="1px solid black" marginBottom="1rem">
                    <StyledTab
                        isSelected={tabEquals(0)}
                        onSelect={selectTab(0)}
                    >
                        Data Collection
                    </StyledTab>
                    <StyledTab
                        isSelected={tabEquals(1)}
                        onSelect={selectTab(1)}
                    >
                        Infrastructure
                    </StyledTab>
                    <StyledTab
                        isSelected={tabEquals(2)}
                        onSelect={selectTab(2)}
                    >
                        Danger Zone
                    </StyledTab>
                </Tablist>
            </Pane>
            <Section isDisplayed={tabEquals(0)}>
                <Pane display="flex" marginBottom="1rem">
                    <Switch
                        checked={showMetadata}
                        onChange={() => setShowMetadata(!showMetadata)}
                        marginRight="0.5rem"
                        id="metadata-switch"
                    />
                    <Label htmlFor="metadata-switch">
                        Configure Forwarder Metadata
                    </Label>
                </Pane>
                {showMetadata && <ForwarderMetadataInputs />}
                {isTypePcap(state.collectorType) && (
                    <PcapCollectorsSection showFormFields={true} />
                )}
                {isTypeSplunk(state.collectorType) && (
                    <SplunkCollectorsSection showFormFields={true} />
                )}
                {isTypeSyslog(state.collectorType) && (
                    <SyslogCollectorsSection
                        invalidTls={invalidTls}
                        showFormFields={true}
                    />
                )}
            </Section>
            <Section isDisplayed={tabEquals(1)}>
                <InfrastructureSection showFormFields={true} />
            </Section>
            <Section isDisplayed={tabEquals(2)}>
                <DangerZoneSection handleRemove={handleRemove} />
            </Section>
        </Pane>
    );
}

export default function ChronicleCyclopsForwarderForm(props) {
    return (
        <StateReducerProvider initialState={initialState} reducer={reducer}>
            <Form {...props} />
        </StateReducerProvider>
    );
}
