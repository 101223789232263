import { gql } from '@apollo/client';

export const CDP_DEPLOYMENT_FRAGMENT = gql`
    fragment CDPDeployment on CDPDeploymentLog {
        id
        name
        cluster {
            id
            isActive
        }
        createdAt
        createdBy
        status
        cdpForwarder {
            id
            organization {
                id
                name
            }
        }
    }
`;

export const CHRONICLE_DEPLOYMENT_FRAGMENT = gql`
    fragment ChronicleDeployment on DeploymentLog {
        id
        name
        cluster {
            id
            isActive
        }
        createdAt
        createdBy
        status
        forwarder {
            id
            organization {
                id
                name
            }
        }
    }
`;

export const CHRONICLE_CONFIGURATION_FRAGMENT = gql`
    fragment Configuration on ChronicleForwarderConfig {
        infrastructure {
            replicaCount
        }
        collectors {
            tagId
            collectorType
            common {
                dataType
                enabled
                dataHint
                batchEverySeconds
                batchEveryBytes
                comment
            }
            syslog {
                port
                tlsEnabled
                tlsRawCert
                tlsRawKey
            }
            splunk {
                username
                password
                url
                queryString
                ignoreCerts
            }
            pcap {
                interface
                bpf
            }
        }
    }
`;

export const CDP_CONFIGURATION_FRAGMENT = gql`
    fragment CDPConfiguration on CDPForwarderConfig {
        destinationRef
        infrastructure {
            bufferTime
            replicaCount
            workerCount
            batchFullThreshold
            flushThreadCount
        }
        collectors {
            tagId
            collectorType
            common {
                dataType
                enabled
                dataHint
                batchEverySeconds
                batchEveryBytes
                batchFullThreshold
                flushThreadCount
                comment
                destinationRef
                metadata {
                    labels
                    namespace
                }
            }
            syslog {
                port
                tlsEnabled
                tlsRawCert
                tlsRawKey
            }
        }
        metadata {
            labels
            namespace
        }
    }
`;
