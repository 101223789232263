import {
    Button,
    Heading,
    Label,
    MoreIcon,
    Pane,
    SelectMenu,
} from 'evergreen-ui';
import React from 'react';

import {
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';
import DestinationTagMenuInput from '../../../../components/DestinationTagMenuInput';
import { actions } from '../models/reducer';
import destinationRefs from '../../../../system/destinationRefs';

export function DestinationInput({
    addDestination,
    deselectDestination,
    removeDestination,
    destinations,
}) {
    return (
        <Pane width="100%">
            <Label position="relative" top="0.25rem">
                Destinations
            </Label>
            <DestinationTagMenuInput
                onDeselect={deselectDestination}
                onSelect={addDestination}
                onRemove={removeDestination}
                selected={destinations}
                showLabel={false}
            />
        </Pane>
    );
}

export function ForwarderDestinationInput() {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const addDestination = ({ value }) =>
        dispatch({
            type: actions.ADD_FORWARDER_DESTINATION_REF,
            payload: value,
        });
    const deselectDestination = ({ value }) =>
        dispatch({
            type: actions.REMOVE_FORWARDER_DESTINATION_REF,
            payload: value,
        });
    const removeDestination = (value) =>
        dispatch({
            type: actions.REMOVE_FORWARDER_DESTINATION_REF,
            payload: value,
        });

    return (
        <Pane marginBottom="1rem">
            <Heading size={700} id="global-destinations" marginBottom="0.75rem">
                Data Destinations
            </Heading>
            <DestinationInput
                addDestination={addDestination}
                deselectDestination={deselectDestination}
                removeDestination={removeDestination}
                destinations={state.destinationRef}
            />
        </Pane>
    );
}

export function CollectorDestinationInput({
    collector,
    options = destinationRefs,
}) {
    const dispatch = useDispatchContext();

    const destinations = collector?.common?.destinationRef;

    const addDestination = ({ value }) => {
        dispatch({
            type: actions.ADD_COLLECTOR_DESTINATION_REF,
            payload: { collector, value },
        });
    };
    const deselectDestination = ({ value }) =>
        dispatch({
            type: actions.REMOVE_COLLECTOR_DESTINATION_REF,
            payload: { collector, value },
        });

    return (
        <SelectMenu
            title="Data Destination"
            options={options.map((destination) => ({
                value: destination,
                label: destination,
            }))}
            isMultiSelect
            selected={destinations}
            onSelect={addDestination}
            onDeselect={deselectDestination}
            data-testid="destination-select-menu"
        >
            <Button appearance="minimal" data-testid="expand-menu-button">
                <MoreIcon size={16} />
            </Button>
        </SelectMenu>
    );
}
