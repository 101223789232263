/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom';

// import App from './App';
import App from './app';
import * as serviceWorker from './serviceWorker';
import './index.css';

const root = document.getElementById('root');

ReactDOM.render(<App />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();

if (module.hot) {
    module.hot.accept('./app', () => {
        const NextApp = require('./app').default;
        ReactDOM.render(<NextApp />, root);
    });
}
