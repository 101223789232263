export const azure = 'Azure';
export const chronicle = 'Chronicle';
export const cdpAlerting = 'CDPAlerting';
export const itd = 'ITD';
export const gcs = 'GCS';
export const filtering = 'Filtering';

const destinationRefs = [azure, chronicle, cdpAlerting, itd, gcs, filtering];

export default destinationRefs;
