import { cond, equals, mergeDeepRight, pipe, prop } from 'ramda';

import {
    CDP_CLOUD_DEFAULTS_EKS,
    CDP_CLOUD_DEFAULTS_GKE,
    EKS,
    GKE,
} from './defaults';

/**
 * creates object with properties of CDP_CLOUD_DEFAULTS_EKS merged with 2nd object
 */
export const mergeEksDefaults = mergeDeepRight(CDP_CLOUD_DEFAULTS_EKS);

/**
 * creates object with properties of CDP_CLOUD_DEFAULTS_GKE merged with 2nd object
 */
export const mergeGkeDefaults = mergeDeepRight(CDP_CLOUD_DEFAULTS_GKE);

/**
 * @returns Boolean
 */
export const isEksForwarder = pipe(prop('kubernetesService'), equals(EKS));

/**
 * @returns Boolean
 */
export const isGkeForwarder = pipe(prop('kubernetesService'), equals(GKE));

/**
 * encapsulates if/else logic and applies merge function by evaluating value of kubernetesService field
 */
export const injectDefaults = cond([
    [isEksForwarder, mergeEksDefaults],
    [isGkeForwarder, mergeGkeDefaults],
]);
