import { gql } from '@apollo/client';
import { path } from 'ramda';

import useQuerySelector from '../useQuerySelector';

const QUERY_TENANT_CLUSTERS = gql`
    query fetchTenantClusters($id: ID!) {
        organization(id: $id) {
            id
            clusters(cursor: { limit: 0 }, query: { isActive: true }) {
                id
                name
                updatedAt
                updatedBy
                description
                ucn
                organization {
                    id
                    name
                }
            }
        }
    }
`;

function useQueryTenantClusters(id) {
    const queryVariables = { variables: { id } };
    const response = useQuerySelector(
        QUERY_TENANT_CLUSTERS,
        queryVariables,
        path(['organization', 'clusters']),
    );

    if (!id) {
        return {
            data: null,
            loading: null,
            error: {
                message:
                    'MISSING ORGANIZATION ID! Please verify an organization is selected. Location useQueryTenantClusters.',
            },
        };
    }

    return response;
}

export default useQueryTenantClusters;
