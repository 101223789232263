import {
    Button,
    Heading,
    HelpIcon,
    IconButton,
    ListItem,
    Pane,
    SideSheet,
    Text,
    UnorderedList,
} from 'evergreen-ui';
import { equals } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import withRequireTenant from '../../../hocs/withRequireTenant';

const CYCLOPS = 'cyclops';
const CLOUD = 'cloud';
const CHRONICLE = 'chronicle';

const isCyclops = equals(CYCLOPS);
const isCloud = equals(CLOUD);
const isChronicle = equals(CHRONICLE);

const Container = styled.div`
    width: 100%;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const FlexButtonWrapper = styled(Pane)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space-between;
    min-height: 200px;
    margin-top: 2vh;

    @media (min-width: 1100px) {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: 1175px) {
        justify-content: center;
    }
`;

const FlexButton = styled(Button)`
    display: flex;
    justify-content: center;
    height: 48px;
    width: 275px;

    @media (min-width: 1100px) {
        margin: 0 1rem;
    }
`;

const HostSelection = ({ setHost }) => (
    <>
        <div>
            <Heading size={700}>
                Where is forwarder running Cyclops or the cloud?
            </Heading>
        </div>
        <FlexButtonWrapper>
            <FlexButton height={48} onClick={() => setHost(CYCLOPS)}>
                Cyclops
            </FlexButton>
            <FlexButton height={48} onClick={() => setHost(CLOUD)}>
                Cloud
            </FlexButton>
        </FlexButtonWrapper>
    </>
);

const CyclopsSelection = () => (
    <>
        <div>
            <Heading size={700}>
                What type of forwarder Chronicle or CDP?
            </Heading>
        </div>
        <FlexButtonWrapper>
            <Link to="/admin/forwarders/new/chronicle">
                <FlexButton height={48}>Chronicle</FlexButton>
            </Link>
            <Link to="/admin/forwarders/new/cdp">
                <FlexButton height={48}>CDP</FlexButton>
            </Link>
        </FlexButtonWrapper>
    </>
);

const CloudSelection = ({ setType }) => (
    <>
        <div>
            <Heading size={700}>
                What type of forwarder Chronicle or CDP?
            </Heading>
        </div>
        <FlexButtonWrapper>
            <FlexButton height={48} onClick={() => setType(CHRONICLE)}>
                Chronicle
            </FlexButton>
            <Link to="/admin/forwarders/cloud/cdp/new">
                <FlexButton height={48}>CDP</FlexButton>
            </Link>
        </FlexButtonWrapper>
    </>
);

const LogSelection = ({ setLogs }) => (
    <>
        <div>
            <Heading size={700}>What type of logs are being collected?</Heading>
        </div>
        <FlexButtonWrapper>
            <Link to="/admin/forwarders/cloud/chronicle/pcap/new">
                <FlexButton height={48}>PCAP</FlexButton>
            </Link>
            <Link to="/admin/forwarders/cloud/chronicle/splunk/new">
                <FlexButton height={48}>Splunk</FlexButton>
            </Link>
            <Link to="/admin/forwarders/cloud/chronicle/syslog/new">
                <FlexButton height={48}>Syslog</FlexButton>
            </Link>
        </FlexButtonWrapper>
    </>
);

const HelpSideSheet = () => {
    const [isShown, setIsShown] = React.useState(false);

    return (
        <>
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
            >
                <Pane padding="1rem">
                    <Heading size={700}>Forwarder Section Help</Heading>
                    <Heading size={600} marginTop="1rem">
                        Cyclops or Cloud
                    </Heading>
                    <UnorderedList>
                        <ListItem>
                            CYCLOPS is hosted in the client's environment.
                        </ListItem>
                        <ListItem>
                            Cloud forwarders are deployed to a CYDERES managed
                            cloud environment.
                        </ListItem>
                    </UnorderedList>
                    <Heading size={600} marginTop="1rem">
                        GKE or EKS for the cloud environment?
                    </Heading>
                    <Text>
                        We default to GKE. If you think you may need to deploy
                        in EKS please confirm with engineering before you create
                        the forwarder.
                    </Text>
                    <Heading size={600} marginTop="1rem">
                        Chronicle or CDP forwarder type?
                    </Heading>
                    <Text>
                        Generally, if the forwarder is collecting syslog data,
                        you will want to select CDP as the forwarder type. PCAP
                        and Splunk logs must be collected via the Chronicle
                        forwarder.
                    </Text>
                </Pane>
            </SideSheet>
            <IconButton
                icon={HelpIcon}
                onClick={() => setIsShown(true)}
                appearance="minimal"
                position="absolute"
                bottom="3vh"
                left="96%"
                data-testid="help-button"
            />
        </>
    );
};

function SelectForwarderPipeline() {
    const [host, setHost] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [logs] = React.useState(null);

    return (
        <Container>
            {!host && <HostSelection setHost={setHost} />}
            {isCyclops(host) && <CyclopsSelection />}
            {isCloud(host) && !type && <CloudSelection setType={setType} />}
            {isCloud(host) && isChronicle(type) && !logs && <LogSelection />}
            <HelpSideSheet />
        </Container>
    );
}

export default withRequireTenant(SelectForwarderPipeline);
