import { useMutation, useQuery } from '@apollo/client';
import { toaster } from 'evergreen-ui';
import { mergeDeepLeft } from 'ramda';
import { useHistory } from 'react-router-dom';

import {
    CREATE_NEW_ORGANIZATION,
    QUERY_ORGANIZATION_NAMES,
} from './CreateNewOrganization-graphql';
import { QUERY_ALL_ORGANIZATIONS } from '../../../graphql/queries';

const defaultValues = {
    userCount: 1,
    name: '',
    address: {
        street: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
    },
    pointOfContact: {
        name: '',
        email: '',
        phone: '',
    },
    organizationURLs: {
        chronicleURL: '',
        canaryURL: '',
    },
    kibanaSpaceIdentifier: '',
    authGroupName: '',
    lookerConfig: {
        model: '',
    },
};

const useCreateNewOrganization = ({
    name,
    contactName,
    contactEmail,
    contactPhone,
    chronicleURL,
    canaryURL,
    kibanaSpaceIdentifier,
    authGroupName,
    lookerModel,
}) => {
    const { loading, error, data } = useQuery(QUERY_ORGANIZATION_NAMES);
    let orgNameData = null;
    if (error) {
        toaster.danger('ERROR: Unable to load organizations.');
    } else if (!loading && !error) {
        orgNameData = data.organizations.map((org) => org.name.toLowerCase());
    }
    const history = useHistory();
    const values = {
        name,
        pointOfContact: {
            name: contactName,
            email: contactEmail,
            phone: contactPhone,
        },
        organizationURLs: {
            chronicleURL,
            canaryURL,
        },
        kibanaSpaceIdentifier,
        authGroupName,
        lookerConfig: {
            model: lookerModel,
        },
    };
    const props = mergeDeepLeft(values, defaultValues);
    const [mutate, response] = useMutation(CREATE_NEW_ORGANIZATION, {
        variables: { props },
        onError: () => {
            toaster.danger('ERROR: Unable to save.');
        },
        onCompleted: (data) => {
            toaster.success('Success!');
            history.push(`/admin/organizations/${data.createOrganization.id}`);
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: QUERY_ALL_ORGANIZATIONS,
                variables: {
                    fetchPolicy: 'network-only',
                },
            },
        ],
    });

    return [mutate, response, orgNameData];
};

export { useCreateNewOrganization };
