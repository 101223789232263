import React from 'react';
import { Alert } from 'evergreen-ui';

import useJwt from '../../hooks/useJwt';
import { isInternalUser } from '../../utils/auth';

export default function ErrorAlert({
    error = { message: 'There was an unexpected error.' },
}) {
    const [{ jwt }] = useJwt();

    return (
        <Alert
            intent="danger"
            title="Something went wrong"
            appearance="card"
            data-testid="error-alert"
        >
            {isInternalUser(jwt) ? (
                <div>{error.message}</div>
            ) : (
                <div>There was an unexpected error.</div>
            )}
        </Alert>
    );
}
