import { gql } from '@apollo/client';

export const QUERY_CLUSTER = gql`
    query cluster($id: ID!) {
        cluster(id: $id) {
            id
            ucn
            updatedAt
            updatedBy
            name
            organization {
                id
                name
            }
        }
    }
`;

export const MUTATE_CLUSTER_ORG = gql`
    mutation mutatClusterOrg($id: ID!, $props: UpdateClusterInput!) {
        cluster(id: $id) {
            update(props: $props) {
                id
            }
        }
    }
`;
