import ManageCDPCyclopsForwarder from '../admin-portal/forwarders/CDPCyclopsForwarder/Manage';
import ChronicleForwarderDashboard from '../admin-portal/forwarders/ChronicleCyclopsForwarder/Manage';
import DeployCDPCyclopsForwarder from '../admin-portal/forwarders/CDPCyclopsForwarder/Deploy';
import DeployChronicleForwarder from '../admin-portal/forwarders/DeployChronicleForwarder';
import ForwardersTable from '../admin-portal/forwarders/ForwardersTable';
import SelectForwarderPipeline from '../admin-portal/forwarders/SelectForwarderPipeline';
import CreateCDPCloudForwarder from '../admin-portal/forwarders/CDPCloudForwarder/Create';
import ManageCDPCloudForwarder from '../admin-portal/forwarders/CDPCloudForwarder/Manage';
import CloudForwardersTable from '../admin-portal/forwarders/CloudForwardersTable';
import CreateChronicleCloudPcapForwarder from '../admin-portal/forwarders/ChronicleCloudPcapForwarder/Create';
import ManageChronicleCloudPcapForwarder from '../admin-portal/forwarders/ChronicleCloudPcapForwarder/Manage';
import CreateChronicleCloudSplunkForwarder from '../admin-portal/forwarders/ChronicleCloudSplunkForwarder/Create';
import ManageChronicleCloudSplunkForwarder from '../admin-portal/forwarders/ChronicleCloudSplunkForwarder/Manage';
import CreateChronicleCloudSyslogForwarder from '../admin-portal/forwarders/ChronicleCloudSyslogForwarder/Create';
import ManageChronicleCloudSyslogForwarder from '../admin-portal/forwarders/ChronicleCloudSyslogForwarder/Manage';

import { baseAdminPath } from './home-routes';

const basePath = `${baseAdminPath}/forwarders`;

const routes = {};

routes.Forwarders = {
    path: `${basePath}`,
    exact: true,
    admin: false,
    secure: true,
    component: ForwardersTable,
};

routes.SelectForwarderPipeline = {
    path: `${basePath}/new`,
    exact: true,
    admin: true,
    secure: true,
    component: SelectForwarderPipeline,
};

routes.DeployCDPCyclopsForwarder = {
    path: `${basePath}/new/cdp`,
    exact: true,
    admin: true,
    secure: true,
    component: DeployCDPCyclopsForwarder,
};

routes.DeployChronicleForwarder = {
    path: `${basePath}/new/chronicle`,
    exact: true,
    admin: true,
    secure: true,
    component: DeployChronicleForwarder,
};

routes.ManageCDPCyclopsForwarder = {
    path: `${basePath}/cdp/:organizationId/:deploymentId/:forwarderId`,
    exact: true,
    admin: true,
    secure: true,
    component: ManageCDPCyclopsForwarder,
};

routes.ChronicleForwarderDashboard = {
    path: `${basePath}/chronicle/:organizationId/:deploymentId/:forwarderId`,
    exact: true,
    admin: true,
    secure: true,
    component: ChronicleForwarderDashboard,
};

routes.CreateCDPCloudForwarder = {
    path: `${basePath}/cloud/cdp/new`,
    exact: true,
    admin: true,
    secure: true,
    component: CreateCDPCloudForwarder,
};

routes.ManageCDPCloudForwarder = {
    path: `${basePath}/cloud/cdp/:id`,
    exact: true,
    admin: true,
    secure: true,
    component: ManageCDPCloudForwarder,
};

routes.CloudForwardersTable = {
    path: `${basePath}/cloud`,
    exact: true,
    admin: true,
    secure: true,
    component: CloudForwardersTable,
};

routes.CreateChronicleCloudPcapForwarder = {
    path: `${basePath}/cloud/chronicle/pcap/new`,
    exact: true,
    admin: true,
    secure: true,
    component: CreateChronicleCloudPcapForwarder,
};

routes.ManageChronicleCloudPcapForwarder = {
    path: `${basePath}/cloud/chronicle/pcap/:id`,
    exact: true,
    admin: true,
    secure: true,
    component: ManageChronicleCloudPcapForwarder,
};

routes.CreateChronicleCloudSplunkForwarder = {
    path: `${basePath}/cloud/chronicle/splunk/new`,
    exact: true,
    admin: true,
    secure: true,
    component: CreateChronicleCloudSplunkForwarder,
};

routes.ManageChronicleCloudSplunkForwarder = {
    path: `${basePath}/cloud/chronicle/splunk/:id`,
    exact: true,
    admin: true,
    secure: true,
    component: ManageChronicleCloudSplunkForwarder,
};

routes.CreateChronicleCloudSyslogForwarder = {
    path: `${basePath}/cloud/chronicle/syslog/new`,
    exact: true,
    admin: true,
    secure: true,
    component: CreateChronicleCloudSyslogForwarder,
};

routes.ManageChronicleCloudSyslogForwarder = {
    path: `${basePath}/cloud/chronicle/syslog/:id`,
    exact: true,
    admin: true,
    secure: true,
    component: ManageChronicleCloudSyslogForwarder,
};

routes.ManageChronicleCloudSyslogForwarder = {
    path: `${basePath}/cloud/chronicle/syslog/:id`,
    exact: true,
    admin: true,
    secure: true,
    component: ManageChronicleCloudSyslogForwarder,
};

export default routes;
