import React from 'react';
import { Badge } from 'evergreen-ui';
import { always, equals, ifElse } from 'ramda';
import { Link } from 'react-router-dom';

import {
    trimDeploymentUCN,
    trimCDPDeploymentPrefix,
} from '../../../utils/forwarders';

import { CHRONICLE, CDP } from '../../../utils/constants';
import withRowDataAsProps from '../../../hocs/withRowDataAsProps';

const buildUrl = (deployment) => {
    const base = `/admin/forwarders`;
    const dynamic = `${deployment.forwarderType}/${deployment?.organization?.id}/${deployment?.id}/${deployment?.forwarderId}`;
    const url = `${base}/${dynamic}`;
    return url;
};

const NameCell = (deployment) => {
    const url = buildUrl(deployment);
    const { name, forwarderType } = deployment;

    let cellText = name;
    if (forwarderType === CHRONICLE) {
        cellText = name && trimDeploymentUCN(name);
    } else if (forwarderType === CDP) {
        cellText = name && trimCDPDeploymentPrefix(name);
    }

    return <Link to={url}>{cellText}</Link>;
};

const ForwarderTypeCell = ({ forwarderType }) => {
    const badgeColor = ifElse(equals('cdp'), always('blue'), always('orange'));
    const color = badgeColor(forwarderType);

    return <Badge color={color}>{forwarderType}</Badge>;
};

const columns = [
    {
        title: 'Name',
        path: ['name'],
        render: withRowDataAsProps(NameCell),
    },
    {
        title: 'Type',
        path: ['forwarderType'],
        render: withRowDataAsProps(ForwarderTypeCell),
    },
    {
        title: 'Organization',
        path: ['organization', 'name'],
    },
    {
        title: 'Created',
        path: ['createdAt'],
    },
    {
        title: 'Created By',
        path: ['createdBy'],
    },
];

export default columns;
