import React from 'react';
import PropTypes from 'prop-types';
import { find, propEq } from 'ramda';
import styled, { css } from 'styled-components';
import { Tab, Tablist } from 'evergreen-ui';

const Body = styled.div`
    padding: 1vh;
`;
const Divider = styled.span`
    color: #00000042;
    height: 25px;
    width: 1px;
    border-right: 1.5px solid #00000042;
`;
const StyledTabList = styled(Tablist)`
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #00000042;
    min-height: 50px;
`;
const StyledTab = styled(Tab)`
    border-radius: 0;
    font-size: 1rem;
    height: 50px;
    font-weight: bold;
    color: #0000009e

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.isSelected &&
        css`
            color: #f08f19;
            border-bottom: 2px solid black;
        `}
`;

function TabbedSections({ sections, selected, setSelected }) {
    const selectedTab = find(propEq('value', selected), sections);
    const children = selectedTab.children;

    return (
        <>
            <StyledTabList data-testid="controlled-tablist">
                {sections.map((section, index) => {
                    return (
                        <React.Fragment key={`${section.value}-fragment`}>
                            <StyledTab
                                key={`${section.value}-tab`}
                                value={section.value}
                                onSelect={() => setSelected(section.value)}
                                isSelected={selected === section.value}
                            >
                                {section.label}
                            </StyledTab>
                            <Divider key={`${section.value}-divider`} />
                        </React.Fragment>
                    );
                })}
            </StyledTabList>
            <Body>{children}</Body>
        </>
    );
}

export default TabbedSections;

TabbedSections.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            children: PropTypes.node,
        }),
    ).isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    setSelected: PropTypes.func.isRequired,
};
