import { gql } from '@apollo/client';
import { prop } from 'ramda';

import useQuerySelector from '../useQuerySelector';

export const GET_AUTH_CONTEXT = gql`
    query getAuthContext {
        me {
            role
            user {
                groups
                username
            }
            organization {
                id
                name
            }
        }
    }
`;

export default function useAuthContext() {
    return useQuerySelector(GET_AUTH_CONTEXT, {}, prop('me'));
}
