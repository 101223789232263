import { gql } from '@apollo/client';

export const QUERY_TENANT_CLOUD_FORWARDERS = gql`
    query tenantCloudForwarders($id: ID!) {
        organization(id: $id) {
            id
            cdpCloudForwarders {
                id
                name
                createdAt
                createdBy
                forwarderType @client
                organization {
                    id
                    name
                }
            }
            chronicleCloudPcapForwarders {
                id
                name
                createdAt
                createdBy
                forwarderType @client
                collectorType @client
                organization {
                    id
                    name
                }
            }
            chronicleCloudSplunkForwarders {
                id
                name
                createdAt
                createdBy
                forwarderType @client
                collectorType @client
                organization {
                    id
                    name
                }
            }
            chronicleCloudSyslogForwarders {
                id
                name
                createdAt
                createdBy
                forwarderType @client
                collectorType @client
                organization {
                    id
                    name
                }
            }
        }
    }
`;
