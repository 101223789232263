import React from 'react';
import { Alert, Heading, Pane } from 'evergreen-ui';

import LoadingSpinner from '../../components/LoadingSpinner';
import useJwt from '../../hooks/useJwt';
import useTenant from '../../hooks/useTenant';
import { isInternalUser } from '../../utils/auth';

const withRequireTenant = (Component) => (props) => {
    const [tenant] = useTenant();
    const [{ jwt }] = useJwt();

    if (!tenant && !jwt) {
        return <LoadingSpinner />;
    }

    if (jwt && !tenant) {
        if (!isInternalUser(jwt)) {
            return (
                <Alert intent="danger" appearance="card" title="UNAUTHORIZED">
                    You do not have the correct permissions to view this content
                </Alert>
            );
        }

        return (
            <Pane width="100%" textAlign="center" marginTop="15%">
                <Heading size={700}>please select organization</Heading>
            </Pane>
        );
    }

    return <Component {...props} />;
};

export default withRequireTenant;
