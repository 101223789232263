import { assoc, flatten, groupBy, isEmpty, map, mergeLeft, pipe } from 'ramda';

import { isEmptyOrNil } from '../../../utils/common';

const setDefaultDestinations = (defaultValue) => (deployment) => {
    if (deployment?.destinationRef?.length) return deployment;

    return assoc('destinationRef', defaultValue, deployment);
};

const setDefaultPorts = (collectors) => {
    const { syslog, common } = collectors;
    const { port } = isEmptyOrNil(syslog) ? { port: 'see config' } : syslog;
    return { port, ...common };
};

const normalizeCDPDeploymentRecord = (deploymentRecord) => {
    const {
        status,
        cdpForwarderConfiguration: configuration,
    } = deploymentRecord;
    const destinationRef = configuration?.destinationRef || [];
    const collectors = configuration?.collectors || [];
    const defaultPortCollectors = map(setDefaultPorts, collectors);
    const setDestinationRefs = map(setDefaultDestinations(destinationRef));
    const addStatus = map(assoc('status', status));
    return pipe(setDestinationRefs, addStatus)(defaultPortCollectors);
};

const normalizeChronicleDeploymentRecord = (deploymentRecord) => {
    const {
        status,
        chronicleForwarderConfiguration: configuration,
    } = deploymentRecord;
    const collectors = configuration?.collectors || [];
    const defaultPortCollectors = map(setDefaultPorts, collectors);
    const destinationRef = ['chronicle'];
    const setDestinationRefs = map(setDefaultDestinations(destinationRef));
    const addStatus = map(assoc('status', status));
    return pipe(setDestinationRefs, addStatus)(defaultPortCollectors);
};

const addForwarderMetadata = (id, name, collectorType) => ({
    id,
    forwarderName: name,
    chronicle: true,
    collectorType,
});

const normalizeCDPForwarder = (cdpForwarder) => {
    const { id, name, cdpDeploymentStatus: deploymentRecords } = cdpForwarder;
    const normalizedDeploymentRecords = map(
        normalizeCDPDeploymentRecord,
        deploymentRecords,
    );
    const deployments = flatten(normalizedDeploymentRecords);
    const withMetadata = addForwarderMetadata(id, name, 'CDP Forwarder');
    return map(mergeLeft(withMetadata), deployments);
};

const normalizeChronicleForwarder = (chronicleForwarder) => {
    const {
        id,
        name,
        deploymentStatus: deploymentRecords,
    } = chronicleForwarder;
    const normalizedDeploymentRecords = map(
        normalizeChronicleDeploymentRecord,
        deploymentRecords,
    );
    const deployments = flatten(normalizedDeploymentRecords);
    const withMetadata = addForwarderMetadata(id, name, 'Chronicle Forwarder');
    return map(mergeLeft(withMetadata), deployments);
};

const normalizeForwarders = (forwarders) => {
    const { cdpForwarders, forwarders: chronicleForwarders } = forwarders;
    const normalizeCDPForwarders = flatten(
        map(normalizeCDPForwarder, cdpForwarders),
    );
    const normalizedChronicleForwarders = flatten(
        map(normalizeChronicleForwarder, chronicleForwarders),
    );
    return [...normalizeCDPForwarders, ...normalizedChronicleForwarders];
};

const groupByDatatype = groupBy((collector) => {
    const { dataType } = collector;
    return dataType === undefined ? 'UNKNOWN' : dataType;
});

export const resolveCyclopsCollector = (queryData) => {
    const { forwarders, cdpForwarders } = queryData;
    const normalizedForwardersArray = normalizeForwarders({
        forwarders,
        cdpForwarders,
    });
    const dataTypes = groupByDatatype(normalizedForwardersArray);
    const dataTypeArray = Object.entries(dataTypes);
    const normalizedDataTypesArray = dataTypeArray.map(([key, value]) => {
        const dataType = {};
        dataType['name'] = key;
        dataType['collectors'] = value;

        return dataType;
    });
    return normalizedDataTypesArray;
};

export const resolveCyclopsCollectorResponse = (data) => {
    if (isEmpty(data)) {
        return [];
    }
    const response = resolveCyclopsCollector(data);
    return response;
};
