import React from 'react';
import { Combobox, Label, Pane } from 'evergreen-ui';

import { actions } from '../models/reducer';
import {
    useStateContext,
    useDispatchContext,
} from '../../../../context/createStateReducerContext';

const InfrastructureSection = ({ showFormFields }) => {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const handleChangeReplicaCount = (value) =>
        dispatch({ type: actions.UPDATE_REPLICA_COUNT, payload: value });

    return (
        <Pane marginBottom="3vh">
            <Label id="replica-count">Replica Count</Label>
            <Combobox
                items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                value={state.infrastructure.replicaCount}
                selectedItem={state.infrastructure.replicaCount}
                initialSelectedItem={state.infrastructure.replicaCount}
                onChange={handleChangeReplicaCount}
                disabled={!showFormFields}
                aria-labelledby="replica-count"
                marginRight="2rem"
            />
        </Pane>
    );
};

export default InfrastructureSection;
