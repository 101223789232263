import { evolve, identity } from 'ramda';

import { useQuery } from '@apollo/client';

/**
 * Calls useQuery hook and applies the provided selector to the data
 * @param query
 * @param options
 * @param selector
 * @returns {Evolve<QueryResult<any, OperationVariables>, {data: <T>(a: T) => T}>}
 */
export default function useQuerySelector(query, options, selector = identity) {
    const response = useQuery(query, options);
    return evolve({ data: selector }, response);
}
