import { InMemoryCache, makeVar } from '@apollo/client';

import { CDP, CHRONICLE, PCAP, SPLUNK, SYSLOG } from '../utils/constants';

const networkHealthCheckReactiveVar = makeVar({
    status: 0,
    error: null,
});
const updateNetworkHealthCheckCache = ({ status, error }) =>
    networkHealthCheckReactiveVar({ status, error });

// const tenantReactiveVar = makeVar();
// const updateTenantCache = ({ id, name }) => tenantReactiveVar({ id, name });

const cache = new InMemoryCache({
    typePolicies: {
        Forwarder: {
            fields: {
                forwarderType: {
                    read() {
                        return CHRONICLE;
                    },
                },
            },
        },
        CDPForwarder: {
            fields: {
                forwarderType: {
                    read() {
                        return CDP;
                    },
                },
            },
        },
        CDPCloudForwarder: {
            fields: {
                forwarderType: {
                    read() {
                        return CDP;
                    },
                },
            },
        },
        ChronicleCloudPcapForwarder: {
            fields: {
                forwarderType: {
                    read() {
                        return CHRONICLE;
                    },
                },
                collectorType: {
                    read() {
                        return PCAP;
                    },
                },
            },
        },
        ChronicleCloudSplunkForwarder: {
            fields: {
                forwarderType: {
                    read() {
                        return CHRONICLE;
                    },
                },
                collectorType: {
                    read() {
                        return SPLUNK;
                    },
                },
            },
        },
        ChronicleCloudSyslogForwarder: {
            fields: {
                forwarderType: {
                    read() {
                        return CHRONICLE;
                    },
                },
                collectorType: {
                    read() {
                        return SYSLOG;
                    },
                },
            },
        },
        CDPForwarderIdentity: {
            fields: {
                forwarderType: {
                    read() {
                        return CDP;
                    },
                },
            },
        },
        ChronicleForwarderIdentity: {
            fields: {
                forwarderType: {
                    read() {
                        return CHRONICLE;
                    },
                },
            },
        },
        Query: {
            fields: {
                networkHealthCheck: {
                    read() {
                        return networkHealthCheckReactiveVar();
                    },
                },
            },
        },
    },
});

export { cache, networkHealthCheckReactiveVar, updateNetworkHealthCheckCache };
