import { gql } from '@apollo/client';

export const DELETE_CDP_FORWARDER = gql`
    mutation deleteCDPFw($id: ID!) {
        cdpForwarder(id: $id) {
            remove
        }
    }
`;

export const QUERY_CDP_DEPLOYMENT = gql`
    query queryCdpDeployment($id: ID!) {
        cdpDeploymentLog(id: $id) {
            id
            deploymentAppStatus {
                state
                errorMessage
            }
            cluster {
                name
            }
        }
    }
`;

export const QUERY_CDP_FORWARDER = gql`
    query query_cdp_forwarder($id: ID!) {
        cdpForwarder(id: $id) {
            id
            name
            configuration {
                destinationRef
                infrastructure {
                    bufferTime
                    replicaCount
                    workerCount
                    batchFullThreshold
                    flushThreadCount
                }
                collectors {
                    tagId
                    collectorType
                    common {
                        dataType
                        enabled
                        batchEverySeconds
                        batchEveryBytes
                        batchFullThreshold
                        flushThreadCount
                        comment
                        destinationRef
                        metadata {
                            namespace
                            labels {
                                key
                                value
                            }
                        }
                    }
                    syslog {
                        port
                        tlsEnabled
                        tlsRawCert
                        tlsRawKey
                    }
                }

                metadata {
                    namespace
                    labels {
                        key
                        value
                    }
                }
            }
        }
    }
`;

export const UPDATE_CDP_FORWARDER_CONFIG = gql`
    mutation updateCdpForwarderConfig(
        $id: ID!
        $props: UpdateCDPForwarderInput!
    ) {
        cdpForwarder(id: $id) {
            update(props: $props) {
                id
                name
                createdAt
                updatedAt
                updatedBy
                configuration {
                    collectors {
                        tagId
                        collectorType
                        common {
                            dataType
                            enabled
                            dataHint
                            batchEverySeconds
                            batchEachBytes
                            comment
                            destinationRef
                            batchFullThreshold
                            flushThreadCount
                            metadata {
                                namespace
                                labels {
                                    key
                                    value
                                }
                            }
                        }
                        syslog {
                            port
                            tlsEnabled
                            tlsRawCert
                            tlsRawKey
                        }
                    }

                    destinationRef

                    infrastructure {
                        replicaCount
                        workerCount
                        bufferTime
                        batchFullThreshold
                        flushThreadCount
                    }

                    metadata {
                        namespace
                        labels {
                            key
                            value
                        }
                    }
                }
                cdpDeploymentStatus {
                    id
                    name
                }
            }
        }
    }
`;

export const UPGRADE_CDP_DEPLOYMENT = gql`
    mutation upgradeCdpDeployment($deploymentLogId: ID!) {
        cdpDeploymentLog(id: $deploymentLogId) {
            upgradeCDPApp {
                id
            }
        }
    }
`;

export const UNDEPLOY_CDP_APP_MUTATION = gql`
    mutation undeployCDPApp($id: ID!) {
        cdpDeploymentLog(id: $id) {
            undeployCDPApp {
                id
            }
        }
    }
`;
