import { default as clusterRoutes } from './clusters-routes';
import { default as contentManagementRoutes } from './content-management-routes';
import { default as datasourcesRoutes } from './datasources-routes';
import { default as forwarderRoutes } from './forwarders-routes';
import { default as helpRoutes } from './help-routes';
import { default as homeRoutes } from './home-routes';
import { default as pipelineSecretsRoutes } from './pipeline-secrets-routes';
import { default as redirectRoutes } from './redirect-routes';
import { buildRedirectRoutesArray, buildRoutesArray } from './utils';

const appRoutes = {
    ...clusterRoutes,
    ...contentManagementRoutes,
    ...datasourcesRoutes,
    ...forwarderRoutes,
    ...helpRoutes,
    ...homeRoutes,
    ...pipelineSecretsRoutes,
};

const routes = buildRoutesArray(appRoutes);
const redirects = buildRedirectRoutesArray(redirectRoutes);

export { redirects };
export default routes;
