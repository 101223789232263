import React from 'react';
import { Pane, TextInputField } from 'evergreen-ui';
import styled from 'styled-components';

import { withEventTargetAsValue } from '../../utils/common';

const FormContainer = styled(Pane)`
    padding: 3vh;
    border: 1px solid #e4e7eb;
    box-shadow: rgb(227, 233, 243) 0px 2px 4px;
`;

function OrganizationForm({
    name,
    setName,
    contactName,
    setContactName,
    contactEmail,
    setContactEmail,
    contactPhone,
    setContactPhone,
    chronicleURL,
    setChronicleURL,
    canaryURL,
    setCanaryURL,
    smartsheetURL,
    setSmartsheetURL,
    kibanaSpaceIdentifier,
    setKibanaSpaceIdentifier,
    authGroupName,
    setAuthGroupName,
    lookerModel,
    setLookerModel,
}) {
    return (
        <FormContainer data-testid="organization-form">
            <TextInputField
                label="Organization Name"
                value={name}
                onChange={withEventTargetAsValue(setName)}
            />
            <TextInputField
                label="Contact"
                value={contactName}
                onChange={withEventTargetAsValue(setContactName)}
            />
            <TextInputField
                label="Contact Email"
                value={contactEmail}
                onChange={withEventTargetAsValue(setContactEmail)}
            />
            <TextInputField
                label="Contact Phone"
                value={contactPhone}
                onChange={withEventTargetAsValue(setContactPhone)}
            />
            <TextInputField
                label="Chronicle URL"
                value={chronicleURL}
                onChange={withEventTargetAsValue(setChronicleURL)}
            />
            <TextInputField
                label="Canary URL"
                value={canaryURL}
                onChange={withEventTargetAsValue(setCanaryURL)}
            />
            <TextInputField
                label="Looker Model"
                value={lookerModel}
                onChange={withEventTargetAsValue(setLookerModel)}
            />
            <TextInputField
                label="Client Onboarding Dashboard Smartsheet URL"
                value={smartsheetURL}
                onChange={withEventTargetAsValue(setSmartsheetURL)}
            />
            <TextInputField
                label="Client Kibana Space Identifier"
                value={kibanaSpaceIdentifier}
                onChange={withEventTargetAsValue(setKibanaSpaceIdentifier)}
            />
            <TextInputField
                label="Okta Group Name"
                value={authGroupName}
                onChange={withEventTargetAsValue(setAuthGroupName)}
            />
        </FormContainer>
    );
}

export default OrganizationForm;
