/* default env */
export const ENVIRONMENT = 'production';

/* resource defaults */
export const BATCH_FULL_THRESHOLD = 0.8;
export const BUFFER_TIME = '20s';
export const FLUSH_THREAD_COUNT = 8;

/* infra defaults */
export const SERVICE_TYPE = 'LoadBalancer';

/* default config values */
export const CLOUD_SYSLOG_DEFAULTS = {
    configuration: {
        infrastructure: {
            serviceType: SERVICE_TYPE,
        },
    },
};
