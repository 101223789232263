import { gql } from '@apollo/client';
import { prop } from 'ramda';

import useQuerySelector from '../useQuerySelector';

const QUERY_ORGANIZATION_BY_ID = gql`
    query queryOrganizationById($id: ID!) {
        organization(id: $id) {
            id
            name
            kibanaSpaceIdentifier
            lookerConfig {
                model
            }
        }
    }
`;

function useQueryOrganization(id) {
    const queryVariables = {
        variables: {
            id,
        },
    };
    const response = useQuerySelector(
        QUERY_ORGANIZATION_BY_ID,
        queryVariables,
        prop('organization'),
    );

    if (!id) {
        return {
            data: null,
            loading: null,
            error: {
                message: 'useQueryOrganization must be called with a valid ID',
            },
        };
    }

    return response;
}

export default useQueryOrganization;
