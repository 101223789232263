import React, { useState } from 'react';
import { Alert, toaster } from 'evergreen-ui';
import { pathOr } from 'ramda';
import { Redirect, useParams } from 'react-router-dom';

import HandleQueryResponse from '../../../../components/HandleQueryResponse';
import { DEPLOYMENT_STATE_ACTIVE } from '../../../../utils/constants';
import withVerifyTenantIdMatchesUrlParams from '../../../../hocs/withVerifyTenantIdMatchesUrlParams';
import ForwarderDashboardHeader from '../../../../components/ForwarderDashboardHeader';
import PageContainer from '../../../../components/PageContainer';

import CloneModal from './CloneModal';
import { useChronicleForwarder, useHealthCheck } from '../models/hooks';
import ChronicleCyclopsForwarderForm from '../Form';

function ManageChronicleCyclopsForwarder() {
    const { deploymentId, forwarderId } = useParams();

    const { meta, models, operations } = useChronicleForwarder(
        forwarderId,
        deploymentId,
    );
    const healthResponse = useHealthCheck(deploymentId);

    const deploymentState = pathOr(
        DEPLOYMENT_STATE_ACTIVE,
        ['data', 'deploymentLog', 'deploymentAppStatus', 'state'],
        healthResponse,
    );
    const deploymentErrorMessage = pathOr(
        '',
        ['data', 'deploymentLog', 'deploymentAppStatus', 'errorMessage'],
        healthResponse,
    );
    const clusterName = pathOr(
        'Unknown',
        ['data', 'deploymentLog', 'cluster', 'name'],
        healthResponse,
    );

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const showModal = () => setModalIsOpen(true);
    const hideModal = () => setModalIsOpen(false);

    React.useEffect(() => {
        if (meta.success.update) {
            toaster.success('Forwarder successfully updated!');
        }
        if (meta.success.remove) {
            toaster.success('Forwarder successfully removed!');
        }
        if (meta.errors.length > 0) {
            toaster.danger(`Error: ${meta.errors[0].message}`);
        }
    }, [meta.errors, meta.success.update, meta.success.remove]);

    if (meta.success.remove) {
        return <Redirect to="/admin/forwarders" />;
    }

    return (
        <PageContainer>
            <HandleQueryResponse response={meta}>
                {deploymentErrorMessage && (
                    <Alert
                        appearance="card"
                        intent="danger"
                        title="RANCHER ERROR: Please check Rancher for more information."
                        marginBottom="2vh"
                        display="block"
                    >
                        {deploymentErrorMessage}
                    </Alert>
                )}
                <ForwarderDashboardHeader
                    deploymentState={deploymentState}
                    forwarder={models.forwarder}
                    forwarderType="Chronicle"
                    clusterName={clusterName}
                />
                <ChronicleCyclopsForwarderForm
                    isLoading={meta.loading}
                    handleSubmit={operations.update}
                    handleRemove={operations.remove}
                    handleClone={showModal}
                    values={models.forwarder}
                />
                <CloneModal
                    isShown={modalIsOpen}
                    onCloseComplete={hideModal}
                    chronicleForwarder={models.forwarder}
                />
            </HandleQueryResponse>
        </PageContainer>
    );
}

const fallbackUrl = '/admin/forwarders';

export { ManageChronicleCyclopsForwarder };
export default withVerifyTenantIdMatchesUrlParams(
    ManageChronicleCyclopsForwarder,
    fallbackUrl,
);
