/* case alert sources */
export const ABUSEBOX_ALERT = 'Abuse Box';
export const CHRONICLE_ALERT = 'Chronicle';
export const CUSTOMER_ALERT = 'Customer';
export const CUSTOMER_CONSOLE_ALERT = 'Customer Console';
export const THREAT_HUNTING_ALERT = 'Hunting';
export const RATE_ALERT = 'RATE';

export const CASE_ALERT_SOURCES = [
    ABUSEBOX_ALERT,
    CHRONICLE_ALERT,
    CUSTOMER_ALERT,
    CUSTOMER_CONSOLE_ALERT,
    THREAT_HUNTING_ALERT,
    RATE_ALERT,
];

/* case categories */
export const ANONYMIZED_TRAFFIG = 'Anonymized Traffic';
export const BRUTE_FORCE = 'Brute Force';
export const CLEARTEXT_CREDENTIALS = 'Cleartext Credentials';
export const CLEARTEXT_FILE_TRANSFER = 'Cleartext File Transfer';
export const COMMAND_AND_CONTROL = 'Command and Control (C2)';
export const CYDERES_ACCESS_REQUEST = 'CYDERES Access Request';
export const DOS = 'Denial of Service (DoS)';
export const DISTRUBUTED_DOS = 'Distributed Denial of Service (DDoS)';
export const EXFILTRATION = 'Exfiltration';
export const EXPLOITATION = 'Exploitation';
export const HIGH_PROFILE_C2_ATTEMPTS =
    'High Profile Command and Control (C2) Attempts';
export const HIGH_SEVERITY_PHISHING = 'High Severity Phishing';
export const INACTIVE_SERVICE = 'Inactive Service Detected';
export const INACTIVE_SERVICE_POSSIBLY_MALICIOUS =
    'Inactive Service Detected with Possible Malicious Activity';
export const INFORMATIONAL = 'Informational';
export const LATERAL_MOVEMENT = 'Lateral Movement';
export const MALWARE = 'Malware';
export const MALWARE_SPREADING = 'Malware Spreading';
export const PCI_DSS = 'PCI DSS';
export const PERSISTENT_SCAN = 'Persistent Scan';
export const PHISHING = 'Phishing';
export const PUP = 'Potentially Unwanted Program (PUP)';
export const PRIVILEGE_ESCALATION = 'Privilege Escalation';
export const RANSOMWARE = 'Ransomware';
export const SILENT_LOG_SOURCE = 'Silent Log Source';
export const SPEAR_PHISHING = 'Spear Phishing';
export const SUSPICIOUS_REMOTE_ACCESS = 'Suspicious Remote Access';
export const TAKE_DOWN_REQ = 'Take Down Request';
export const UNAUTHED_MESSAGING = 'Unauthorized Messaging';

export const CASE_CATEGORIES = [
    ANONYMIZED_TRAFFIG,
    BRUTE_FORCE,
    CLEARTEXT_CREDENTIALS,
    CLEARTEXT_FILE_TRANSFER,
    COMMAND_AND_CONTROL,
    CYDERES_ACCESS_REQUEST,
    DOS,
    DISTRUBUTED_DOS,
    EXFILTRATION,
    EXPLOITATION,
    HIGH_PROFILE_C2_ATTEMPTS,
    HIGH_SEVERITY_PHISHING,
    INACTIVE_SERVICE,
    INACTIVE_SERVICE_POSSIBLY_MALICIOUS,
    INFORMATIONAL,
    LATERAL_MOVEMENT,
    MALWARE,
    MALWARE_SPREADING,
    PCI_DSS,
    PERSISTENT_SCAN,
    PHISHING,
    PUP,
    PRIVILEGE_ESCALATION,
    RANSOMWARE,
    SILENT_LOG_SOURCE,
    SPEAR_PHISHING,
    SUSPICIOUS_REMOTE_ACCESS,
    TAKE_DOWN_REQ,
    UNAUTHED_MESSAGING,
];

/* case severity */
export const S1 = 'S1 (High)';
export const S2 = 'S2 (Medium)';
export const S3 = 'S3 (Low)';
export const S4 = 'S4 (Informational)';

export const CASE_SEVERITIES = [S1, S2, S3, S4];

/* case progress status values */
export const IN_PROGRESS = 'In Progress';
export const RESOLVED = 'Resolved';
export const WAITING_ON_CUSTOMER = 'Waiting on Customer';
export const WAITING_ON_CYDERES = 'Waiting on CYDERES';
export const WAITING_ON_INTERNAL_TEAM = 'Waiting on Internal CYDERES Team';
export const WAITING_ON_THIRD_PARTY = 'Waiting on Third Party';
export const UNREVIEWED = 'Unreviewed';

export const CASE_STATUS = [
    IN_PROGRESS,
    RESOLVED,
    WAITING_ON_CUSTOMER,
    WAITING_ON_CYDERES,
    WAITING_ON_INTERNAL_TEAM,
    WAITING_ON_THIRD_PARTY,
    UNREVIEWED,
];

/* deployment log app status state */
export const DEPLOYMENT_STATE_ACTIVE = 'ACTIVE';
export const DEPLOYMENT_STATE_DEPLOYING = 'DEPLOYING';
export const DEPLOYMENT_STATE_ERROR = 'ERROR';
export const DEPLOYMENT_STATE_INSTALLING = 'INSTALLING';

/* forwarder types */
export const CDP = 'cdp';
export const CHRONICLE = 'chronicle';

/* forwarder collector types */
export const PCAP = 'pcap';
export const SPLUNK = 'splunk';
export const SYSLOG = 'syslog';
