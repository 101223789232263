import {
    allPass,
    always,
    any,
    complement,
    compose,
    curry,
    either,
    equals,
    filter,
    identity,
    ifElse,
    includes,
    is,
    isEmpty,
    isNil,
    last,
    map,
    path,
    pathEq,
    pipe,
    prop,
    propEq,
    sortBy,
    toLower,
    values,
} from 'ramda';

export const isEmptyOrNil = either(isEmpty, isNil);
export const isNotEmptyOrNil = complement(isEmptyOrNil);
export const isLoading = propEq('loading', true);
export const anyIsLoading = any(isLoading);
export const hasError = pipe(prop('error'), isNotEmptyOrNil);
export const anyHasError = any(hasError);
export const returnZero = always(0);
export const isNumber = is(Number);
export const isNaN = equals(NaN);
export const isValidNum = allPass([isNumber, complement(isNaN)]);
export const checkIsNumElseReturnZero = ifElse(
    isValidNum,
    identity,
    returnZero,
);
export const checkFloatElseReturnString = ifElse(
    pipe(last, equals('.')),
    identity,
    parseFloat,
);
export const withEventTargetAsValue = (handler) =>
    compose(handler, path(['target', 'value']));
export const withEventTargetAsNumber = (handler) =>
    compose(
        handler,
        checkIsNumElseReturnZero,
        Number,
        path(['target', 'value']),
    );
export const withEventTargetAsFloat = (handler) =>
    compose(handler, checkFloatElseReturnString, path(['target', 'value']));
export const filterValueFromArray = (value, array, path) => {
    let filterArray;
    if (path) {
        const pathEqalsValue = pathEq(path, value);
        const pathDoesNotEqualValue = complement(pathEqalsValue);
        filterArray = filter(pathDoesNotEqualValue);
    } else {
        const equalsValue = equals(value);
        const doesNotEqualValue = complement(equalsValue);
        filterArray = filter(doesNotEqualValue);
    }
    return filterArray(array);
};

// simple functional wrapping of Array.prototype.some
export const some = (callback, array) => array.some(callback);

// iterate over an object's values and check if some values include string
export const someObjectsInclude = curry((string, object) => {
    const lowercaseString = toLower(string);
    const objectValues = values(object);
    const stringValues = filter(is(String), objectValues);
    const lowercaseValues = map(toLower, stringValues);
    return some(includes(lowercaseString), lowercaseValues);
});

/**
 * sort array of objects by prop
 * @param {String} key
 * @param {Array<{ key: String }>} array
 */
export const sortByProp = (key, array) =>
    sortBy(pipe(prop(key), toLower), array);
