import { gql } from '@apollo/client';

const CHRONICLE_IDENTITY = gql`
    fragment ChronicleIdentity on ChronicleForwarderIdentity {
        id
        name
        createdAt
        createdBy
        updatedAt
        updatedBy
        organizationId
        organization {
            id
            name
        }
        forwarderType @client
    }
`;
const CDP_IDENTITY = gql`
    fragment CDPIdentity on CDPForwarderIdentity {
        id
        name
        createdAt
        createdBy
        updatedAt
        updatedBy
        organizationId
        organization {
            id
            name
        }
        forwarderType @client
    }
`;

export const QUERY_FORWARDER_IDENTITIES = gql`
    query allForwarderIdentities {
        chronicleForwarderIdentities {
            ...ChronicleIdentity
        }

        cdpForwarderIdentities {
            ...CDPIdentity
        }
    }
    ${CHRONICLE_IDENTITY}
    ${CDP_IDENTITY}
`;

export const QUERY_TENANT_FORWARDER_IDENTITIES = gql`
    query tenantForwarderIdentities($id: ID!) {
        organization(id: $id) {
            id

            chronicleForwarderIdentities {
                ...ChronicleIdentity
            }

            cdpForwarderIdentities {
                ...CDPIdentity
            }
        }
    }
    ${CHRONICLE_IDENTITY}
    ${CDP_IDENTITY}
`;
