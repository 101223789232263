import React from 'react';
import styled from 'styled-components';
import { TextareaField } from 'evergreen-ui';

import { actions } from '../models/reducer';
import {
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';

const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props) => props.justify || 'space-between'};
    align-items: ${(props) => props.align};
    margin: ${(props) => props.margin || 0};
    width: ${(props) => props.width || '100%'};
`;
const FlexSection = styled.div`
    width: 47%;
`;

function TLSSection({ invalidTls }) {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const handleUpdateTlsCert = (event) =>
        dispatch({
            type: actions.UPDATE_TLS_CERT,
            payload: event.target.value,
        });

    const handleUpdateTlsKey = (event) =>
        dispatch({ type: actions.UPDATE_TLS_KEY, payload: event.target.value });

    return (
        <FlexBox margin="2vh 0">
            <FlexSection>
                <TextareaField
                    isInvalid={invalidTls}
                    style={{ height: '45vh' }}
                    label="TLS Certificate"
                    value={state.tlsCert || ''}
                    onChange={handleUpdateTlsCert}
                    validationMessage={
                        invalidTls
                            ? 'A TLS certificate is required when TLS is enabled on a forwarder.'
                            : undefined
                    }
                    data-testid="tls-cert-input"
                />
            </FlexSection>
            <FlexSection>
                <TextareaField
                    isInvalid={invalidTls}
                    style={{ height: '45vh' }}
                    label="TLS Key"
                    value={state.tlsKey || ''}
                    onChange={handleUpdateTlsKey}
                    validationMessage={
                        invalidTls
                            ? 'A TLS private key is required when TLS is enabled on a forwarder.'
                            : undefined
                    }
                    data-testid="tls-key-input"
                />
            </FlexSection>
        </FlexBox>
    );
}

export default TLSSection;
