import React, { useState } from 'react';
import {
    Label,
    ListItem,
    Pane,
    SearchInput,
    Text,
    Tooltip,
    UnorderedList,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { compose, prop, sortBy, toLower } from 'ramda';
import styled from 'styled-components';

import useQuerySelector from '../../../../hooks/useQuerySelector';
import HandleQueryResponse from '../../../../components/HandleQueryResponse';

import { QUERY_LOG_TYPES } from './SearchableTechnologiesList-graphql';

const List = styled(UnorderedList)`
    display: flex;
    flex-wrap: wrap;
    margin: 2vh auto;
`;

const StyledListItem = styled(ListItem)`
    margin: 2vh;
    font-size: 1rem;
`;

const StyledLabel = styled(Label)`
    font-size: 1rem;
`;

const Hint = styled(Text)`
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: grey;
    font-style: italic;
`;

export const ListTechnologies = ({ technologies }) => {
    const [value, setValue] = useState('');

    const handleOnChange = (event) => setValue(event.target.value);

    const sortByLogType = sortBy(compose(toLower, prop('logType')));
    const sortedTechnologies = sortByLogType(technologies);
    const listedTechnologies = sortedTechnologies.filter(
        (technology) =>
            technology.description
                .toLowerCase()
                .indexOf(value.toLowerCase()) !== -1,
    );

    return (
        <>
            <StyledLabel htmlFor="tech-search-input">
                search our {technologies.length} supported technology
                integrations
            </StyledLabel>
            <SearchInput
                id="tech-search-input"
                data-testid="jest-tech-search"
                value={value}
                onChange={handleOnChange}
                width="100%"
                marginTop="0.5rem"
            />
            <Hint>
                Hover over a technology to display the name used for forwarder
                configurations
            </Hint>
            <List data-testid="jest-tech-list">
                {listedTechnologies.map((technology) => (
                    <StyledListItem key={technology.logType}>
                        <Tooltip content={technology.logType} position="top">
                            <Pane>{technology.description}</Pane>
                        </Tooltip>
                    </StyledListItem>
                ))}
            </List>
        </>
    );
};

ListTechnologies.propTypes = {
    technologies: PropTypes.array.isRequired,
};

export default function SearchableTechnologiesList() {
    const response = useQuerySelector(QUERY_LOG_TYPES, null, prop('logTypes'));

    const missingDataTypes = [
        {
            logType: 'PCAP DNS',
            description: 'PCAP DNS',
        },
        {
            logType: 'PCAP DHCP',
            description: 'PCAP DHCP',
        },
        {
            logType: 'CWT_YEEGO',
            description: 'CWT YEEGO',
        },
    ]; // not being returned from chronicle API but supported

    const technologies = response?.data
        ? [...missingDataTypes, ...response.data]
        : missingDataTypes;

    return (
        <HandleQueryResponse response={response}>
            <ListTechnologies technologies={technologies} />
        </HandleQueryResponse>
    );
}
