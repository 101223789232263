import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Heading, Pane, TextInputField } from 'evergreen-ui';
import React, { useEffect } from 'react';
import { prop } from 'ramda';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';

import DeletionDialogButton from '../../../components/DeletionDialog';
import LoadingSpinner from '../../../components/LoadingSpinner';
import PageContainer from '../../../components/PageContainer';

import {
    useDeleteOrganization,
    useQueryOrganization,
    useUpdateOrganization,
} from './Organization-hooks';

const formSchema = yup.object().shape({
    name: yup.string().required().label('Organization Name'),
    contactName: yup.string().required().label('Contact'),
    contactEmail: yup.string().email().label('Contact Email'),
    contactPhone: yup.string().label('Contact Phone'),
    chronicleURL: yup.string().url().label('Chronicle URL'),
    canaryURL: yup.string().url().label('Canary URL'),
    smartsheetURL: yup.string().url().label('Smartsheet URL'),
    kibanaSpaceIdentifier: yup.string().label('Kibana Space Identifier'),
    authGroupName: yup.string().label('Okta Group Name'),
    lookerModel: yup.string().label('Looker Model'),
});

const FormContainer = styled(Pane)`
    padding: 3vh;
    border: 1px solid #e4e7eb;
    box-shadow: rgb(227, 233, 243) 0px 2px 4px;
`;

function Organization() {
    const { organizationId } = useParams();
    const { data, loading } = useQueryOrganization(organizationId);

    const organization = prop('organization', data);

    const { errors, formState, handleSubmit, register, reset } = useForm({
        mode: 'all',
        resolver: yupResolver(formSchema),
        reValidateMode: 'onChange',
    });

    const [mutate] = useUpdateOrganization();
    const [deletionMutation] = useDeleteOrganization();

    useEffect(() => {
        if (!organization) return;

        const defaults = {
            name: organization?.name || '',
            contactName: organization?.pointOfContact?.name || '',
            contactEmail: organization?.pointOfContact?.email || '',
            contactPhone: organization?.pointOfContact?.phone || '',
            chronicleURL: organization?.organizationURLs?.chronicleURL || '',
            canaryURL: organization?.organizationURLs?.canaryURL || '',
            smartsheetURL: organization?.organizationURLs?.smartsheetURL || '',
            kibanaSpaceIdentifier: organization?.kibanaSpaceIdentifier || '',
            authGroupName: organization?.authGroupName || '',
            lookerModel: organization?.lookerConfig?.model || '',
        };

        reset(defaults);
    }, [organization, reset]);

    if (loading) {
        return <LoadingSpinner />;
    }

    const onSubmit = (data) => {
        const organizationProps = {
            name: data.name,
            pointOfContact: {
                name: data.contactName,
                email: data.contactEmail,
                phone: data.contactPhone,
            },
            organizationURLs: {
                chronicleURL: data.chronicleURL,
                canaryURL: data.canaryURL,
                smartsheetURL: data.smartsheetURL,
            },
            kibanaSpaceIdentifier: data.kibanaSpaceIdentifier,
            lookerConfig: {
                model: data.lookerModel,
            },
        };

        const authGroupNameProp = data.authGroupName;
        mutate({
            variables: { organizationId, organizationProps, authGroupNameProp },
        });
    };

    return (
        <PageContainer>
            <Pane
                width="100%"
                display="flex"
                justifyContent="space-between"
                marginBottom="2vh"
            >
                <Heading size={700}>Update Organization</Heading>
            </Pane>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormContainer data-testid="organization-update-form">
                    <TextInputField
                        name="name"
                        label="Organization Name"
                        defaultValue={organization?.name}
                        validationMessage={errors.name?.message}
                        ref={register}
                        required
                    />
                    <TextInputField
                        name="authGroupName"
                        label="Okta Group Name"
                        defaultValue={organization?.authGroupName}
                        validationMessage={errors.authGroupName?.message}
                        ref={register}
                        required
                    />
                    <TextInputField
                        name="contactName"
                        label="Contact"
                        defaultValue={organization?.pointOfContact?.name}
                        validationMessage={errors.contactName?.message}
                        ref={register}
                        required
                    />
                    <TextInputField
                        name="contactEmail"
                        label="Contact Email"
                        defaultValue={organization?.pointOfContact?.email}
                        validationMessage={errors.contactEmail?.message}
                        ref={register}
                    />
                    <TextInputField
                        name="contactPhone"
                        label="Contact Phone"
                        defaultValue={organization?.pointOfContact?.phone}
                        validationMessage={errors.contactPhone?.message}
                        ref={register}
                    />
                    <TextInputField
                        name="chronicleURL"
                        label="Chronicle URL"
                        defaultValue={
                            organization?.organizationURLs?.chronicleURL
                        }
                        validationMessage={errors.chronicleURL?.message}
                        ref={register}
                    />
                    <TextInputField
                        name="canaryURL"
                        label="Canary URL"
                        defaultValue={organization?.organizationURLs?.canaryURL}
                        validationMessage={errors.canaryURL?.message}
                        ref={register}
                    />
                    <TextInputField
                        name="lookerModel"
                        label="Looker Model"
                        defaultValue={organization?.lookerConfig?.model}
                        validationMessage={errors.lookerModel?.message}
                        ref={register}
                    />
                    <TextInputField
                        name="smartsheetURL"
                        label="Client Onboarding Dashboard Smartsheet URL"
                        defaultValue={
                            organization?.organizationURLs?.smartsheetURL
                        }
                        validationMessage={errors.smartsheetURL?.message}
                        ref={register}
                    />
                    <TextInputField
                        name="kibanaSpaceIdentifier"
                        label="Client Kibana Space Identifier"
                        defaultValue={organization?.kibanaSpaceIdentifier}
                        validationMessage={
                            errors.kibanaSpaceIdentifier?.message
                        }
                        ref={register}
                    />
                    <Button
                        type="submit"
                        disabled={!formState.isValid || !formState.isDirty}
                        marginRight="1rem"
                    >
                        Save Organization
                    </Button>
                    <DeletionDialogButton
                        safeDelete={true}
                        deletionKey={organizationId}
                        confirmationKey={organization.name}
                        confirmationField="Name"
                        deletionMutation={deletionMutation}
                        deletionType="Organization"
                    />
                </FormContainer>
            </form>
        </PageContainer>
    );
}

export default Organization;
