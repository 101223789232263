import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'evergreen-ui';

import ErrorAlert from '../../../components/ErrorAlert';
import useTenant from '../../../hooks/useTenant';
import useVerifyIsInternalUser from '../../../hooks/useVerifyIsInternalUser';

const Container = styled.div`
    width: 100%;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function SelectForwarderIdentityType() {
    const [tenant] = useTenant();
    const userHasPermission = useVerifyIsInternalUser();

    if (!tenant) {
        return (
            <Container>
                <h1>Please select an organization</h1>
            </Container>
        );
    }

    if (!userHasPermission) {
        return <ErrorAlert />;
    }

    return (
        <Container>
            <div>
                <h1>Which type of forwarders will be using these keys?</h1>
            </div>
            <div>
                <Link
                    to="/admin/pipeline-secrets/new/chronicle"
                    data-testid="chronicle-link"
                >
                    <Button marginRight="2vh" height={48}>
                        Chronicle
                    </Button>
                </Link>
                <Link
                    to="/admin/pipeline-secrets/new/cdp"
                    data-testid="cdp-link"
                >
                    <Button marginLeft="2vh" height={48}>
                        CDP
                    </Button>
                </Link>
            </div>
        </Container>
    );
}

export default SelectForwarderIdentityType;
