import React from 'react';
import { Badge, Button, Pane } from 'evergreen-ui';
import { always, equals, ifElse, propOr } from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import HandleQueryResponse from '../../../components/HandleQueryResponse';
import SearchableTable from '../../../components/SearchableTable';
import PageContainer from '../../../components/PageContainer';
import withRowDataAsProps from '../../../hocs/withRowDataAsProps';
import withRequireTenant from '../../../hocs/withRequireTenant';
import useTenant from '../../../hooks/useTenant';

import { useCloudForwarders } from './hooks';

const buildUrl = (data) => {
    if (data.forwarderType === 'chronicle') {
        return `/admin/forwarders/cloud/chronicle/${data.collectorType}/${data.id}`;
    }
    return `/admin/forwarders/cloud/cdp/${data.id}`;
};
const NameCell = (data) => <Link to={buildUrl(data)}>{data.name}</Link>;

const ForwarderTypeCell = ({ forwarderType }) => {
    const badgeColor = ifElse(equals('cdp'), always('blue'), always('orange'));
    const color = badgeColor(forwarderType);

    return <Badge color={color}>{forwarderType}</Badge>;
};

const columns = [
    {
        title: 'Name',
        path: ['name'],
        render: withRowDataAsProps(NameCell),
    },
    {
        title: 'Type',
        path: ['forwarderType'],
        render: withRowDataAsProps(ForwarderTypeCell),
    },
    {
        title: 'Organization',
        path: ['organization', 'name'],
    },
    {
        title: 'Created',
        path: ['createdAt'],
    },
    {
        title: 'Created By',
        path: ['createdBy'],
    },
];

const ButtonContainer = styled(Pane)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2vh;
`;

function CloudForwardersTable() {
    const [tenant] = useTenant();
    const id = propOr('', 'id', tenant);

    const { meta, models } = useCloudForwarders(id);
    const { cloudForwarders } = models;

    return (
        <PageContainer>
            <HandleQueryResponse response={meta}>
                <Pane>
                    <ButtonContainer>
                        <Link to="/admin/forwarders/new">
                            <Button iconBefore="plus">New</Button>
                        </Link>
                    </ButtonContainer>
                    <SearchableTable
                        columns={columns}
                        data={cloudForwarders}
                        allowSearch
                    />
                </Pane>
            </HandleQueryResponse>
        </PageContainer>
    );
}

export default withRequireTenant(CloudForwardersTable);
