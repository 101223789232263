// Global abilities

export const ASSUME_AUTH_CONTEXT = 'AssumeAuthContext';
export const RENEW_RESOURCE_UCN = 'RenewAnyResourceUcn';

// Organization abilities
export const ASSIGN_AUTH_GROUP = 'AssignAuthGroup';
export const CREATE_ORGANIZATIONS = 'CreateOrganizations';
export const REMOVE_ORGANIZATIONS = 'RemoveOrganizations';
export const RESTORE_ORGANIZATIONS = 'RestoreOrganizations';
export const UPDATE_ORGANIZATIONS = 'UpdateOrganizations';
export const UPDATE_OWN_ORGANIZATIONS = 'UpdateOwnOrganizations';
export const VIEW_ORGANIZATIONS = 'ViewOrganizations';
export const VIEW_OWN_ORGANIZATIONS = 'ViewOwnOrganizations';

// Release Notes abilities
export const CREATE_RELEASE_NOTES = 'CreateReleaseNotes';
export const REMOVE_RELEASE_NOTES = 'CreateReleaseNotes';
export const RESTORE_RELEASE_NOTES = 'CreateReleaseNotes';
export const UPDATE_RELEASE_NOTES = 'CreateReleaseNotes';
export const PUBLISH_RELEASE_NOTES = 'CreateReleaseNotes';
export const UNPUBLISH_RELEASE_NOTES = 'CreateReleaseNotes';
export const VIEW_PUBLISHED_RELEASE_NOTES = 'ViewPublishedReleaseNotes';
export const VIEW_UNPUBLISHED_RELEASE_NOTES = 'ViewUnpublishedReleaseNotes';

// Forwarder abilities
export const CREATE_ANY_FORWARDER = 'CreateAnyForwarder';
export const CREATE_ORG_FORWARDER = 'CreateOrgForwarder';
export const DEPLOY_ANY_FORWARDER = 'DeployAnyForwarder';
export const REMOVE_ANY_FORWARDER = 'RemoveAnyForwarder';
export const RESTORE_ANY_FORWARDER = 'RestoreAnyForwarder';
export const UNDEPLOY_ANY_FORWARDER = 'UndeployAnyForwarder';
export const UPDATE_ANY_FORWARDER = 'UpdateAnyForwarder';
export const UPDATE_ORG_FORWARDER = 'UpdateOrgForwarder';
export const UPGRADE_ANY_FORWARDER = 'UpgradeAnyForwarder';
export const VIEW_ANY_FORWARDER = 'ViewAnyForwarder';
export const VIEW_ORG_FORWARDER = 'ViewOrgForwarder';
export const VIEW_ANY_FORWARDER_CONFIG = 'ViewAnyForwarderConfig';

// CDPForwarder abilities
export const CREATE_ANY_CDP_FORWARDER = 'CreateAnyCDPForwarder';
export const CREATE_ORG_CDP_FORWARDER = 'CreateOrgCDPForwarder';
export const DEPLOY_ANY_CDP_FORWARDER = 'DeployAnyCDPForwarder';
export const REMOVE_ANY_CDP_FORWARDER = 'RemoveAnyCDPForwarder';
export const RESTORE_ANY_CDP_FORWARDER = 'RestoreAnyCDPForwarder';
export const UNDEPLOY_ANY_CDP_FORWARDER = 'UndeployAnyCDPForwarder';
export const UPDATE_ANY_CDP_FORWARDER = 'UpdateAnyCDPForwarder';
export const UPDATE_ORG_CDP_FORWARDER = 'UpdateOrgCDPForwarder';
export const UPGRADE_ANY_CDP_FORWARDER = 'UpgradeAnyCDPForwarder';
export const VIEW_ANY_CDP_FORWARDER = 'ViewAnyCDPForwarder';
export const VIEW_ORG_CDP_FORWARDER = 'ViewOrgCDPForwarder';
export const VIEW_ANY_CDP_FORWARDER_CONFIG = 'ViewAnyCDPForwarderConfig';

// Chronicle FW identity abilities
export const CREATE_ANY_CHRONICLE_FW_IDENTITY = 'CreateAnyChronicleFwIdentity';
export const REMOVE_ANY_CHRONICLE_FW_IDENTITY = 'RemoveAnyChronicleFwIdentity';
export const RESTORE_ANY_CHRONICLE_FW_IDENTITY =
    'RestoreAnyChronicleFwIdentity';
export const UPDATE_ANY_CHRONICLE_FW_IDENTITY = 'UpdateAnyChronicleFwIdentity';
export const VIEW_ANY_CHRONICLE_FW_IDENTITY = 'ViewAnyChronicleFwIdentity';

// CDP FW identity abilities
export const CREATE_ANY_CDP_FW_IDENTITY = 'CreateAnyCDPFwIdentity';
export const REMOVE_ANY_CDP_FW_IDENTITY = 'RemoveAnyCDPFwIdentity';
export const RESTORE_ANY_CDP_FW_IDENTITY = 'RestoreAnyCDPFwIdentity';
export const UPDATE_ANY_CDP_FW_IDENTITY = 'UpdateAnyCDPFwIdentity';
export const VIEW_ANY_CDP_FW_IDENTITY = 'ViewAnyCDPFwIdentity';

// DeploymentLog abilities
export const VIEW_ANY_DEPLOYMENT_LOG = 'ViewAnyDeploymentLog';
export const VIEW_ORG_DEPLOYMENT_LOG = 'ViewOrgDeploymentLog';

// CDPDeploymentLog abilities
export const VIEW_ANY_CDP_DEPLOYMENT_LOG = 'ViewAnyCDPDeploymentLog';
export const VIEW_ORG_CDP_DEPLOYMENT_LOG = 'ViewOrgCDPDeploymentLog';

// Cluster abilities
export const SYNC_CLUSTERS = 'SyncClusters';
export const UPDATE_ANY_CLUSTER = 'UpdateAnyCluster';
export const VIEW_ANY_CLUSTER = 'ViewAnyCluster';
export const VIEW_ORG_CLUSTER = 'ViewOrgCluster';

// Log abilities
export const VIEW_LOG_TYPES = 'ViewLogTypes';
export const SYNC_LOG_TYPES = 'SyncLogTypes';

// Jira abilities
export const VIEW_ANY_JIRA_ACTIONS = 'ViewAnyJiraActions';
export const VIEW_ANY_JIRA_CASES = 'ViewAnyJiraCases';
export const VIEW_ANY_JIRA_ABUSE_BOX = 'ViewAnyAbuseBoxSummaries';
export const VIEW_ORG_JIRA_ACTIONS = 'ViewOrgJiraActions';
export const VIEW_ORG_JIRA_CASES = 'ViewOrgJiraCases';
export const VIEW_ORG_JIRA_ABUSE_BOX = 'ViewOrgAbuseBoxSummaries';
export const CREATE_ORG_JIRA_CASE_COMMENT = 'CreateOrgJiraCaseComment';
export const CREATE_ANY_JIRA_CASE_COMMENT = 'CreateAnyJiraCaseComment';

// LTE abilities
export const VIEW_LTE_FACTS = 'ViewLTEFacts';
export const CREATE_LTE_FACTS = 'CreateLTEFacts';
export const UPDATE_LTE_FACTS = 'UpdateLTEFacts';
export const REMOVE_LTE_FACTS = 'RemoveLTEFacts';
export const RESTORE_LTE_FACTS = 'RestoreLTEFacts';

export const VIEW_LTE_RULES = 'ViewLTERules';
export const CREATE_LTE_RULES = 'CreateLTERules';
export const UPDATE_LTE_RULES = 'UpdateLTERules';
export const REMOVE_LTE_RULES = 'RemoveLTERules';
export const RESTORE_LTE_RULES = 'RestoreLTERules';

// Dropbox abilities
export const SHARE_DROPBOX_FILES = 'ShareDropboxFiles';
export const PROVISION_DROPBOX_FOLDER = 'ProvisionDropboxFolder';
export const VIEW_ANY_DROPBOX = 'ViewAnyShareableDropboxLink';
export const VIEW_ORG_DROPBOX = 'ViewOrgShareableDropboxLink';
export const CREATE_ORG_FILE_REQUEST = 'CreateOrgDropboxFileRequest';
export const CREATE_ANY_FILE_REQUEST = 'CreateAnyDropboxFileRequest';

// Grouped VIEW* abilities
export const VIEW_ANYTHING = [
    VIEW_ANY_CHRONICLE_FW_IDENTITY,
    VIEW_ANY_CLUSTER,
    VIEW_ANY_DEPLOYMENT_LOG,
    VIEW_ANY_FORWARDER,
    VIEW_ANY_FORWARDER_CONFIG,
    VIEW_ORG_CLUSTER,
    VIEW_ORG_DEPLOYMENT_LOG,
    VIEW_ORG_FORWARDER,
    VIEW_ORGANIZATIONS,
    VIEW_OWN_ORGANIZATIONS,
    VIEW_ANY_FORWARDER,
    VIEW_ANY_JIRA_ACTIONS,
    VIEW_ANY_JIRA_CASES,
    VIEW_ANY_JIRA_ABUSE_BOX,
    VIEW_LOG_TYPES,
    VIEW_LTE_FACTS,
    VIEW_LTE_RULES,
    VIEW_ANY_DROPBOX,
];

// Grouped CREATE* abilities
export const CREATE_ANYTHING = [
    CREATE_ANY_CHRONICLE_FW_IDENTITY,
    CREATE_ANY_FORWARDER,
    CREATE_ORGANIZATIONS,
    CREATE_ORG_FORWARDER,
    CREATE_LTE_FACTS,
    CREATE_LTE_RULES,
];

// Grouped UPDATE* abilities
export const UPDATE_ANYTHING = [
    UPDATE_ANY_CLUSTER,
    UPDATE_ANY_FORWARDER,
    UPDATE_ORG_FORWARDER,
    UPDATE_ORGANIZATIONS,
    UPDATE_OWN_ORGANIZATIONS,
    UPDATE_ANY_CHRONICLE_FW_IDENTITY,
    UPDATE_LTE_FACTS,
    UPDATE_LTE_RULES,
];

// Grouped REMOVE* abilities
export const REMOVE_ANYTHING = [
    REMOVE_ANY_CHRONICLE_FW_IDENTITY,
    REMOVE_ANY_FORWARDER,
    REMOVE_ORGANIZATIONS,
    REMOVE_LTE_FACTS,
    REMOVE_LTE_RULES,
];

// Grouped RESTORE* abilities
export const RESTORE_ANYTHING = [
    RESTORE_ANY_CHRONICLE_FW_IDENTITY,
    RESTORE_ANY_FORWARDER,
    RESTORE_ORGANIZATIONS,
    RESTORE_LTE_FACTS,
    RESTORE_LTE_RULES,
];
