import React from 'react';
import { Heading, Pane, Text, toaster, Alert } from 'evergreen-ui';

import LoadingSpinner from '../../../components/LoadingSpinner';
import PageContainer from '../../../components/PageContainer';
import { CDP } from '../../../utils/constants';
import ForwarderIdentityKeysForm from '../ForwarderIdentityKeysForm';
import useCDPForwarderIdentity from './CDPForwarderIdentity-hooks';
import { useHistory } from 'react-router-dom';

const Header = ({ identity }) => (
    <Pane data-testid="header">
        <Heading size={800} marginBottom="1vh">
            {identity.name}
        </Heading>
        <Pane display="flex" marginBottom="2vh">
            <Pane display="flex" marginRight="1vw">
                <Heading marginRight="0.25vw">organization:</Heading>
                <Text>{identity.organization?.name}</Text>
            </Pane>
            <Pane display="flex" marginRight="1vw">
                <Heading marginRight="0.25vw">last update:</Heading>
                <Text>{identity.updatedAt || identity.createdAt}</Text>
            </Pane>
            <Pane display="flex" marginRight="1vw">
                <Heading marginRight="0.25vw">updated by:</Heading>
                <Text>{identity.updatedBy || identity.createdBy}</Text>
            </Pane>
        </Pane>
    </Pane>
);

const CDPForwarderIdentity = () => {
    const { meta, models, operations } = useCDPForwarderIdentity();
    const history = useHistory();
    const {
        loading,
        loadError,
        saving,
        saveSuccess,
        saveError,
        deleteSuccess,
        deleteError,
    } = meta;
    const { cdpForwarderIdentity } = models;
    const { update, deleteKey } = operations;

    if (loading) {
        return <LoadingSpinner />;
    }

    if (loadError) {
        return (
            <Alert
                title="UNABLE TO QUERY DATA"
                intent="danger"
                appearance="card"
            >
                {loadError.message}
            </Alert>
        );
    }

    if (saveError) {
        console.warn(saveError.message);
        toaster.danger('Failure. Unable to save.');
    }

    if (saveSuccess) {
        toaster.success('Success!');
    }

    if (deleteError) {
        toaster.danger('ERROR: Unable to delete.');
    }

    if (deleteSuccess) {
        history.push(`/admin/pipeline-secrets`);
        toaster.success(
            `Successfully Deleted Identity Key ${cdpForwarderIdentity.name}`,
        );
    }

    return (
        <PageContainer>
            <Header identity={cdpForwarderIdentity} />
            <ForwarderIdentityKeysForm
                forwarderIdentity={cdpForwarderIdentity}
                identityType={CDP}
                isLoading={saving}
                onSubmit={update}
                deletionMutation={deleteKey}
            />
        </PageContainer>
    );
};

export default CDPForwarderIdentity;
