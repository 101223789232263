import { equals } from 'ramda';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorAlert from '../../components/ErrorAlert';
import useQueryOrganization from '../../hooks/useQueryOrganization';
import useTenant from '../../hooks/useTenant';
import useVerifyIsInternalUser from '../../hooks/useVerifyIsInternalUser';
import { isNotEmptyOrNil, isEmptyOrNil } from '../../utils/common';

export const withVerifyTenantIdMatchesUrlParams = (
    Component,
    fallbackUrl = '/',
) => (props) => {
    const { organizationId } = useParams();
    const [tenant, setTenant] = useTenant();
    const isCyderesUser = useVerifyIsInternalUser();
    const { data: organization, loading, error } = useQueryOrganization(
        organizationId,
    );

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <ErrorAlert error={error} />;
    }

    if (isEmptyOrNil(tenant)) {
        if (isCyderesUser && organization) {
            const { id, name } = organization;
            setTenant({ id, name });
        }
        if (!isCyderesUser && organization) {
            return <Redirect to="/" data-testid="redirect" />;
        }
    }
    if (isNotEmptyOrNil(tenant)) {
        if (!equals(tenant.id, organizationId)) {
            return <Redirect to={fallbackUrl} />;
        } else {
            return <Component {...props} />;
        }
    }

    return null;
};

export default withVerifyTenantIdMatchesUrlParams;
