import PropTypes from 'prop-types';

const SECRET_KEY_PROPTYPES = PropTypes.shape({
    type: PropTypes.string,
    projectId: PropTypes.string,
    privateKeyId: PropTypes.string,
    privateKey: PropTypes.string,
    clientEmail: PropTypes.string,
    clientId: PropTypes.string,
    authUri: PropTypes.string,
    tokenUri: PropTypes.string,
    authProviderCertUrl: PropTypes.string,
    clientCertUrl: PropTypes.string,
});

const FORWARDER_IDENTITY_PROPTYPES = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    createdAt: PropTypes.string,
    createdBy: PropTypes.string,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
    collectorId: PropTypes.string,
    customerId: PropTypes.string,
    secretKey: SECRET_KEY_PROPTYPES,
});

export default FORWARDER_IDENTITY_PROPTYPES;
