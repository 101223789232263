import React from 'react';
import { Heading } from 'evergreen-ui';
import styled from 'styled-components';

import PageContainer from '../../../components/PageContainer';

import SearchableTechnologiesList from './SearchableTechnologiesList';

const PageTitle = styled(Heading)`
    font-size: 2rem;
    color: #1890ff;
    margin-bottom: 1rem;
`;

export default function Technologies() {
    return (
        <PageContainer>
            <PageTitle>Supported Technologies</PageTitle>
            <SearchableTechnologiesList />
        </PageContainer>
    );
}
