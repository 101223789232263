import { Button, Dialog, TextInputField } from 'evergreen-ui';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withEventTargetAsValue } from '../../utils/common';

function DeletionDialogButton({
    safeDelete,
    deletionKey,
    confirmationKey,
    confirmationField,
    deletionMutation,
    deletionType,
}) {
    const runDeletionMutation = async () => {
        setIsLoading(true);
        await deletionMutation({
            variables: { id: deletionKey },
        });
        if (deletionMutation.error) {
            return null;
        }
        setIsLoading(false);
        return deletionMutation;
    };
    const closeDialog = () => {
        setConfirmationValue('');
        setShowDialog(false);
    };
    const [confirmationValue, setConfirmationValue] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const confirmationLabel = `Confirm Delete ${deletionType}`;
    const textFieldLabel = `Enter ${deletionType} name: ${confirmationField}`;
    const textFieldPlaceholder = `${deletionType} ${confirmationField} (Case Sensitive)`;
    const buttonLabel = `Delete ${deletionType}`;

    if (safeDelete) {
        return (
            <Button
                type="button"
                appearance="primary"
                intent="danger"
                onClick={() => setShowDialog(true)}
            >
                {buttonLabel}
                <Dialog
                    isShown={showDialog}
                    title="Confirm Deletion"
                    intent="danger"
                    onConfirm={runDeletionMutation}
                    onCloseComplete={closeDialog}
                    confirmLabel={confirmationLabel}
                    isConfirmDisabled={confirmationKey !== confirmationValue}
                    isConfirmLoading={isLoading}
                    preventBodyScrolling
                >
                    <TextInputField
                        label={textFieldLabel}
                        value={confirmationValue}
                        onChange={withEventTargetAsValue(setConfirmationValue)}
                        placeholder={textFieldPlaceholder}
                    />
                </Dialog>
            </Button>
        );
    } else {
        return (
            <Button
                type="button"
                appearance="primary"
                intent="danger"
                onClick={runDeletionMutation}
            >
                {buttonLabel}
            </Button>
        );
    }
}

DeletionDialogButton.propTypes = {
    safeDelete: PropTypes.bool, // True: requires the user to enter a value to delete
    deletionKey: PropTypes.string, // The value passed to the deletion mutation (ex: organization.id)
    confirmationKey: PropTypes.string, // The value the user needs to match in order to safe delete (ex: Stark Industries)
    confirmationField: PropTypes.string, // The field or prop the user needs to match (ex: name)
    deletionMutation: PropTypes.func, // The gql mutation that deletes the object
    deletionType: PropTypes.string, // What is being deleted.  This labels everything (ex: Organization)
};

export default DeletionDialogButton;
