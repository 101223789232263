import ChronicleForwarderIdentity from '../admin-portal/secrets/ChronicleForwarderIdentity';
import CDPForwarderIdentity from '../admin-portal/secrets/CDPForwarderIdentity';
import CreateCdpForwarderIdentity from '../admin-portal/secrets/CreateCdpForwarderIdentity';
import CreateChronicleForwarderIdentity from '../admin-portal/secrets/CreateChronicleForwarderIdentity';
import ForwarderIdentities from '../admin-portal/secrets/ForwarderIdentities';
import SelectForwarderIdentityType from '../admin-portal/secrets/SelectForwarderIdentityType';

import { baseAdminPath } from './home-routes';

const basePath = `${baseAdminPath}/pipeline-secrets`;

const routes = {};

routes.ForwarderIdentities = {
    path: `${basePath}`,
    exact: true,
    admin: true,
    secure: true,
    component: ForwarderIdentities,
};

routes.SelectForwarderIdentityType = {
    path: `${basePath}/new`,
    exact: true,
    admin: true,
    secure: true,
    component: SelectForwarderIdentityType,
};

routes.CreateCdpForwarderIdentity = {
    path: `${basePath}/new/cdp`,
    exact: true,
    admin: true,
    secure: true,
    component: CreateCdpForwarderIdentity,
};

routes.CreateChronicleForwarderIdentity = {
    path: `${basePath}/new/chronicle`,
    exact: true,
    admin: true,
    secure: true,
    component: CreateChronicleForwarderIdentity,
};

routes.ForwarderIdentityDashboard = {
    path: `${basePath}/chronicle/:identityId`,
    exact: true,
    admin: true,
    secure: true,
    component: ChronicleForwarderIdentity,
};

routes.CDPForwarderIdentity = {
    path: `${basePath}/cdp/:identityId`,
    exact: true,
    admin: true,
    secure: true,
    component: CDPForwarderIdentity,
};

export default routes;
