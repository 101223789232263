import { ApolloClient, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { resolve } from '@specialblend/react-process-env';
import { cache, updateNetworkHealthCheckCache } from './cache';

const uri = resolve('REACT_APP_RUBIX_URI', process.env);
const appName = resolve('REACT_APP_NAME', process.env);
// REACT_APP_VERSION is only set at build time
const appVersion = process.env.REACT_APP_VERSION;

export default function createClient(token) {
    // ignoring httpLinkErrors for now since only used to console log errors
    /* istanbul ignore next */
    const httpLinkErrors = onError(
        ({ graphQLErrors, operation, networkError }) => {
            if (graphQLErrors) {
                console.error(
                    'GraphQL Error at the following operation: ',
                    operation,
                );
            }
            if (networkError) {
                console.error('Network error: ', networkError.message);
                updateNetworkHealthCheckCache({
                    status: 1,
                    error: networkError,
                });
            }
        },
    );

    const link = new HttpLink({
        uri,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    const client = new ApolloClient({
        cache,
        link: httpLinkErrors.concat(link),
        connectToDevTools: true,
        name: appName,
        version: appVersion,
    });

    return client;
}
