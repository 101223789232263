import { Pane, Heading } from 'evergreen-ui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    margin: 0 0 1vh 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const DescriptionSection = styled.div`
    width: 100%;
    margin-bottom: 3vh;
    flex-direction: column;
    align-items: flex-end;
`;

export const DescriptionsFlexBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DescriptionBlock = styled.div`
    height: 40px;
    font-size: 0.8rem;
    margin-right: 3.5vh;
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
        margin-right: 0;
        margin-left: 3.5vh;
    }
`;

export const DescriptionLabel = styled.div`
    font-weight: bold;
    height: 50%;
    white-space: nowrap;
`;

export const DescriptionValue = styled(Pane)`
    height: 50%;
`;

export const TitleSection = styled.div`
    width: 100%;
    margin-bottom: 1vh;
`;

export const Title = styled(Heading)`
    margin: 0;
    font-size: 2rem;
`;

export const StatusBadge = styled.div`
    width: 12px;
    height: 12px;
    border: 5px solid ${(props) => props.color};
    border-radius: 30px;
    background-color: ${(props) => props.color};
    transition: all 0.5s ease-out;
    border: 1px solid white;

    ${(props) =>
        props.animate &&
        css`
            animation-name: grow;
            animation-duration: 900ms;
            animation-timing-function: ease-out;
            animation-delay: 0;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            animation-fill-mode: none;
            animation-play-state: running;
            @keyframes grow {
                0% {
                    transform: scale(1);
                }
                25% {
                    transform: scale(1.1);
                }
                50% {
                    transform: scale(1.3);
                }
                75% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }
        `}
`;
