import React from 'react';
import { Pane, Tooltip } from 'evergreen-ui';
import { always, cond, equals, propOr } from 'ramda';

import {
    DEPLOYMENT_STATE_ACTIVE,
    DEPLOYMENT_STATE_DEPLOYING,
    DEPLOYMENT_STATE_ERROR,
    DEPLOYMENT_STATE_INSTALLING,
} from '../../utils/constants';
import useTenant from '../../hooks/useTenant';
import {
    Container,
    DescriptionBlock,
    DescriptionsFlexBox,
    DescriptionLabel,
    DescriptionSection,
    DescriptionValue,
    StatusBadge,
    Title,
    TitleSection,
} from './ForwarderDashboardHeader-styles';

const determineStatusBadgeColor = cond([
    [equals(DEPLOYMENT_STATE_ACTIVE), always('#5bb27d')],
    [equals(DEPLOYMENT_STATE_DEPLOYING), always('#f8cf5f')],
    [equals(DEPLOYMENT_STATE_INSTALLING), always('#f8cf5f')],
    [equals(DEPLOYMENT_STATE_ERROR), always('red')],
]);
const determineStatusMessage = cond([
    [equals(DEPLOYMENT_STATE_ACTIVE), always('All good!')],
    [
        equals(DEPLOYMENT_STATE_DEPLOYING),
        always('The forwarder is deploying...'),
    ],
    [equals(DEPLOYMENT_STATE_INSTALLING), always('Installing...')],
    [equals(DEPLOYMENT_STATE_ERROR), always('Error! Something is not right.')],
]);

const Status = ({ deploymentState }) => {
    const color = determineStatusBadgeColor(deploymentState);
    const message = determineStatusMessage(deploymentState);

    return (
        <Tooltip content={message}>
            <Pane
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <StatusBadge
                    color={color}
                    animate={deploymentState === DEPLOYMENT_STATE_ERROR}
                    data-testid="status-badge"
                />
            </Pane>
        </Tooltip>
    );
};

function ForwarderDashboardHeader({
    deploymentState,
    forwarder,
    forwarderType,
    clusterName,
}) {
    const [tenant] = useTenant();

    const { collectors } = forwarder.configuration;
    const [collector] = collectors;
    const logs = collector.collectorType;

    const sections = [
        {
            label: 'status',
            value: <Status deploymentState={deploymentState} />,
        },
        {
            label: 'forwarder type',
            value: forwarderType,
        },
        {
            label: 'logs',
            value: logs,
        },
        {
            label: 'organization',
            value: propOr('', 'name', tenant),
        },
        {
            label: 'cluster',
            value: clusterName,
        },
        {
            label: 'forwarder ID',
            value: forwarder.id,
        },
    ];

    return (
        <Container data-testid="dashboard-header">
            <TitleSection>
                <Title>{forwarder.name}</Title>
            </TitleSection>
            <DescriptionSection direction="column" align="flex-end" width="60%">
                <DescriptionsFlexBox>
                    {sections.map((section) => (
                        <DescriptionBlock
                            key={`${section.value}-description-text`}
                        >
                            <DescriptionLabel>{section.label}</DescriptionLabel>
                            <DescriptionValue>{section.value}</DescriptionValue>
                        </DescriptionBlock>
                    ))}
                </DescriptionsFlexBox>
            </DescriptionSection>
        </Container>
    );
}

export { Status };
export default ForwarderDashboardHeader;
