import { curry } from 'ramda';
import React, { useReducer, useContext } from 'react';

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const useStateContext = () => {
    const context = useContext(StateContext);

    if (!context) {
        throw new Error(
            'useStateContext must but called within StateReducerProvider context provider',
        );
    }

    return context;
};

const useDispatchContext = () => {
    const context = useContext(DispatchContext);

    if (!context) {
        throw new Error(
            'useDispatchContext must be called within StateReducerProvider context provider',
        );
    }

    return context;
};

function StateReducerProvider({ children, reducer, initialState }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    );
}

const withStateReducerContext = curry(
    (reducer, initialState, component, props) => {
        const Component = component;

        return (
            <StateReducerProvider
                reducer={reducer}
                initialState={initialState}
                children={<Component {...props} />}
            />
        );
    },
);

export { StateReducerProvider, useStateContext, useDispatchContext };
export default withStateReducerContext;
