import { gql } from '@apollo/client';

export const CLOUD_PCAP_FW_FRAGMENT = gql`
    fragment CloudPcapFwFragment on ChronicleCloudPcapForwarder {
        configuration {
            client
            pcap {
                common {
                    batchEveryBytes
                    batchEverySeconds
                    dataType
                    enabled
                }
                bpf
                interface
            }
            infrastructure {
                autoscaling {
                    enabled
                }
                hostname
                replicaCount
                resources {
                    limits {
                        cpu
                        memory
                    }
                    requests {
                        cpu
                        memory
                    }
                }
                workerCount
            }
        }
        kubernetesService
        identityId
        organizationId
        name
    }
`;

export const CREATE_PCAP_CLOUD_FW = gql`
    mutation createPcapCloudFw($props: ChronicleCloudPcapForwarderInput!) {
        createChronicleCloudPcapForwarder(props: $props) {
            id
            ...CloudPcapFwFragment
        }
    }
    ${CLOUD_PCAP_FW_FRAGMENT}
`;

export const REMOVE_PCAP_CLOUD_FW = gql`
    mutation removePcapCloudFw($id: ID!) {
        removeChronicleCloudPcapForwarder(id: $id)
    }
`;

export const UPDATE_PCAP_CLOUD_FW = gql`
    mutation updatePcapCloudFw(
        $id: ID!
        $props: ChronicleCloudPcapForwarderInput!
    ) {
        updateChronicleCloudPcapForwarder(id: $id, props: $props) {
            id
            ...CloudPcapFwFragment
        }
    }
    ${CLOUD_PCAP_FW_FRAGMENT}
`;

export const QUERY_PCAP_CLOUD_FW = gql`
    query pcapCloudFw($id: ID!) {
        chronicleCloudPcapForwarder(id: $id) {
            id
            ...CloudPcapFwFragment
        }
    }
    ${CLOUD_PCAP_FW_FRAGMENT}
`;
