import { ApolloProvider } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';

import AnalyticsProvider from './AnalyticsProvider';
import StateProvider from './StateProvider';
import TenantProvider from './TenantProvider';

import createClient from '../apollo/client';
import { parseIdToken } from '../utils/auth';

export default function AppProviders({ children }) {
    const auth = useOktaAuth();
    const token = parseIdToken(auth);
    const client = createClient(token);
    return (
        <ApolloProvider client={client}>
            <StateProvider>
                <TenantProvider>
                    <AnalyticsProvider>{children}</AnalyticsProvider>
                </TenantProvider>
            </StateProvider>
        </ApolloProvider>
    );
}
