import PropTypes from 'prop-types';

const APP_PROPTYPES = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
});

const PROJECTS_PROPTYPES = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    apps: PropTypes.arrayOf(APP_PROPTYPES),
});

const CLUSTER_PROPTYPES = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
    description: PropTypes.string,
    projects: PropTypes.arrayOf(PROJECTS_PROPTYPES),
});

export default CLUSTER_PROPTYPES;
