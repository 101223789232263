import { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { toaster } from 'evergreen-ui';

import { QUERY_ALL_ORG_FORWARDERS } from '../../graphql/queries';
import { anyIsLoading } from '../../utils/common';

export const CREATE_CHRONICLE_FW = gql`
    mutation createChronicleFw($props: CreateForwarderInput!) {
        createForwarder(props: $props) {
            id
            name
        }
    }
`;

export const DEPLOY_CHRONICLE_FORWARDER = gql`
    mutation deployChronicleForwarder(
        $appName: String!
        $clusterId: ID!
        $forwarderId: ID!
        $chronicleForwarderIdentityId: ID!
    ) {
        forwarder(id: $forwarderId) {
            deployApp(
                props: {
                    name: $appName
                    environmentId: $clusterId
                    chronicleForwarderIdentityId: $chronicleForwarderIdentityId
                }
            ) {
                id
                name
                status
                error {
                    code
                    status
                    message
                }
            }
        }
    }
`;

const useCreateChronicleForwarderMutation = ({
    clusterId,
    forwarderName,
    forwarderConfiguration,
    identityId,
    organizationId,
}) => {
    const [newForwarderId, setNewForwarderId] = useState(null);

    const [deployChronicleForwarder, deployChronicleForwarderRes] = useMutation(
        DEPLOY_CHRONICLE_FORWARDER,
        {
            variables: {
                forwarderId: newForwarderId,
                clusterId: clusterId,
                chronicleForwarderIdentityId: identityId,
                appName: forwarderName,
            },
            refetchQueries: [
                {
                    query: QUERY_ALL_ORG_FORWARDERS,
                    variables: {
                        id: organizationId,
                    },
                },
            ],
            awaitRefetchQueries: true,
            onError: () =>
                toaster.danger('Unable to perfom action at this time.'),
            onCompleted: () =>
                toaster.success('Successfully created new forwarder.'),
        },
    );
    const [createForwarder, createForwarderRes] = useMutation(
        CREATE_CHRONICLE_FW,
        {
            variables: {
                props: {
                    organizationId,
                    name: forwarderName,
                    configuration: forwarderConfiguration,
                },
            },
            onError: () =>
                toaster.danger('Unable to perfom action at this time.'),
            onCompleted: async (data) => {
                await setNewForwarderId(data.createForwarder.id);
                deployChronicleForwarder();
            },
        },
    );

    let error;
    if (createForwarderRes.error) {
        error = createForwarderRes.error;
    }
    if (deployChronicleForwarderRes.error) {
        error = deployChronicleForwarderRes.error;
    }

    const response = {
        data: {
            deployment: deployChronicleForwarderRes.data,
            forwarder: createForwarderRes.data,
        },
        loading: anyIsLoading([
            createForwarderRes,
            deployChronicleForwarderRes,
        ]),
        error,
    };

    return [createForwarder, response];
};

export default useCreateChronicleForwarderMutation;
