import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Pane } from 'evergreen-ui';
import PropTypes from 'prop-types';
import {
    dissoc,
    join,
    keys,
    lensPath,
    over,
    pipe,
    prop,
    propOr,
    replace,
    split,
    toUpper,
} from 'ramda';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import FormInputField from '../../../components/FormInputField';
import { CDP, CHRONICLE } from '../../../utils/constants';
import DeletionDialogButton from '../../../components/DeletionDialog';

const requiredMessage = 'this field is required';
const schema = yup.object().shape({
    customerName: yup.string(),
    collectorId: yup.string().required(requiredMessage),
    customerId: yup.string().required(requiredMessage),
    secretKey: yup.object().shape({
        type: yup.string().required(requiredMessage),
        projectId: yup.string().required(requiredMessage),
        privateKeyId: yup.string().required(requiredMessage),
        privateKey: yup.string().required(requiredMessage),
        clientEmail: yup.string().email().required(requiredMessage),
        clientId: yup.string().required(requiredMessage),
        authUri: yup.string().required(requiredMessage),
        tokenUri: yup.string().required(requiredMessage),
        authProviderCertUrl: yup.string().required(requiredMessage),
        clientCertUrl: yup.string().required(requiredMessage),
    }),
});

function ForwarderIdentityKeysForm({
    forwarderIdentity,
    identityType,
    isLoading,
    onSubmit,
    deletionMutation,
}) {
    const { errors, handleSubmit, register } = useForm({
        resolver: yupResolver(schema),
    });

    const customerName = propOr('', 'customerName', forwarderIdentity);
    const customerId = propOr('', 'customerId', forwarderIdentity);
    const collectorId = propOr('', 'collectorId', forwarderIdentity);
    const secretKey = pipe(
        prop('secretKey'),
        dissoc('__typename'),
    )(forwarderIdentity);

    const identityValues = { customerName, customerId, collectorId };
    const identityInputKeys = keys(identityValues);
    const secretKeyInputKeys = keys(secretKey);

    const createLabel = pipe(split(/(?=[A-Z])/), join(' '), toUpper);

    const displaySecretKeyValue = (key) => {
        let displayValue = secretKey[key];

        if (key !== 'privateKey') return displayValue;

        return replace(/\n/g, '\\n')(displayValue);
    };

    const replacePrivateKeyValue = (values) => {
        const privateKey = lensPath(['secretKey', 'privateKey']);

        const replaceValue = replace(/\\n/g, '\n'); // input modifies the newline char \n to be \\n so replacing with expected value

        return over(privateKey, replaceValue, values);
    };

    const submitForm = pipe(replacePrivateKeyValue, onSubmit);

    return (
        <form data-testid="identity-form">
            <Pane width="100%" display="flex" justifyContent="flex-end">
                <DeletionDialogButton
                    safeDelete={true}
                    deletionKey={forwarderIdentity.id}
                    confirmationKey={forwarderIdentity.name}
                    confirmationField={forwarderIdentity.name}
                    deletionMutation={deletionMutation}
                    deletionType="identity key"
                />
                <Button
                    appearance="primary"
                    intent="success"
                    marginBottom="1rem"
                    marginLeft="1rem"
                    onClick={handleSubmit(submitForm)}
                    isLoading={isLoading}
                    disabled={isLoading}
                >
                    SAVE
                </Button>
            </Pane>
            {identityInputKeys.map((key) => {
                if (identityType !== CDP && key === 'customerName') {
                    return null;
                } else {
                    return (
                        <FormInputField
                            label={createLabel(key)}
                            defaultValue={identityValues[key]}
                            error={errors[key]?.message}
                            name={key}
                            register={register}
                            key={key}
                        />
                    );
                }
            })}
            {secretKeyInputKeys.map((key) => (
                <FormInputField
                    label={createLabel(key)}
                    defaultValue={displaySecretKeyValue(key)}
                    error={errors?.secretKey?.[key]?.message}
                    name={`secretKey.${key}`}
                    register={register}
                    key={key}
                />
            ))}
        </form>
    );
}

ForwarderIdentityKeysForm.propTypes = {
    forwarderIdentity: PropTypes.shape({
        customerName: PropTypes.string,
        collectorId: PropTypes.string,
        customerId: PropTypes.string,
        secretKey: PropTypes.shape({
            type: PropTypes.string,
            projectId: PropTypes.string,
            privateKeyId: PropTypes.string,
            privateKey: PropTypes.string,
            clientEmail: PropTypes.string,
            clientId: PropTypes.string,
            authUri: PropTypes.string,
            tokenUri: PropTypes.string,
            authProviderCertUrl: PropTypes.string,
            clientCertUrl: PropTypes.string,
        }),
    }),
    identityType: PropTypes.oneOf([CDP, CHRONICLE]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ForwarderIdentityKeysForm;
