import Organizations from '../admin-portal/organizations/Organizations';
import Organization from '../admin-portal/organizations/Organization';
import CreateNewOrganization from '../admin-portal/organizations/CreateNewOrganization';

import { baseAdminPath } from './home-routes';

const basePath = `${baseAdminPath}/organizations`;

const routes = {};

routes.Organizations = {
    path: basePath,
    exact: true,
    admin: true,
    secure: true,
    component: Organizations,
};

routes.CreateNewOrganization = {
    path: `${basePath}/create`,
    exact: true,
    admin: true,
    secure: true,
    component: CreateNewOrganization,
};

routes.Organization = {
    path: `${basePath}/:organizationId`,
    exact: true,
    admin: true,
    secure: true,
    component: Organization,
};

export default routes;
