import React from 'react';
import { SelectField } from 'evergreen-ui';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

const FormSelectField = ({ label, error, control, ...rest }) => (
    <Controller
        as={SelectField}
        label={label}
        control={control}
        isInvalid={Boolean(error)}
        validationMessage={error}
        {...rest}
    />
);

FormSelectField.propTypes = {
    label: PropTypes.string,
    control: PropTypes.any.isRequired,
    error: PropTypes.string,
};

FormSelectField.defaultProps = {
    label: '',
    error: null,
};

export default FormSelectField;
