import {
    Button,
    Dialog,
    Label,
    Pane,
    Switch,
    Tab,
    Tablist,
    toaster,
} from 'evergreen-ui';
import { equals, propOr } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { isNotEmptyOrNil } from '../../../../utils/common';
import {
    StateReducerProvider,
    useDispatchContext,
    useStateContext,
} from '../../../../context/createStateReducerContext';
import useTenant from '../../../../hooks/useTenant';

import {
    actions,
    addCredentialsToCollectors,
    initialState,
    reducer,
} from '../models/reducer';

import CollectorsSection from './CollectorsSection';
import DangerZoneSection from './DangerZoneSection';
import { ForwarderDestinationInput } from './DestinationInputs';
import InfrastructureSection from './InfrastructureSection';
import { ForwarderMetadataInputs } from './MetadataInputs';
import TLSSection from './TLSSection';
import { validateState } from './utils';
import {
    DEPLOYMENT_STATE_DEPLOYING,
    DEPLOYMENT_STATE_INSTALLING,
} from '../../../../utils/constants';
import useAuthContext from '../../../../hooks/useAuthContext';
import { isCyderesSuperuser } from '../../../../utils/auth';

const FlexBoxBtnContainer = styled.div`
    width: 100%;
    margin: 1vh 0;
    display: flex;
    justify-content: flex-end;
`;

const StyledTab = styled(Tab)`
    &:focus {
        box-shadow: none;
        outline: none;
    }
`;

const SubmitButton = styled(Button)`
    background: #265a7f;
    color: #fff;
    svg: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    &:hover, &:active {
        background: #517b98 !important; # not ideal
    }
`;

const Section = ({ isDisplayed, children }) => (
    <Pane display={isDisplayed ? 'block' : 'none'}>{children}</Pane>
);

function Form({
    deploymentState,
    isLoading,
    handleSubmit,
    handleRemove,
    handleClone,
    values,
}) {
    const userAuthContext = useAuthContext();
    const userRole = propOr({ role: '' }, 'data', userAuthContext);
    const isAdmin = isCyderesSuperuser(userRole);

    const [showMetadata, setShowMetadata] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [invalidTls, setInvalidTls] = useState(false);

    const showDialog = () => {
        let tlsInvalid = false;
        variables.collectors.forEach((x) => {
            if (
                x.syslog.tlsEnabled &&
                (!x.syslog.tlsRawCert || !x.syslog.tlsRawKey)
            ) {
                tlsInvalid = true;
            }
        });
        setInvalidTls(tlsInvalid);

        if (!tlsInvalid) {
            setDialogIsOpen(true);
        }
    };
    const hideDialog = () => setDialogIsOpen(false);
    const [tenant] = useTenant();
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const [tab, setTab] = useState(0);
    const tabEquals = equals(tab);
    const selectTab = (value) => () => setTab(value);

    const [variables, setVariables] = useState(
        addCredentialsToCollectors(state),
    );

    const handleSaveBtnOnClick = async (event) => {
        handleSubmit({
            variables: {
                id: variables.forwarderId,
                props: {
                    name: variables.name,
                    organizationId: tenant.id,
                    configuration: {
                        infrastructure: variables.infrastructure,
                        collectors: variables.collectors,
                        destinationRef: variables.destinationRef,
                        metadata: variables.metadata,
                    },
                },
            },
        });
    };

    const mapRemoteForwarderToState = useCallback(
        () =>
            dispatch({
                type: actions.MAP_REMOTE_FORWARDER_TO_STATE,
                payload: values,
            }),
        [values, dispatch],
    );

    useEffect(() => {
        setVariables(addCredentialsToCollectors(state));
    }, [state]);

    useEffect(() => {
        const resetState = () => dispatch({ type: actions.RESET_STATE });

        if (isNotEmptyOrNil(values)) {
            mapRemoteForwarderToState();
        }

        return () => resetState();
    }, [dispatch, values, mapRemoteForwarderToState]);

    return (
        <Pane data-testid="cdp-forwarder-form" padding="1rem">
            <Pane>
                {dialogIsOpen && (
                    <Pane>
                        <Dialog
                            isShown={dialogIsOpen}
                            title="Confirm Upgrade"
                            onCloseComplete={() => hideDialog()}
                            onConfirm={() => handleSaveBtnOnClick()}
                            confirmLabel="Confirm"
                        >
                            Once the forwarder upgrade has been submitted you
                            will be unable modify the forwarder until it returns
                            to a healthy state, this may take some time.
                        </Dialog>
                    </Pane>
                )}
            </Pane>
            <FlexBoxBtnContainer>
                <Button onClick={handleClone}>Clone</Button>
                {isAdmin && (
                    <SubmitButton
                        onClick={showDialog}
                        disabled={
                            isLoading ||
                            !state.collectors.length ||
                            !validateState(state)
                        }
                        isLoading={isLoading}
                        marginLeft="1rem"
                    >
                        Save
                    </SubmitButton>
                )}
                {!isAdmin && (
                    <SubmitButton
                        onClick={showDialog}
                        disabled={
                            deploymentState === DEPLOYMENT_STATE_INSTALLING ||
                            deploymentState === DEPLOYMENT_STATE_DEPLOYING ||
                            isLoading ||
                            !state.collectors.length ||
                            state.destinationRef < 1 ||
                            !validateState(state)
                        }
                        isLoading={isLoading}
                        marginLeft="1rem"
                    >
                        Save
                    </SubmitButton>
                )}
            </FlexBoxBtnContainer>
            <Pane>
                <Tablist borderBottom="1px solid black" marginBottom="1rem">
                    <StyledTab
                        isSelected={tabEquals(0)}
                        onSelect={selectTab(0)}
                    >
                        Data Collection
                    </StyledTab>
                    <StyledTab
                        isSelected={tabEquals(1)}
                        onSelect={selectTab(1)}
                    >
                        Infrastructure
                    </StyledTab>
                    <StyledTab
                        isSelected={tabEquals(2)}
                        onSelect={selectTab(2)}
                    >
                        Danger Zone
                    </StyledTab>
                </Tablist>
                <Section isDisplayed={tabEquals(0)}>
                    <Pane width="calc(50% - 0.75rem)">
                        <ForwarderDestinationInput />
                    </Pane>
                    <Pane display="flex" marginBottom="1rem">
                        <Switch
                            checked={showMetadata}
                            onChange={() => setShowMetadata(!showMetadata)}
                            marginRight="0.5rem"
                            id="metadata-switch"
                        />
                        <Label htmlFor="metadata-switch">
                            Configure Forwarder Metadata
                        </Label>
                    </Pane>
                    {showMetadata && <ForwarderMetadataInputs />}
                    <CollectorsSection />
                    <TLSSection invalidTls={invalidTls} />
                </Section>
                <Section isDisplayed={tabEquals(1)}>
                    <InfrastructureSection />
                </Section>
                <Section isDisplayed={tabEquals(2)}>
                    <DangerZoneSection handleRemove={handleRemove} />
                </Section>
            </Pane>
        </Pane>
    );
}

function CDPCyclopsForwarderForm(props) {
    return (
        <StateReducerProvider initialState={initialState} reducer={reducer}>
            <Form {...props} />
        </StateReducerProvider>
    );
}

export default CDPCyclopsForwarderForm;
