import { gql } from '@apollo/client';

export const CREATE_NEW_ORGANIZATION = gql`
    mutation createNewOrganization($props: CreateOrganizationInput!) {
        createOrganization(props: $props) {
            id
        }
    }
`;

export const QUERY_ORGANIZATION_NAMES = gql`
    query organizations {
        organizations {
            id
            name
        }
    }
`;
