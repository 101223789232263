import { useMutation, useQuery } from '@apollo/client';

import { anyIsLoading, isNotEmptyOrNil } from '../../../../utils/common';

import {
    DELETE_CHRONICLE_FORWARDER,
    QUERY_CHRONICLE_DEPLOYMENT,
    QUERY_CHRONICLE_FORWARDER,
    UPDATE_CHRONICLE_FORWARDER_CONFIG,
    UPGRADE_CHRONICLE_DEPLOYMENT,
    UNDEPLOY_CHORNICLE_APP,
} from './graphql';

const cacheRemove = (cache, response) => {
    const id = response.data.forwarder.remove;
    cache.evict({
        id: cache.identify({ id, __typename: 'Forwarder' }),
    });
};

export function useHealthCheck(id) {
    const query = useQuery(QUERY_CHRONICLE_DEPLOYMENT, {
        variables: { id },
        pollInterval: 20000,
    });
    return query;
}

export function useChronicleForwarder(id, deploymentId) {
    const [removeApp, removeAppResponse] = useMutation(UNDEPLOY_CHORNICLE_APP, {
        onError: () => {},
        variables: { id: deploymentId },
    });
    const [remove, removeResponse] = useMutation(DELETE_CHRONICLE_FORWARDER, {
        variables: { id },
        onError: () => {},
        onCompleted: () => removeApp(),
        update: cacheRemove,
    });
    const [upgradeApp, upgradeAppResponse] = useMutation(
        UPGRADE_CHRONICLE_DEPLOYMENT,
        {
            onError: () => {},
            variables: { id: deploymentId },
        },
    );
    const [update, updateConfigResponse] = useMutation(
        UPDATE_CHRONICLE_FORWARDER_CONFIG,
        {
            onError: () => {},
            onCompleted: () => upgradeApp(),
        },
    );
    const query = useQuery(QUERY_CHRONICLE_FORWARDER, {
        variables: { id },
        skip: !id,
    });

    const meta = {
        loading: anyIsLoading([
            query,
            removeAppResponse,
            removeResponse,
            upgradeAppResponse,
            updateConfigResponse,
        ]),
        errors: [
            query.error,
            removeAppResponse.error,
            removeResponse.error,
            upgradeAppResponse.error,
            updateConfigResponse.error,
        ].filter(isNotEmptyOrNil),
        success: {
            remove: !!removeResponse.data && !!removeAppResponse.data,
            update: !!upgradeAppResponse?.data && !!updateConfigResponse?.data,
        },
    };

    const models = {
        forwarder:
            updateConfigResponse?.data?.forwarder?.update ||
            query?.data?.forwarder,
    };

    const operations = {
        remove,
        update,
    };

    return { meta, models, operations };
}
