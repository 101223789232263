import { gql } from '@apollo/client';

export const QUERY_CHRONICLE_FW_IDENTITY = gql`
    query chronicleFwIdentity($id: ID!) {
        chronicleForwarderIdentity(id: $id) {
            id
            name
            createdAt
            createdBy
            updatedAt
            updatedBy
            collectorId
            customerId
            secretKey {
                type
                projectId
                privateKeyId
                privateKey
                clientEmail
                clientId
                authUri
                tokenUri
                authProviderCertUrl
                clientCertUrl
            }
            organization {
                id
                name
            }
        }
    }
`;

export const UPDATE_CHRONICLE_FW_IDENTITY = gql`
    mutation updateChronicleFwIdentity(
        $id: ID!
        $props: UpdateChronicleForwarderIdentityInput!
    ) {
        chronicleForwarderIdentity(id: $id) {
            update(props: $props) {
                id
                name
                createdAt
                createdBy
                updatedAt
                updatedBy
                collectorId
                customerId
                secretKey {
                    type
                    projectId
                    privateKeyId
                    privateKey
                    clientEmail
                    clientId
                    authUri
                    tokenUri
                    authProviderCertUrl
                    clientCertUrl
                }
            }
        }
    }
`;

export const DELETE_CHRONICLE_FW_IDENTITY = gql`
    mutation deleteChronicleFwIdentity($id: ID!) {
        chronicleForwarderIdentity(id: $id) {
            remove
        }
    }
`;
