import React, { useState } from 'react';
import { Button, Heading, Pane, toaster } from 'evergreen-ui';

import OrganizationForm from '../../../components/OrganizationForm';
import { useCreateNewOrganization } from './CreateNewOrganization-hooks';
import PageContainer from '../../../components/PageContainer';

function CreateNewOrganization() {
    const [name, setName] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [chronicleURL, setChronicleURL] = useState('');
    const [canaryURL, setCanaryURL] = useState('');
    const [kibanaSpaceIdentifier, setKibanaSpaceIdentifier] = useState('');
    const [authGroupName, setAuthGroupName] = useState('');
    const [lookerModel, setLookerModel] = useState('');

    const [mutate, response, orgNameData] = useCreateNewOrganization({
        name,
        contactName,
        contactEmail,
        contactPhone,
        chronicleURL,
        canaryURL,
        kibanaSpaceIdentifier,
        authGroupName,
        lookerModel,
    });

    const submitForm = () => {
        if (orgNameData.includes(name.toLowerCase())) {
            toaster.danger('ERROR: Organization already exists.');
        } else {
            mutate();
        }
    };

    return (
        <PageContainer>
            <Pane
                width="100%"
                display="flex"
                justifyContent="space-between"
                marginBottom="2vh"
            >
                <Heading size={700}>Create An Organization</Heading>
                <Button
                    appearance="primary"
                    intent="success"
                    height={36}
                    onClick={submitForm}
                    isLoading={response.loading}
                    disabled={!name}
                >
                    Save
                </Button>
            </Pane>
            <OrganizationForm
                name={name}
                setName={setName}
                contactName={contactName}
                setContactName={setContactName}
                contactEmail={contactEmail}
                setContactEmail={setContactEmail}
                contactPhone={contactPhone}
                setContactPhone={setContactPhone}
                chronicleURL={chronicleURL}
                setChronicleURL={setChronicleURL}
                canaryURL={canaryURL}
                setCanaryURL={setCanaryURL}
                kibanaSpaceIdentifier={kibanaSpaceIdentifier}
                setKibanaSpaceIdentifier={setKibanaSpaceIdentifier}
                authGroupName={authGroupName}
                setAuthGroupName={setAuthGroupName}
                lookerModel={lookerModel}
                setLookerModel={setLookerModel}
            />
        </PageContainer>
    );
}

export default CreateNewOrganization;
