/**
 * create a functional reducer
 * @param {*} initialState
 * @param {*} handlers must be able to invoke handlers, i.e. they must be functions
 */
const createReducer = (initialState, handlers) => {
    const handleReducer = (state = initialState, action) => {
        const handler = handlers[action.type];
        if (handler) {
            return handler(state, action);
        }

        return state;
    };

    return handleReducer;
};

export { createReducer };
