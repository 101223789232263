import React from 'react';
import { Button, Pane } from 'evergreen-ui';
import { propOr } from 'ramda';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import HandleQueryResponse from '../../../components/HandleQueryResponse';
import PageContainer from '../../../components/PageContainer';
import SearchableTable from '../../../components/SearchableTable';
import useTenant from '../../../hooks/useTenant';
import withRequireTenant from '../../../hocs/withRequireTenant';
import { isEmptyOrNil } from '../../../utils/common';

import columns from './columns';
import { useDeployments, useOrgDeployments } from './hooks';

const ButtonContainer = styled(Pane)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2vh;
`;

const Table = ({ data, ...rest }) => {
    return (
        <Pane {...rest}>
            <ButtonContainer>
                <Link to="/admin/forwarders/new">
                    <Button iconBefore="plus">New</Button>
                </Link>
            </ButtonContainer>
            <SearchableTable columns={columns} data={data} allowSearch />
        </Pane>
    );
};

const TenantForwardersTable = ({ id }) => {
    const response = useOrgDeployments({
        id,
    });
    const data = propOr([], 'data', response);

    return (
        <PageContainer>
            <HandleQueryResponse response={response}>
                <Table
                    key="tenant-forwarders-table"
                    data-testid="tenant-forwarders-table"
                    data={data}
                />
            </HandleQueryResponse>
        </PageContainer>
    );
};

// const AllForwardersTable = () => {
//     const response = useDeployments({});
//     const data = propOr([], 'data', response);

//     return (
//         <PageContainer>
//             <HandleQueryResponse response={response}>
//                 <Table
//                     key="all-forwarders-table"
//                     data-testid="all-forwarders-table"
//                     data={data}
//                 />
//             </HandleQueryResponse>
//         </PageContainer>
//     );
// };

function ForwardersTable() {
    const [tenant] = useTenant();
    const id = propOr('', 'id', tenant);

    // if (isEmptyOrNil(tenant)) {
    //     return <AllForwardersTable />;
    // }

    return <TenantForwardersTable id={id} />;
}

export { Table };
export default withRequireTenant(ForwardersTable);
