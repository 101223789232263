import { useMutation, gql } from '@apollo/client';
import { equals, pluck } from 'ramda';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Button, TextInput, toaster } from 'evergreen-ui';

import ErrorAlert from '../../../components/ErrorAlert';
import LoadingSpinner from '../../../components/LoadingSpinner';
import useAbility from '../../../hooks/useAbility';
import useTenant from '../../../hooks/useTenant';
import { CREATE_ANY_CHRONICLE_FW_IDENTITY } from '../../../system/abilities';
import {
    hasError,
    isEmptyOrNil,
    isNotEmptyOrNil,
    isLoading,
    withEventTargetAsValue,
} from '../../../utils/common';
import { useQueryAllForwarderIdentities } from '../ForwarderIdentities/ForwarderIdentities-hooks';

const CREATE_CHRONICLE_FW_IDENTITY = gql`
    mutation createChronicleForwarderIdentity(
        $props: CreateChronicleForwarderIdentityInput!
    ) {
        createChronicleForwarderIdentity(props: $props) {
            id
        }
    }
`;

const Container = styled.div`
    width: 100%;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StyledTextInput = styled(TextInput)`
    height: 45px;
    min-width: 300px;
    font-size: 1.2rem;
    margin-bottom: 1rem;
`;

const ABILITY_ERROR_MESSAGE = 'There was an error validating user permissions.';
const PERMISSION_DENIED_MESSAGE =
    'Permission denied. To request access open a ticket with CYDERES Engineering.';

const initialValues = {
    collectorId: '',
    customerId: '',
    secretKey: {
        type: 'service_account',
        projectId: '',
        privateKeyId: '',
        privateKey: '',
        clientEmail: '',
        clientId: '',
        authUri: '',
        tokenUri: '',
        authProviderCertUrl: '',
        clientCertUrl: '',
    },
};

function CreateTenantChronicleForwarderIdentity({
    tenantId,
    tenantName,
    defaultValues = initialValues,
}) {
    const [name, setName] = useState('');

    const variables = {
        props: {
            ...defaultValues,
            name,
            organizationId: tenantId,
        },
    };

    const [createChronicleFwIdentity, { data, loading, error }] = useMutation(
        CREATE_CHRONICLE_FW_IDENTITY,
        {
            variables,
            onError: () => {},
        },
    );

    const {
        data: queryData,
        loading: queryLoading,
        error: queryError,
    } = useQueryAllForwarderIdentities();

    const existingIdKeyNames = pluck('name', queryData);

    const tryCreateChronicleFwIdentity = () => {
        if (existingIdKeyNames.includes(name))
            toaster.danger(
                'That forwarder identity already exists.  Please enter a new name and try again.',
            );
        else {
            createChronicleFwIdentity();
        }
    };

    if (loading || queryLoading) {
        return <LoadingSpinner />;
    }

    if (error || queryError) {
        return <ErrorAlert />;
    }

    if (isNotEmptyOrNil(data)) {
        const identityKey = data.createChronicleForwarderIdentity.id;
        return (
            <Redirect to={`/admin/pipeline-secrets/chronicle/${identityKey}`} />
        );
    }

    return (
        <Container>
            <h1>Enter a unique name for referencing these keys.</h1>
            <StyledTextInput
                value={name}
                onChange={withEventTargetAsValue(setName)}
            />
            <Button
                height={48}
                onClick={tryCreateChronicleFwIdentity}
                disabled={isEmptyOrNil(name)}
            >
                Create Chronicle Identity Keys For {tenantName}
            </Button>
        </Container>
    );
}

function CreateChronicleForwarderIdentity() {
    const [tenant] = useTenant();
    const canAnyCreateChronicleIdentitiesResponse = useAbility(
        CREATE_ANY_CHRONICLE_FW_IDENTITY,
    );

    const hasPermission = equals(
        true,
        canAnyCreateChronicleIdentitiesResponse.data,
    );

    if (isEmptyOrNil(tenant)) {
        return <Redirect to="/admin/pipeline-secrets/new" />;
    }

    if (isLoading(canAnyCreateChronicleIdentitiesResponse)) {
        return <LoadingSpinner />;
    }

    if (hasError(canAnyCreateChronicleIdentitiesResponse)) {
        const _error = {
            message: ABILITY_ERROR_MESSAGE,
        };
        return <ErrorAlert error={_error} />;
    }

    if (isNotEmptyOrNil(tenant)) {
        if (!hasPermission) {
            const _error = {
                message: PERMISSION_DENIED_MESSAGE,
            };
            return <ErrorAlert error={_error} />;
        }
    }

    return (
        <CreateTenantChronicleForwarderIdentity
            tenantId={tenant.id}
            tenantName={tenant.name}
        />
    );
}

export { CreateTenantChronicleForwarderIdentity };
export default CreateChronicleForwarderIdentity;
