import React, { useState } from 'react';

import PageContainer from '../../../components/PageContainer';
import TabbedSections from '../../../components/TabbedSections';
import withRequireTenant from '../../../hocs/withRequireTenant';
import CloudDataSourcesTable from '../CloudDataSources';
import CyclopsDataSourcesTable from '../CyclopsDataSources';

function DataSourcesDashboardTabbedSections() {
    const [selectedTab, setSelectedTab] = useState(0);

    const sections = [
        {
            label: 'Cyclops Forwarders',
            value: 0,
            children: <CyclopsDataSourcesTable />,
        },
        {
            label: 'Cloud Forwarders',
            value: 1,
            children: <CloudDataSourcesTable />,
        },
    ];

    return (
        <PageContainer enableMargins>
            <TabbedSections
                sections={sections}
                selected={selectedTab}
                setSelected={setSelectedTab}
            />
        </PageContainer>
    );
}

export default withRequireTenant(DataSourcesDashboardTabbedSections);
