import DataSourcesTable from '../admin-portal/datasources/DataSourcesTable';
import DataSourceTable from '../admin-portal/datasources/DataSourcesCommon/DataSourceTable';
import { baseAdminPath } from './home-routes';

const dataSourceBaseAdminPath = `${baseAdminPath}/datasources`;

const routes = {};

routes.DataSourcesOrgAdminDashboard = {
    path: dataSourceBaseAdminPath,
    exact: true,
    admin: true,
    secure: true,
    component: DataSourcesTable,
};
routes.DataSourceAdminDashboard = {
    path: `${dataSourceBaseAdminPath}/:forwarderType/:dataSource`,
    exact: true,
    admin: true,
    secure: true,
    component: DataSourceTable,
};

export default routes;
