export const PORT_MIN = 30000;
export const PORT_MAX = 32767;

export const rfc1123DnsRegex = () =>
    /^(?![0-9]+$)(?!.*-$)(?!-)[a-z0-9-]{1,63}$/;
export const noUniqueCharsRegex = () =>
    /^(?!.*-$|.*\s$)(?!-|\s)[A-Za-z0-9- ]{1,63}$/;

export const validateMetadata = (metadata) => {
    const namespace = metadata?.namespace || '';
    const labels = metadata?.labels || [];

    const namespaceIsValid = namespace
        ? rfc1123DnsRegex().test(namespace)
        : true;

    const labelsAreValid = labels.reduce((prev, current) => {
        if (!prev) return false;
        const keyIsValid = rfc1123DnsRegex().test(current.key);
        const valueIsValid = noUniqueCharsRegex().test(current.value);
        return keyIsValid && valueIsValid;
    }, true);

    return namespaceIsValid && labelsAreValid;
};

export const validateCommonFields = (common) => {
    const commentIsValid = common?.comment
        ? noUniqueCharsRegex().test(common.comment)
        : true;
    return (
        commentIsValid &&
        Boolean(common.dataType) &&
        validateMetadata(common.metadata)
    );
};

export const validatePcapFields = (pcap) =>
    Boolean(pcap.bpf) && Boolean(pcap.interface);

export const validateSplunkFields = (splunk) =>
    Boolean(splunk.queryString) && Boolean(splunk.url);

export const validateSyslogFields = (syslog, collectors) => {
    const portIsValid =
        syslog.port && syslog.port >= PORT_MIN && syslog.port <= PORT_MAX;

    const portIsUnique =
        collectors.filter((collector) => collector.syslog.port === syslog.port)
            .length === 1;

    return portIsValid && portIsUnique;
};

export const validatePcapState = (state) => {
    let collectorsArValid = true;
    state.collectors.forEach((collector) => {
        const valid =
            validatePcapFields(collector.pcap) &&
            validateCommonFields(collector.common);
        if (!valid) collectorsArValid = false;
    });
    return validateMetadata(state.metadata) && collectorsArValid;
};

export const validateSplunkState = (state) => {
    let collectorsArValid = true;
    state.collectors.forEach((collector) => {
        const valid =
            validateSplunkFields(collector.splunk) &&
            validateCommonFields(collector.common);
        if (!valid) collectorsArValid = false;
    });
    return (
        Boolean(state.splunkUsername) &&
        Boolean(state.splunkPassword) &&
        validateMetadata(state.metadata) &&
        collectorsArValid
    );
};

export const validateSyslogState = (state) => {
    let collectorsArValid = true;
    state.collectors.forEach((collector) => {
        const valid =
            validateSyslogFields(collector.syslog, state.collectors) &&
            validateCommonFields(collector.common);
        if (!valid) collectorsArValid = false;
    });
    return validateMetadata(state.metadata) && collectorsArValid;
};

export const validateState = (state) => {
    if (state.collectorType === 'pcap') return validatePcapState(state);
    if (state.collectorType === 'splunk') return validateSplunkState(state);
    if (state.collectorType === 'syslog') return validateSyslogState(state);
    return false;
};
