import { always, flatten, ifElse, prop, propOr } from 'ramda';

import { QUERY_TENANT_FORWARDER_IDENTITIES } from '../ForwarderIdentities-graphql';
import useQuerySelector from '../../../../hooks/useQuerySelector';
import { isEmptyOrNil } from '../../../../utils/common';

const MISSING_ID_ERROR_MESSAGE =
    'useQueryAllTenantForwarderIdentities was not called with an id';

function useQueryAllTenantForwarderIdentities(id) {
    const queryVariables = {
        variables: { id },
    };
    const { data, loading, error } = useQuerySelector(
        QUERY_TENANT_FORWARDER_IDENTITIES,
        queryVariables,
        prop('organization'),
    );
    const cdpForwarderIdentities = propOr([], 'cdpForwarderIdentities', data);
    const chronicleForwarderIdentities = propOr(
        [],
        'chronicleForwarderIdentities',
        data,
    );

    const checkInvocationError = ifElse(
        isEmptyOrNil,
        always({ message: MISSING_ID_ERROR_MESSAGE }),
        always(error),
    );

    const _error = checkInvocationError(id);

    let _data = [];
    if (data) {
        _data = flatten([cdpForwarderIdentities, chronicleForwarderIdentities]);
    }

    const response = {
        loading,
        error: _error,
        data: _data,
    };

    return response;
}

export { MISSING_ID_ERROR_MESSAGE };
export default useQueryAllTenantForwarderIdentities;
