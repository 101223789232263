import { gql } from '@apollo/client';

export const CLOUD_SPLUNK_FW_FRAGMENT = gql`
    fragment CloudSplunkFwFragment on ChronicleCloudSplunkForwarder {
        configuration {
            client
            splunk {
                common {
                    batchEveryBytes
                    batchEverySeconds
                    dataType
                    enabled
                }
                ignoreCerts
                password
                queryString
                url
                username
            }
            infrastructure {
                autoscaling {
                    enabled
                }
                hostname
                replicaCount
                resources {
                    limits {
                        cpu
                        memory
                    }
                    requests {
                        cpu
                        memory
                    }
                }
                serviceType
                workerCount
            }
        }
        kubernetesService
        identityId
        organizationId
        name
    }
`;

export const CREATE_SPLUNK_CLOUD_FW = gql`
    mutation createSplunkCloudFw($props: ChronicleCloudSplunkForwarderInput!) {
        createChronicleCloudSplunkForwarder(props: $props) {
            id
            ...CloudSplunkFwFragment
        }
    }
    ${CLOUD_SPLUNK_FW_FRAGMENT}
`;

export const REMOVE_SPLUNK_CLOUD_FW = gql`
    mutation removeSplunkCloudFw($id: ID!) {
        removeChronicleCloudSplunkForwarder(id: $id)
    }
`;

export const UPDATE_SPLUNK_CLOUD_FW = gql`
    mutation updateSplunkCloudFw(
        $id: ID!
        $props: ChronicleCloudSplunkForwarderInput!
    ) {
        updateChronicleCloudSplunkForwarder(id: $id, props: $props) {
            id
            ...CloudSplunkFwFragment
        }
    }
    ${CLOUD_SPLUNK_FW_FRAGMENT}
`;

export const QUERY_SPLUNK_CLOUD_FW = gql`
    query splunkCloudFw($id: ID!) {
        chronicleCloudSplunkForwarder(id: $id) {
            id
            ...CloudSplunkFwFragment
        }
    }
    ${CLOUD_SPLUNK_FW_FRAGMENT}
`;
