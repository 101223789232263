export const COMMENTS_REGEX = /^[A-Za-z0-9 ]+$/;
export const METADATA_REGEX = /^(?![0-9]+$)(?!.*-$)(?!-)[a-z0-9-]{1,63}$/;

export const PORT_MIN = 30000;
export const PORT_MAX = 32767;

export const rfc1123DnsRegex = () =>
    /^(?![0-9]+$)(?!.*-$)(?!-)[a-z0-9-]{1,63}$/;
export const noUniqueCharsRegex = () =>
    /^(?!.*-$|.*\s$)(?!-|\s)[A-Za-z0-9- ]{1,63}$/;

export const validateMetadata = (metadata) => {
    const namespace = metadata?.namespace || '';
    const labels = metadata?.labels || [];

    const namespaceIsValid = namespace
        ? rfc1123DnsRegex().test(namespace)
        : true;

    const labelsAreValid = labels.reduce((prev, current) => {
        if (!prev) return false;
        const keyIsValid = rfc1123DnsRegex().test(current.key);
        const valueIsValid = noUniqueCharsRegex().test(current.value);
        return keyIsValid && valueIsValid;
    }, true);

    return namespaceIsValid && labelsAreValid;
};

export const validateCollector = ({ common, syslog }, collectors) => {
    const metadataIsValid = validateMetadata(common.metadata);
    const portIsValid =
        syslog.port && syslog.port >= PORT_MIN && syslog.port <= PORT_MAX;
    const portIsUnique =
        collectors.filter((collector) => collector.syslog.port === syslog.port)
            .length === 1;
    const hasDataType = Boolean(common.dataType);
    const commentIsValid = common.comment
        ? noUniqueCharsRegex().test(common.comment)
        : true;
    return (
        metadataIsValid &&
        portIsValid &&
        hasDataType &&
        commentIsValid &&
        portIsUnique
    );
};

export const validateState = (state) => {
    let collectorsInputsAreValid = true;
    state.collectors.forEach((collector) => {
        const valid = validateCollector(collector, state.collectors);
        if (!valid) collectorsInputsAreValid = false;
    });
    const metadataIsValid = validateMetadata(state.metadata);
    return collectorsInputsAreValid && metadataIsValid;
};
