import {
    both,
    equals,
    either,
    includes,
    is,
    or,
    pathOr,
    propSatisfies,
} from 'ramda';

const isArray = is(Array);
const isCyderesAll = both(isArray, includes('Cyderes-All'));
const isWizardInternal = both(isArray, includes('wizard-internal'));

export const isInternalUser = either(
    propSatisfies(isCyderesAll, 'groups'),
    propSatisfies(isWizardInternal, 'groups'),
);

export const isSuperuser = equals('Superuser');
export const isInternalAdmin = equals('InternalAdmin');
export const isCyderesAdmin = propSatisfies(isInternalAdmin, 'role');
export const isCyderesSuperuser = propSatisfies(isSuperuser, 'role');
export const isSuperUserOrAdmin = either(isCyderesAdmin, isCyderesSuperuser);

const isOrganizationUser = includes('OrganizationUser');
const isOrganizationAdmin = includes('OrganizationAdmin');
const isOrganizationUserOrAdmin = or(isOrganizationUser, isOrganizationAdmin);
export const isAuthedOrganizationUserOrAdmin = propSatisfies(
    isOrganizationUserOrAdmin,
    'role',
);

export const parseIdToken = pathOr(null, ['authState', 'idToken', 'idToken']);
