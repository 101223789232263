import React from 'react';
import { Pane, Text, TextInputField } from 'evergreen-ui';
import { equals } from 'ramda';
import styled from 'styled-components';

import ActionButton from '../../../../components/ActionButton';
import { useStateContext } from '../../../../context/createStateReducerContext';

const InputContainer = styled(Pane)`
    border: 1px solid #ef5350;
    padding: 2rem;
`;

const DangerZoneSection = ({ handleRemove }) => {
    const state = useStateContext();
    const [value, setValue] = React.useState('');
    const setInputValue = (e) => setValue(e.target.value);

    const disabled = !equals(value, state.name);

    return (
        <Pane>
            <Pane marginBottom="1rem">
                <Text>
                    To delete this forwarder confirm the name in the input
                    below.
                </Text>
            </Pane>
            <InputContainer>
                <TextInputField
                    label={`Enter Value To Delete: ${state.name}`}
                    value={value}
                    onChange={setInputValue}
                />
                <ActionButton
                    appearance="primary"
                    disabled={disabled}
                    intent="danger"
                    onClick={handleRemove}
                >
                    Delete
                </ActionButton>
            </InputContainer>
        </Pane>
    );
};

export default DangerZoneSection;
