import { Alert } from 'evergreen-ui';
import { propOr } from 'ramda';
import React from 'react';

import LoadingSpinner from '../../components/LoadingSpinner';
import useAuthContext from '../../hooks/useAuthContext';
import { isLoading, hasError } from '../../utils/common';
import { isSuperUserOrAdmin } from '../../utils/auth';

const withRequireAdmin = (Component) => (props) => {
    const response = useAuthContext();

    const user = propOr({ role: '' }, 'data', response);
    const isAdmin = isSuperUserOrAdmin(user);

    if (isLoading(response)) {
        return <LoadingSpinner />;
    }

    if (hasError(response) || !isAdmin) {
        return (
            <Alert title="ACCESS DENIED" intent="warning">
                You do not have the correct permissions to view this content.
            </Alert>
        );
    }

    return <Component {...props} />;
};

export default withRequireAdmin;
