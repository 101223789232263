import { defaultTo, path, prop, propOr } from 'ramda';
import React from 'react';
import { useHistory } from 'react-router-dom';

import HandleQueryResponse from '../../../components/HandleQueryResponse';
import SearchableTable from '../../../components/SearchableTable';
import useTenant from '../../../hooks/useTenant';
import { sortByProp } from '../../../utils/common';
import { buildDataSourcesColumns } from '../DataSourcesCommon/ForwarderDataSourcesTable-columns';
import useCloudForwarders from './CloudDataSourcesTable-hooks';
import { resolveCloudCollectorResponse } from './CloudDataSourcesTable-resolver';

const CloudDataSourcesTable = () => {
    const [tenant] = useTenant();
    const tenantId = prop('id', tenant);
    const defaultToEmpty = defaultTo('');
    const id = defaultToEmpty(tenantId);
    const history = useHistory();
    const route = path(['location', 'pathname'], history);
    const queryOrgCloudForwarders = useCloudForwarders({
        id,
    });
    const collectors = propOr([], 'data', queryOrgCloudForwarders);
    const resolvedCollectors = resolveCloudCollectorResponse(collectors);
    const columns = buildDataSourcesColumns(`${route}/cloud`);

    return (
        <HandleQueryResponse response={queryOrgCloudForwarders}>
            <SearchableTable
                columns={columns}
                data={sortByProp('name', resolvedCollectors)}
                data-testid="cloudDataSources-table"
                allowSearch
            />
        </HandleQueryResponse>
    );
};

export default CloudDataSourcesTable;
