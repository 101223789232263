/* istanbul ignore file */

import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

/**
 * deprecated routes
 * see util function buildRedirectRoute in ./utils.js
 * there is no harm in keeping the redirects indefinitely but
 * in order to avoid an ever growing list add a date comment for each new block of
 * routes and every so often we can review and remove
 * @key old String - the old path
 * @key redirect String - the new path
 * @key component React.Component - returns Redirect component use if you need route params in the new path
 */
const routes = {
    // legacy routes 12/11/20
    '/app/admin/me': {
        old: '/app/admin/me',
        redirect: '/admin/help/admin-token',
    },
    '/app/admin/onboarding': {
        old: '/app/admin/onboarding',
        redirect: '/admin/organizations',
    },
    '/app/admin/clusters': {
        old: '/app/admin/clusters',
        redirect: '/admin/clusters',
    },
    '/app/admin/organizations': {
        old: '/app/admin/organizations',
        redirect: '/admin/organizations',
    },
    '/app/admin/organizations/:id': {
        old: '/app/admin/organizations/:id',
        redirect: '/admin/organizations',
    },
    '/app/help/technologies': {
        old: '/app/help/technologies',
        redirect: '/admin/help/technologies',
    },
    '//admin': {
        old: '//admin',
        redirect: '/admin',
    },
    '//admin/forwarders': {
        old: '//admin/forwarders',
        redirect: '/admin/forwarders',
    },
    '//admin/forwarders/new': {
        old: '//admin/forwarders/new',
        redirect: '/admin/forwarders/new',
    },
    '//admin/forwarders/cdp/:organizationId/:deploymentId/:forwarderId': {
        old:
            '//admin/forwarders/cdp/:organizationId/:deploymentId/:forwarderId',
        component: function Component() {
            const { organizationId, deploymentId, forwarderId } = useParams();
            const path = `/admin/forwarders/cdp/${organizationId}/${deploymentId}/${forwarderId}`;
            return <Redirect to={path} />;
        },
    },
    '//admin/forwarders/chronicle/:organizationId/:deploymentId/:forwarderId': {
        old:
            '//admin/forwarders/chronicle/:organizationId/:deploymentId/:forwarderId',
        component: function Component() {
            const { organizationId, deploymentId, forwarderId } = useParams();
            const path = `/admin/forwarders/chronicle/${organizationId}/${deploymentId}/${forwarderId}`;
            return <Redirect to={path} />;
        },
    },
    '//admin/pipeline-secrets': {
        old: '//admin/pipeline-secrets',
        redirect: '/admin/pipeline-secrets',
    },
    '//admin/clusters': {
        old: '//admin/clusters',
        redirect: '/admin/clusters',
    },
    '//admin/organizations': {
        old: '//admin/organizations',
        redirect: '/admin/organizations',
    },
};

export default routes;
