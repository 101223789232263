import { curry } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import {
    Combobox,
    Heading,
    Label,
    Pane,
    PlusIcon,
    Switch,
    TextInput,
    TextareaField,
    TrashIcon,
} from 'evergreen-ui';

import ActionButton from '../../../../components/ActionButton';
import {
    useStateContext,
    useDispatchContext,
} from '../../../../context/createStateReducerContext';
import useFetchDataTypes from '../../../../hooks/useFetchDataTypes';
import {
    withEventTargetAsNumber,
    withEventTargetAsValue,
} from '../../../../utils/common';
import { actions } from '../models/reducer';
import { CollectorMetadataInputs } from './MetadataInputs';
import { noUniqueCharsRegex, PORT_MIN, PORT_MAX } from './utils';

const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justify || 'space-between'};
    align-items: ${(props) => props.align};
    margin: ${(props) => props.margin || 0};
    width: ${(props) => props.width || '100%'};
`;
const FlexSection = styled.div`
    width: 47%;
`;

function SyslogCollectorInputs({
    collector,
    updateDataType,
    updatePortValue,
    updateCommentValue,
    updateTlsEnabledValue,
    handleRemove,
}) {
    const state = useStateContext();
    const [showMetadata, setShowMetadata] = React.useState(false);
    const portIsSet = React.useRef(!!collector.syslog.port);

    const dataTypes = useFetchDataTypes();
    const { common, syslog } = collector;
    const { comment, dataType } = common;
    const { port, tlsEnabled } = syslog;

    const commentValid =
        noUniqueCharsRegex().test(comment) ||
        common.comment.length === 0 ||
        !common.comment;

    const portIsUnique =
        state.collectors.filter((collector) => collector?.syslog?.port === port)
            .length === 1;

    const portIsInValidRange = port >= PORT_MIN && port <= PORT_MAX;

    const handleSwitchTlsEnabled = () => updateTlsEnabledValue(!tlsEnabled);

    return (
        <Pane
            key={collector.tagId}
            data-testid="table-form-row"
            borderBottom="1px solid #d8dae5"
            paddingTop="1.25rem"
            paddingBottom="1.25rem"
        >
            <Pane display="flex">
                <Pane width="25%">
                    <Label display="block" marginBottom="0.25rem">
                        Data Type
                    </Label>
                    <Combobox
                        width="calc(100% - 0.5rem)"
                        openOnFocus
                        items={dataTypes}
                        value={dataType}
                        isInvalid={!common.dataType}
                        initialSelectedItem={dataType}
                        onChange={updateDataType}
                        border={!common.dataType && '1px solid #D14343'}
                        borderRadius="5.5px"
                    />
                    {!common.dataType && (
                        <Label display="block" color="#D14343">
                            Required
                        </Label>
                    )}
                </Pane>
                <Pane width="25%">
                    <Label display="block" marginBottom="0.25rem">
                        Port
                    </Label>
                    <TextInput
                        width="calc(100% - 0.5rem)"
                        value={port}
                        onChange={updatePortValue}
                        readOnly={portIsSet.current}
                        isInvalid={
                            !port ||
                            !portIsInValidRange ||
                            (!portIsSet.current && !portIsUnique)
                        }
                        required
                    />
                    {(!portIsUnique || !portIsInValidRange || !port) && (
                        <Label
                            display="block"
                            marginBottom="0.25rem"
                            color="#D14343"
                        >
                            {!port && 'Required'}
                            {!portIsUnique &&
                                !portIsSet.current &&
                                'Port numbers must be unique'}
                            {!portIsInValidRange &&
                                Boolean(port) &&
                                'Port numbers must be between 30000 and 32767'}
                        </Label>
                    )}
                </Pane>
                <Pane width="22.5%">
                    <Label display="block" marginBottom="0.25rem">
                        Comment
                    </Label>
                    <TextInput
                        width="85%"
                        value={common.comment}
                        onChange={updateCommentValue}
                        isInvalid={!commentValid}
                        required
                    />
                    {!commentValid && (
                        <Label display="block" color="#D14343">
                            No special characters
                        </Label>
                    )}
                </Pane>
                <Pane width="10%" marginLeft="1rem">
                    <Label display="block" marginBottom="0.25rem">
                        TLS
                    </Label>
                    <Switch
                        display="block"
                        checked={tlsEnabled}
                        onChange={handleSwitchTlsEnabled}
                        marginTop="6%"
                    />
                </Pane>

                <Pane
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="1rem"
                >
                    <ActionButton
                        appearance="primary"
                        data-testid="delete-collector-btn"
                        icon={<TrashIcon color="#fff" />}
                        intent="danger"
                        height={40}
                        onClick={handleRemove}
                        shadow="show"
                        shape="circle"
                        type="icon"
                    />
                </Pane>
            </Pane>
            <Pane display="flex">
                <Pane width="25%">
                    <Pane
                        display="flex"
                        marginTop={commentValid ? '1rem' : '2rem'}
                    >
                        <Switch
                            checked={showMetadata}
                            onChange={() => setShowMetadata(!showMetadata)}
                            marginRight="0.5rem"
                            id={`switch-${collector.tagId}`}
                            marginLeft="0.175rem"
                        />
                        <Label htmlFor={`switch-${collector.tagId}`}>
                            Configure Metadata
                        </Label>
                    </Pane>
                </Pane>
            </Pane>
            <Pane>
                {showMetadata && (
                    <CollectorMetadataInputs collector={collector} />
                )}
            </Pane>
        </Pane>
    );
}

function SyslogCollectorsSection({ invalidTls, showFormFields }) {
    const state = useStateContext();
    const dispatch = useDispatchContext();

    const updateState = curry((collector, actionType, value) =>
        dispatch({ type: actionType, payload: { collector, value } }),
    );
    const insertCollectorInState = updateState(
        null,
        actions.INSERT_SYSLOG_COLLECTOR,
    );
    const handleUpdateTlsCert = (event) =>
        dispatch({
            type: actions.UPDATE_TLS_CERT,
            payload: event.target.value,
        });
    const handleUpdateTlsKey = (event) =>
        dispatch({ type: actions.UPDATE_TLS_KEY, payload: event.target.value });

    return (
        <>
            <Pane data-testid="syslog-config-form">
                <Heading size={700} marginBottom="1rem">
                    Data Sources
                </Heading>
                <Pane>
                    {state.collectors.map((collector) => {
                        const updateCollectorValueInState = updateState(
                            collector,
                        );
                        const handleRemove = updateCollectorValueInState(
                            actions.REMOVE_COLLECTOR,
                        );
                        const updateDataType = updateCollectorValueInState(
                            actions.UPDATE_COMMON_COLLECTOR_DATA_TYPE,
                        );
                        const updatePortValue = withEventTargetAsNumber(
                            updateCollectorValueInState(
                                actions.UPDATE_SYSLOG_COLLECTOR_PORT,
                            ),
                        );
                        const updateCommentValue = withEventTargetAsValue(
                            updateCollectorValueInState(
                                actions.UPDATE_COMMON_COLLECTOR_COMMENT,
                            ),
                        );
                        const updateCollectorTlsEnabledValue = updateCollectorValueInState(
                            actions.UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED,
                        );

                        return (
                            <SyslogCollectorInputs
                                collector={collector}
                                handleRemove={handleRemove}
                                updateDataType={updateDataType}
                                updatePortValue={updatePortValue}
                                updateCommentValue={updateCommentValue}
                                updateTlsEnabledValue={
                                    updateCollectorTlsEnabledValue
                                }
                                key={`form-row-${collector.tagId}`}
                            />
                        );
                    })}
                    <Pane display="flex" alignItems="center">
                        <Pane>
                            <ActionButton
                                aria-label="add data type"
                                appearance="primary"
                                data-testid="add-data-btn"
                                height={40}
                                icon={<PlusIcon color="#fff" />}
                                intent="action"
                                marginTop="1rem"
                                onClick={insertCollectorInState}
                                shadow="show"
                                shape="circle"
                                type="icon"
                            />
                        </Pane>
                    </Pane>
                </Pane>
            </Pane>
            <FlexBox margin="2vh 0">
                <FlexSection>
                    <TextareaField
                        isInvalid={invalidTls}
                        style={{ height: '45vh' }}
                        height="45vh"
                        label="TLS Certificate"
                        value={state.tlsCert}
                        disabled={!showFormFields}
                        onChange={handleUpdateTlsCert}
                        validationMessage={
                            invalidTls
                                ? 'A TLS certificate is required when TLS is enabled on a forwarder.'
                                : undefined
                        }
                        data-testid="tls-cert-input"
                    />
                </FlexSection>
                <FlexSection>
                    <TextareaField
                        isInvalid={invalidTls}
                        style={{ height: '45vh' }}
                        label="TLS Key"
                        value={state.tlsKey}
                        disabled={!showFormFields}
                        onChange={handleUpdateTlsKey}
                        validationMessage={
                            invalidTls
                                ? 'A TLS private key is required when TLS is enabled on a forwarder.'
                                : undefined
                        }
                        data-testid="tls-key-input"
                    />
                </FlexSection>
            </FlexBox>
        </>
    );
}

export default SyslogCollectorsSection;
