import React, { createContext, useReducer } from 'react';

const initialState = {
    lookerModalIsShown: true,
};

export const HIDE_LOOKER_MODAL = 'HIDE_LOOKER_MODAL';
export const StoreContext = createContext(initialState);
const { Provider } = StoreContext;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        const currentState = { ...state };
        switch (action.type) {
            case HIDE_LOOKER_MODAL:
                currentState.lookerModalIsShown = false;
                return currentState;
            default:
                currentState.lookerModalIsShown = true;
                return currentState;
        }
    }, initialState);
    return <Provider value={{ state, dispatch }}> {children}</Provider>;
};

export default StateProvider;
