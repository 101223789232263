import React, { useState } from 'react';
import { Dialog } from 'evergreen-ui';
import { pathOr, propOr, map, mergeDeepLeft } from 'ramda';
import { Redirect } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import CommonForwarderFormInputs from '../../../../components/CommonForwarderFormInputs';
import useCreateChronicleForwarderMutation from '../../../../hooks/useCreateChronicleForwarderMutation';
import useTenant from '../../../../hooks/useTenant';
import useQueryTenantChronicleIdentities from '../../../../hooks/useQueryTenantChronicleIdentities';
import useQueryTenantClusters from '../../../../hooks/useQueryTenantClusters';
import { anyIsLoading, isNotEmptyOrNil } from '../../../../utils/common';
import {
    dissocTypename,
    dissocTypenamesFromCollectorsArray,
} from '../../../../utils/forwarders';

const replaceTagId = (collector) => {
    return mergeDeepLeft({ tagId: uuidv1() }, collector);
};

const replaceAllTagIds = map(replaceTagId);

function CloneModal({ isShown, onCloseComplete, chronicleForwarder }) {
    const [tenant] = useTenant();
    const tenantId = propOr('', 'id', tenant);

    const queryTenantChronicleIdentitiesRes = useQueryTenantChronicleIdentities(
        tenantId,
    );
    const queryTenantClustersRes = useQueryTenantClusters(tenantId);
    const chronicleIdentities = propOr(
        [],
        'data',
        queryTenantChronicleIdentitiesRes,
    );
    const clusters = propOr([], 'data', queryTenantClustersRes);

    const [forwarderName, setForwarderName] = useState('');
    const [selectedCluster, setSelectedCluster] = useState('');
    const [selectedIdentity, setSelectedIdentity] = useState('');

    const { configuration } = chronicleForwarder;
    const infrastructure = dissocTypename(configuration.infrastructure);
    const _collectors = dissocTypenamesFromCollectorsArray(
        configuration.collectors,
    );
    const collectors = replaceAllTagIds(_collectors);

    const copiedConfiguration = {
        infrastructure,
        collectors,
    };

    const [
        createForwarder,
        createForwarderResponse,
    ] = useCreateChronicleForwarderMutation({
        forwarderName,
        clusterId: selectedCluster,
        forwarderConfiguration: copiedConfiguration,
        identityId: selectedIdentity,
        organizationId: tenantId,
    });

    if (isNotEmptyOrNil(createForwarderResponse.data.deployment)) {
        const deploymentLogId = pathOr(
            '',
            ['data', 'deployment', 'forwarder', 'deployApp', 'id'],
            createForwarderResponse,
        );
        const forwarderId = pathOr(
            '',
            ['data', 'forwarder', 'createForwarder', 'id'],
            createForwarderResponse,
        );
        return (
            <Redirect
                to={`/admin/forwarders/chronicle/${tenantId}/${deploymentLogId}/${forwarderId}`}
            />
        );
    }

    return (
        <>
            <Dialog
                isShown={isShown}
                onCloseComplete={onCloseComplete}
                onConfirm={createForwarder}
                isConfirmLoading={anyIsLoading([createForwarderResponse])}
            >
                <CommonForwarderFormInputs
                    name={forwarderName}
                    setName={setForwarderName}
                    clusters={clusters}
                    selectedCluster={selectedCluster}
                    setSelectedCluster={setSelectedCluster}
                    identities={chronicleIdentities}
                    selectedIdentity={selectedIdentity}
                    setSelectedIdentity={setSelectedIdentity}
                />
            </Dialog>
        </>
    );
}

export default CloneModal;
