import React, { useCallback, useEffect, useState } from 'react';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    Button,
    Pane,
    Table,
} from 'evergreen-ui';
import { always, ifElse, is, filter, lt, pathOr, range, slice } from 'ramda';
import PropTypes from 'prop-types';

import { someObjectsInclude } from '../../utils/common';

export default function SearchableTable({
    allowSearch,
    columns,
    data,
    maxRows,
    pagination,
    ...rest
}) {
    const [tableData, setTableData] = useState([]);
    const [tableOffset, setTableOffset] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [visiblePages, setVisiblePages] = useState([]);

    const getTotalPages = (value) => Math.ceil(value / maxRows);
    const totalPages = ifElse(
        lt(maxRows),
        getTotalPages,
        always(1),
    )(data.length);

    let tableOffsetEnd = tableOffset + maxRows;
    if (!pagination) tableOffsetEnd = data.length; // ensures entire array is returned and no set number of rows are rendered if pagination is false
    const paginatedTableData = slice(tableOffset, tableOffsetEnd, tableData);

    const getVisiblePages = useCallback(
        (page, total = totalPages) => {
            if (total < 7) {
                return range(1, total + 1);
            } else if (page % 5 >= 0 && page > 4 && page + 2 < total) {
                return [1, page - 1, page, page + 1, total];
            } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
                return [1, total - 3, total - 2, total - 1, total];
            } else {
                return [1, 2, 3, 4, 5, total];
            }
        },
        [totalPages],
    );

    const changePage = (page) => {
        if (page === activePage) return;

        setActivePage(page);
        setVisiblePages(getVisiblePages(page));
        setTableOffset((page - 1) * maxRows);
    };

    const handleSearch = (searchInputValue) => {
        const dataWithSomeMatchingText = filter(
            someObjectsInclude(searchInputValue),
        )(data);
        setTableData(dataWithSomeMatchingText);
    };

    useEffect(() => {
        setTableData(data);
        setTableOffset(0);
        setActivePage(1);
        setVisiblePages(getVisiblePages(1));
    }, [data, getVisiblePages]);

    return (
        <>
            <Table {...rest}>
                <Table.Head>
                    {columns.map((column, index) => {
                        if (!allowSearch || index !== 0) {
                            return (
                                <Table.TextHeaderCell
                                    key={column.title || `title-${index}`}
                                >
                                    {column.title}
                                </Table.TextHeaderCell>
                            );
                        }
                        return (
                            <Table.SearchHeaderCell
                                key={`${column.title}-${column.index}`}
                                onChange={handleSearch}
                                placeholder={`Filter by ${column.title}...`}
                            />
                        );
                    })}
                </Table.Head>
                <Table.Body>
                    {paginatedTableData.map((row, index) => {
                        return (
                            <Table.Row
                                key={`table-row-${index}`}
                                data-testid="table-row"
                            >
                                {columns.map((column, columnIndex) => {
                                    if (!column.render) {
                                        const value = pathOr(
                                            null,
                                            column.path,
                                            row,
                                        );
                                        return (
                                            <Table.TextCell
                                                key={`${columnIndex}-textcell-row`}
                                            >
                                                {is(Object, value)
                                                    ? null
                                                    : value}
                                            </Table.TextCell>
                                        );
                                    }
                                    return (
                                        <Table.TextCell
                                            key={`${columnIndex}-textcell-row`}
                                        >
                                            {is(Function, column.render)
                                                ? column.render(row)
                                                : column.render}
                                        </Table.TextCell>
                                    );
                                })}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            {pagination && (
                <Pane display="flex" justifyContent="flex-end" marginTop="1vh">
                    <Button
                        appearance="minimal"
                        onClick={() => changePage(activePage - 1)}
                        disabled={activePage === 1}
                        marginRight="0.25vw"
                        data-testid="previous-button"
                    >
                        <ArrowLeftIcon />
                    </Button>
                    {visiblePages.map((page, index, array) => (
                        <Button
                            key={page}
                            isActive={page === activePage}
                            appearance="minimal"
                            onClick={() => changePage(page)}
                        >
                            {array[index - 1] + 2 < page ? `...${page}` : page}
                        </Button>
                    ))}
                    <Button
                        onClick={() => changePage(activePage + 1)}
                        appearance="minimal"
                        disabled={activePage === totalPages}
                        marginLeft="0.25vw"
                        data-testid="next-button"
                    >
                        <ArrowRightIcon />
                    </Button>
                </Pane>
            )}
        </>
    );
}

SearchableTable.propTypes = {
    allowSearch: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            path: PropTypes.arrayOf(PropTypes.string), // see ramda path and pathOr
            render: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        }),
    ),
    data: PropTypes.array,
    maxRows: PropTypes.number, // maximum num of rows in table, pagination must be set to true in order to work
    pagination: PropTypes.bool, // boolean value to include pagination functionality
};

SearchableTable.defaultProps = {
    allowSearch: false,
    columns: [],
    data: [],
    maxRows: 10,
    pagination: true,
};
