import useJwt from '../useJwt';
import { isInternalUser } from '../../utils/auth';

function useVerifyIsInternalUser() {
    const response = useJwt();

    const [{ jwt }] = response;
    const jwtBelongsToInternalUser = isInternalUser(jwt);

    return jwtBelongsToInternalUser;
}

export default useVerifyIsInternalUser;
