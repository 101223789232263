import { gql } from '@apollo/client';

import {
    CDP_DEPLOYMENT_FRAGMENT,
    CHRONICLE_DEPLOYMENT_FRAGMENT,
} from './fragments';

export const QUERY_ALL_ORGANIZATIONS_DEPLOYMENTS = gql`
    query queryAllOrganizations {
        organizations(cursor: { limit: 0 }) {
            id
            name
            forwarders(cursor: { limit: 0 }) {
                id
                deploymentStatus {
                    status
                }
            }
            cdpForwarders(cursor: { limit: 0 }) {
                id
                cdpDeploymentStatus {
                    status
                }
            }
        }
    }
`;

export const QUERY_ALL_ORGANIZATIONS = gql`
    query queryAllOrganizations {
        organizations(cursor: { limit: 0 }) {
            id
            name
            pointOfContact {
                name
                email
                phone
            }
        }
    }
`;

export const QUERY_ALL_CLUSTERS = gql`
    query queryAllClusters {
        clusters(cursor: { limit: 0 }, query: { isActive: true }) {
            id
            name
            updatedAt
            updatedBy
            description
            ucn
            organization {
                id
                name
            }
        }
    }
`;

export const QUERY_ALL_FORWARDERS = gql`
    query queryAllForwarders {
        forwarders(cursor: { limit: 0 }) {
            id
            name
            createdAt
            createdBy
            organization {
                id
                name
            }
            deploymentStatus {
                ...ChronicleDeployment
            }
        }
        cdpForwarders(cursor: { limit: 0 }) {
            id
            name
            createdAt
            createdBy
            organization {
                id
                name
            }
            cdpDeploymentStatus {
                ...CDPDeployment
            }
        }
    }
    ${CDP_DEPLOYMENT_FRAGMENT}
    ${CHRONICLE_DEPLOYMENT_FRAGMENT}
`;

export const QUERY_ALL_ORG_FORWARDERS = gql`
    query queryAllTenantForwarders($id: ID!) {
        organization(id: $id) {
            id
            name
            forwarders(cursor: { limit: 0 }) {
                id
                name
                createdAt
                createdBy
                organization {
                    id
                    name
                }
                deploymentStatus {
                    ...ChronicleDeployment
                }
            }
            cdpForwarders(cursor: { limit: 0 }) {
                id
                name
                createdAt
                createdBy
                organization {
                    id
                    name
                }
                cdpDeploymentStatus {
                    ...CDPDeployment
                }
            }
        }
    }
    ${CDP_DEPLOYMENT_FRAGMENT}
    ${CHRONICLE_DEPLOYMENT_FRAGMENT}
`;
