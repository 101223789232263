import {
    assocPath,
    curry,
    dissoc,
    dissocPath,
    findIndex,
    map,
    mergeDeepLeft,
    omit,
    pathEq,
    propOr,
    propEq,
    remove,
    when,
    update,
} from 'ramda';
import { v1 as uuidv1 } from 'uuid';

import { createReducer } from '../../../../reducers/utils';
import { filterValueFromArray } from '../../../../utils/common';
import {
    dissocTypename,
    dissocTypenamesFromCollectorsArray,
    filterCollectorsWithTls,
} from '../../../../utils/forwarders';

export const initialState = {
    collectors: [],
    destinationRef: [],
    forwarderId: null,
    infrastructure: {
        replicaCount: 1,
        workerCount: 1,
        bufferTime: 0,
        batchFullThreshold: 0.8,
        flushThreadCount: 8,
    },
    metadata: {
        namespace: '',
        labels: [],
    },
    name: null,
    tlsCert: null,
    tlsKey: null,
    isValid: true,
};

/* base collector values when adding new collector to state */
const baseCdpSyslogCollector = {
    collectorType: 'syslog',
    syslog: {
        port: '',
        tlsEnabled: false,
        tlsRawCert: null,
        tlsRawKey: null,
    },
    common: {
        enabled: true,
        dataType: null,
        dataHint: null,
        batchEveryBytes: 1048576,
        batchEverySeconds: 10,
        batchFullThreshold: 0.8,
        flushThreadCount: 8,
        comment: '',
        destinationRef: [],
        metadata: {
            namespace: '',
            labels: [],
        },
    },
};

/****** HELPERS ******/
export const addUniqueTagId = (baseValues) => {
    const tagId = uuidv1();
    const valuesWithTagId = mergeDeepLeft(baseValues, { tagId });
    return valuesWithTagId;
};
export const mergeEnabledCollectorsWithTlsCredentials = (
    tlsRawCert,
    tlsRawKey,
    collectors,
) => {
    const tlsCreds = {
        syslog: {
            tlsRawCert,
            tlsRawKey,
        },
    };
    const mergeWithTlsCreds = mergeDeepLeft(tlsCreds);
    const isTlsEnabled = pathEq(['syslog', 'tlsEnabled'], true);
    const condMergeWithCreds = when(isTlsEnabled, mergeWithTlsCreds);
    const updatedCollectors = map(condMergeWithCreds, collectors);
    return updatedCollectors;
};

/****** REDUCER FUNCTIONS ******/
export const resetState = () => {
    return initialState;
};

export const mapRemoteForwarderToState = (state, action) => {
    const { id, configuration, name } = action.payload;
    const forwarderId = id;
    const namespace = configuration?.metadata?.namespace || '';
    const _labels = configuration?.metadata?.labels || [];
    const labels = _labels.map((label) => omit(['__typename'], label));
    const metadata = { labels, namespace };
    const _collectors = propOr([], 'collectors', configuration);
    const infrastructure = dissocTypename(configuration.infrastructure);
    const collectors = dissocTypenamesFromCollectorsArray(_collectors).map(
        (_collector) => {
            const collector = dissocPath(
                ['common', 'metadata', '__typename'],
                _collector,
            );
            const _labels = collector.common?.metadata?.labels || [];
            const labels = _labels.map((label) => dissoc('__typename', label));
            const metadata = {
                namespace: collector.common?.metadata?.namespace,
                labels,
            };
            return mergeDeepLeft({ common: { metadata } }, collector);
        },
    );
    const collectorsWithTlsEnabled = filterCollectorsWithTls(collectors);
    const destinationRef = propOr([], 'destinationRef', configuration);

    let tlsCert = null;
    let tlsKey = null;
    if (collectorsWithTlsEnabled.length) {
        const [collectorWithTlsValues] = collectorsWithTlsEnabled;
        tlsCert = collectorWithTlsValues.syslog.tlsRawCert;
        tlsKey = collectorWithTlsValues.syslog.tlsRawKey;
    }

    const remoteValues = {
        name,
        collectors,
        destinationRef,
        forwarderId,
        infrastructure,
        metadata,
        tlsCert,
        tlsKey,
    };

    return mergeDeepLeft(remoteValues, state);
};

export const updateStateByPath = curry((path, state, action) => {
    const value = assocPath(path, action.payload, {});
    const newState = mergeDeepLeft(value, state);
    return newState;
});

export const insertCollectorInState = curry((baseCollector, state) => {
    const collector = addUniqueTagId(baseCollector);
    const collectors = [...state.collectors, collector];
    return mergeDeepLeft({ collectors }, state);
});

export const removeCollectorFromState = (state, action) => {
    const { collector } = action.payload;
    const { tagId } = collector;
    const collectors = filterValueFromArray(tagId, state.collectors, ['tagId']);
    return mergeDeepLeft({ collectors }, state);
};

export const updateCollector = curry((key, subKey, state, action) => {
    const { collector, value } = action.payload;
    const updatedCollector = mergeDeepLeft(
        {
            [key]: {
                [subKey]: value,
            },
        },
        collector,
    );
    const propEqsTagId = propEq('tagId', collector.tagId);
    const collectorIndex = findIndex(propEqsTagId, state.collectors);
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
});

export const addCredentialsToCollectors = (state) => {
    const collectors = mergeEnabledCollectorsWithTlsCredentials(
        state.tlsCert,
        state.tlsKey,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const addForwarderDestinationRef = (state, action) => {
    const { payload } = action;
    const withNewDestination = [...state.destinationRef, payload];
    return mergeDeepLeft({ destinationRef: withNewDestination }, state);
};
export const removeForwarderDestinationRef = (state, action) => {
    const { payload } = action;
    const withoutValue = filterValueFromArray(payload, state.destinationRef);
    return mergeDeepLeft({ destinationRef: withoutValue }, state);
};
export const addCollectorDestinationRef = (state, action) => {
    const { collector, value } = action.payload;
    const { destinationRef } = collector.common;
    const withValue = [...destinationRef, value];
    const updatedCollector = mergeDeepLeft(
        { common: { destinationRef: withValue } },
        collector,
    );
    const propEqsTagId = propEq('tagId', collector.tagId);
    const collectorIndex = findIndex(propEqsTagId, state.collectors);
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const removeCollectorDestinationRef = (state, action) => {
    const { collector, value } = action.payload;
    const { destinationRef } = collector.common;
    const withoutValue = filterValueFromArray(value, destinationRef);
    const updatedCollector = mergeDeepLeft(
        { common: { destinationRef: withoutValue } },
        collector,
    );
    const propEqsTagId = propEq('tagId', collector.tagId);
    const collectorIndex = findIndex(propEqsTagId, state.collectors);
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const updateForwarderNamespace = (state, action) => {
    const { value } = action.payload;
    const _metadata = state?.metadata || {};
    const metadata = mergeDeepLeft({ namespace: value }, _metadata);
    return mergeDeepLeft({ metadata }, state);
};
export const updateCollectorNamespace = (state, action) => {
    const { collector, value } = action.payload;
    const _metadata = collector.common?.metadata || {};
    const metadata = mergeDeepLeft({ namespace: value }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const addForwarderLabel = (state, action) => {
    const label = { key: '', value: '' };
    const _labels = state?.metadata?.labels || [];
    const labels = [..._labels, label];

    return mergeDeepLeft({ metadata: { labels } }, state);
};
export const removeForwarderLabel = (state, action) => {
    const { index } = action.payload;
    const labels = remove(index, 1, state.metadata.labels);

    return mergeDeepLeft({ metadata: { labels } }, state);
};
export const updateForwarderLabel = (state, action) => {
    const { index, field, value } = action.payload;
    const _labels = state.metadata.labels;
    const label = mergeDeepLeft(
        {
            [field]: value,
        },
        _labels[index],
    );
    const labels = update(index, label, _labels);

    return mergeDeepLeft({ metadata: { labels } }, state);
};
export const addCollectorLabel = (state, action) => {
    const { collector } = action.payload;
    const currentLabels = collector.common?.metadata?.labels || [];
    const updatedLabels = [...currentLabels, { key: '', value: '' }];
    const _metadata = collector.common?.metadata || {};
    const metadata = mergeDeepLeft({ labels: updatedLabels }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const removeCollectorLabel = (state, action) => {
    const { collector, index } = action.payload;
    const labels = remove(index, 1, collector.common.metadata.labels);
    const _metadata = collector.common?.metadata || {};
    const metadata = mergeDeepLeft({ labels }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};
export const updateCollectorLabel = (state, action) => {
    const { collector, index, field, value } = action.payload;
    const _labels = collector.common.metadata.labels;
    const label = mergeDeepLeft(
        {
            [field]: value,
        },
        _labels[index],
    );
    const labels = update(index, label, _labels);
    const _metadata = collector.common.metadata;
    const metadata = mergeDeepLeft({ labels }, _metadata);
    const common = mergeDeepLeft({ metadata }, collector.common);
    const updatedCollector = mergeDeepLeft({ common }, collector);
    const collectorIndex = findIndex(
        propEq('tagId', collector.tagId),
        state.collectors,
    );
    const collectors = update(
        collectorIndex,
        updatedCollector,
        state.collectors,
    );
    return mergeDeepLeft({ collectors }, state);
};

export const insertBaseCdpCollector = insertCollectorInState(
    baseCdpSyslogCollector,
);
export const updateBufferTime = updateStateByPath([
    'infrastructure',
    'bufferTime',
]);
export const updateReplicaCount = updateStateByPath([
    'infrastructure',
    'replicaCount',
]);
export const updateWorkerCount = updateStateByPath([
    'infrastructure',
    'workerCount',
]);
export const updateBatchFullThreshold = updateStateByPath([
    'infrastructure',
    'batchFullThreshold',
]);
export const updateFlushThreadCount = updateStateByPath([
    'infrastructure',
    'flushThreadCount',
]);
export const updateCollectorCommonValues = updateCollector('common');
export const updateCollectorDataType = updateCollectorCommonValues('dataType');
export const updateCollectorComment = updateCollectorCommonValues('comment');
export const updateSyslogCollectorValues = updateCollector('syslog');
export const updateSyslogPortValue = updateSyslogCollectorValues('port');
export const updateSyslogTlsEnabledValue = updateSyslogCollectorValues(
    'tlsEnabled',
);
export const updateTlsCertValue = updateStateByPath(['tlsCert']);
export const updateTlsKeyValue = updateStateByPath(['tlsKey']);

/****** ACTIONS ******/
export const actions = {
    ADD_COLLECTOR_DESTINATION_REF: Symbol('ADD_COLLECTOR_DESTINATION_REF'),
    ADD_COLLECTOR_LABEL: Symbol('ADD_COLLECTOR_LABEL'),
    ADD_CREDENTIALS_TO_COLLECTORS: Symbol('ADD_CREDENTIALS_TO_COLLECTORS'),
    ADD_FORWARDER_DESTINATION_REF: Symbol('ADD_FORWARDER_DESTINATION_REF'),
    ADD_FORWARDER_LABEL: Symbol('ADD_FORWARDER_LABEL'),
    INSERT_BASE_CDP_COLLECTOR: Symbol('INSERT_BASE_CDP_COLLECTOR'),
    MAP_REMOTE_FORWARDER_TO_STATE: Symbol('MAP_REMOTE_FORWARDER_TO_STATE'),
    REMOVE_COLLECTOR: Symbol('REMOVE_COLLECTOR'),
    REMOVE_COLLECTOR_DESTINATION_REF: Symbol(
        'REMOVE_COLLECTOR_DESTINATION_REF',
    ),
    REMOVE_COLLECTOR_LABEL: Symbol('REMOVE_COLLECTOR_LABEL'),
    REMOVE_FORWARDER_DESTINATION_REF: Symbol(
        'REMOVE_FORWARDER_DESTINATION_REF',
    ),
    REMOVE_FORWARDER_LABEL: Symbol('REMOVE_FORWARDER_LABEL'),
    RESET_STATE: Symbol('RESET_STATE'),
    UPDATE_BUFFER_TIME: Symbol('UPDATE_BUFFER_TIME'),
    UPDATE_COMMON_COLLECTOR_COMMENT: Symbol('UPDATE_COMMON_COLLECTOR_COMMENT'),
    UPDATE_COMMON_COLLECTOR_DATA_TYPE: Symbol(
        'UPDATE_COMMON_COLLECTOR_DATA_TYPE',
    ),
    UPDATE_COMMON_COLLECTOR_LABEL: Symbol('UPDATE_COMMON_COLLECTOR_LABEL'),
    UPDATE_FORWARDER_LABEL: Symbol('UPDATE_FORWARDER_LABEL'),
    UPDATE_REPLICA_COUNT: Symbol('UPDATE_REPLICA_COUNT'),
    UPDATE_SYSLOG_COLLECTOR_NAMESPACE: Symbol(
        'UPDATE_SYSLOG_COLLECTOR_NAMESPACE',
    ),
    UPDATE_SYSLOG_COLLECTOR_PORT: Symbol('UPDATE_SYSLOG_COLLECTOR_PORT'),
    UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED: Symbol(
        'UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED',
    ),
    UPDATE_FORWARDER_NAMESPACE: Symbol('UPDATE_FORWARDER_NAMESPACE'),
    UPDATE_TLS_CERT: Symbol('UPDATE_TLS_CERT'),
    UPDATE_TLS_KEY: Symbol('UPDATE_TLS_KEY'),
    UPDATE_WORKER_COUNT: Symbol('UPDATE_WORKER_COUNT'),
    UPDATE_BATCH_FULL_THRESHOLD: Symbol('UPDATE_BATCH_FULL_THRESHOLD'),
    UPDATE_FLUSH_THREAD_COUNT: Symbol('UPDATE_FLUSH_THREAD_COUNT'),
};

/****** ACTION HANDLERS ******/
export const handlers = {
    [actions.ADD_COLLECTOR_DESTINATION_REF]: addCollectorDestinationRef,
    [actions.ADD_COLLECTOR_LABEL]: addCollectorLabel,
    [actions.ADD_CREDENTIALS_TO_COLLECTORS]: addCredentialsToCollectors,
    [actions.ADD_FORWARDER_DESTINATION_REF]: addForwarderDestinationRef,
    [actions.ADD_FORWARDER_LABEL]: addForwarderLabel,
    [actions.INSERT_BASE_CDP_COLLECTOR]: insertBaseCdpCollector,
    [actions.MAP_REMOTE_FORWARDER_TO_STATE]: mapRemoteForwarderToState,
    [actions.REMOVE_COLLECTOR]: removeCollectorFromState,
    [actions.REMOVE_COLLECTOR_DESTINATION_REF]: removeCollectorDestinationRef,
    [actions.REMOVE_COLLECTOR_LABEL]: removeCollectorLabel,
    [actions.REMOVE_FORWARDER_DESTINATION_REF]: removeForwarderDestinationRef,
    [actions.REMOVE_FORWARDER_LABEL]: removeForwarderLabel,
    [actions.RESET_STATE]: resetState,
    [actions.UPDATE_BUFFER_TIME]: updateBufferTime,
    [actions.UPDATE_COMMON_COLLECTOR_COMMENT]: updateCollectorComment,
    [actions.UPDATE_COMMON_COLLECTOR_DATA_TYPE]: updateCollectorDataType,
    [actions.UPDATE_COMMON_COLLECTOR_LABEL]: updateCollectorLabel,
    [actions.UPDATE_FORWARDER_LABEL]: updateForwarderLabel,
    [actions.UPDATE_FORWARDER_NAMESPACE]: updateForwarderNamespace,
    [actions.UPDATE_REPLICA_COUNT]: updateReplicaCount,
    [actions.UPDATE_SYSLOG_COLLECTOR_NAMESPACE]: updateCollectorNamespace,
    [actions.UPDATE_SYSLOG_COLLECTOR_PORT]: updateSyslogPortValue,
    [actions.UPDATE_SYSLOG_COLLECTOR_TLS_ENABLED]: updateSyslogTlsEnabledValue,
    [actions.UPDATE_TLS_CERT]: updateTlsCertValue,
    [actions.UPDATE_TLS_KEY]: updateTlsKeyValue,
    [actions.UPDATE_WORKER_COUNT]: updateWorkerCount,
    [actions.UPDATE_BATCH_FULL_THRESHOLD]: updateBatchFullThreshold,
    [actions.UPDATE_FLUSH_THREAD_COUNT]: updateFlushThreadCount,
};

/****** REDUCER ******/
export const reducer = createReducer(initialState, handlers);
