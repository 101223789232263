import { useMutation } from '@apollo/client';
import { toaster } from 'evergreen-ui';
import { prop } from 'ramda';
import { useHistory } from 'react-router-dom';

import { QUERY_ALL_ORGANIZATIONS } from '../../../graphql/queries';
import useQuerySelector from '../../../hooks/useQuerySelector';
import { anyIsLoading } from '../../../utils/common';
import {
    DELETE_ORGANIZATION,
    QUERY_ORGANIZATION,
    UPDATE_ORGANIZATION,
} from './Organization-graphql';

export const useQueryOrganization = (id) => {
    const organizationResponse = useQuerySelector(
        QUERY_ORGANIZATION,
        { variables: { id } },
        prop('organization'),
    );

    let error = null;
    if (organizationResponse.error) {
        error = organizationResponse.error;
    }

    return {
        data: {
            organization: organizationResponse.data,
        },
        loading: anyIsLoading([organizationResponse]),
        error,
    };
};

export const useUpdateOrganization = () => {
    const [mutate, response] = useMutation(UPDATE_ORGANIZATION, {
        onError: () => {
            toaster.danger('ERROR: Unable to save.');
        },
        onCompleted: () => {
            toaster.success('Success!');
        },
    });

    return [mutate, response];
};

export const useDeleteOrganization = () => {
    const history = useHistory();
    const [mutate, response] = useMutation(DELETE_ORGANIZATION, {
        onError: () => {
            toaster.danger('ERROR: Unable to delete.');
        },
        onCompleted: () => {
            history.push(`/admin/organizations`);
            toaster.success('Successfully deleted organization.');
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: QUERY_ALL_ORGANIZATIONS,
                variables: {
                    fetchPolicy: 'network-only',
                },
            },
        ],
    });

    return [mutate, response];
};
