import { useQuery } from '@apollo/client';
import { pathOr } from 'ramda';

import { QUERY_TENANT_CLOUD_FORWARDERS } from './graphql';

export const useCloudForwarders = (id) => {
    const { data, loading, error } = useQuery(QUERY_TENANT_CLOUD_FORWARDERS, {
        variables: { id },
        skip: !id,
    });

    const cdp = pathOr([], ['organization', 'cdpCloudForwarders'], data);
    const chroniclePcap = pathOr(
        [],
        ['organization', 'chronicleCloudPcapForwarders'],
        data,
    );
    const chronicleSplunk = pathOr(
        [],
        ['organization', 'chronicleCloudSplunkForwarders'],
        data,
    );
    const chronicleSyslog = pathOr(
        [],
        ['organization', 'chronicleCloudSyslogForwarders'],
        data,
    );

    const meta = {
        error,
        loading,
    };

    const models = {
        cloudForwarders: [
            ...cdp,
            ...chroniclePcap,
            ...chronicleSplunk,
            ...chronicleSyslog,
        ],
    };

    return { meta, models };
};
