import { gql } from '@apollo/client';

export const QUERY_ORGANIZATION = gql`
    query organization($id: ID!) {
        organization(id: $id) {
            id
            name
            pointOfContact {
                name
                phone
                email
            }
            organizationURLs {
                chronicleURL
                canaryURL
                smartsheetURL
            }
            kibanaSpaceIdentifier
            authGroupName
            lookerConfig {
                model
            }
        }
    }
`;

export const UPDATE_ORGANIZATION = gql`
    mutation updateOrganization(
        $organizationId: ID!
        $organizationProps: UpdateOrganizationInput!
        $authGroupNameProp: String!
    ) {
        organization(id: $organizationId) {
            update(props: $organizationProps) {
                id
                name
                pointOfContact {
                    name
                    phone
                    email
                }
                organizationURLs {
                    chronicleURL
                    canaryURL
                    smartsheetURL
                }
                kibanaSpaceIdentifier
                lookerConfig {
                    model
                }
            }
            assignAuthGroup(authGroupName: $authGroupNameProp) {
                id
                authGroupName
            }
        }
    }
`;

export const DELETE_ORGANIZATION = gql`
    mutation deleteOrganization($id: ID!) {
        organization(id: $id) {
            remove
        }
    }
`;
