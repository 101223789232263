import { prop } from 'ramda';

import { QUERY_ALL_ORGANIZATIONS } from '../../graphql/queries';
import useQuerySelector from '../useQuerySelector';

const useQueryAllOrganizations = () => {
    const response = useQuerySelector(
        QUERY_ALL_ORGANIZATIONS,
        {},
        prop('organizations'),
    );

    return response;
};

export default useQueryAllOrganizations;
