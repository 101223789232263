const font = {};

const color = {
    primary: '#425A70',
    inverted: '#fff',
    dark: '#000',
    helper: '#a5a5a5',
};

font.color = color;

export default font;
