import { BanCircleIcon, TickCircleIcon } from 'evergreen-ui';
import { always, equals, ifElse, join } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';

import withRowDataAsProps from '../../../hocs/withRowDataAsProps';

export const buildDataSourcesColumns = (route) => {
    const buildUrl = (name) => {
        return `${route}/${name}`;
    };
    const NameCell = ({ name }) => {
        const url = buildUrl(name);
        return <Link to={url}>{name}</Link>;
    };

    const columns = [
        {
            title: 'Data Type',
            path: ['name'],
            render: withRowDataAsProps(NameCell),
        },
        {
            title: 'Collector Count',
            path: ['collectors'],
            render: (data) => data.collectors.length,
        },
    ];

    return columns;
};

export const buildDataSourceColumns = () => {
    const enabledIconCell = ({ enabled }) => {
        const iconType = ifElse(
            equals(true),
            always(<TickCircleIcon color="success" />),
            always(<BanCircleIcon color="danger" />),
        );
        const Icon = iconType(enabled);

        return Icon;
    };

    const destinationCell = ({ destinationRef }) => {
        const separator = join(', ');
        const destinationString = separator(destinationRef);
        return destinationString;
    };

    const columns = [
        {
            title: 'Forwarder Name',
            path: ['forwarderName'],
        },
        {
            title: 'Collector Type',
            path: ['collectorType'],
        },
        {
            title: 'Enabled',
            path: ['enabled'],
            render: withRowDataAsProps(enabledIconCell),
        },
        {
            title: 'Destination',
            path: ['destinationRef'],
            render: withRowDataAsProps(destinationCell),
        },
        {
            title: 'Port',
            path: ['port'],
        },
    ];

    return columns;
};
