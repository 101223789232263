import { curry, identity, mergeDeepLeft, prop, propOr } from 'ramda';

import useQuerySelector from '../../../hooks/useQuerySelector';

import { QUERY_ALL_DEPLOYMENTS, QUERY_ORG_DEPLOYMENTS } from './graphql';
import { normalizeChronicleForwarders, normalizeCdpForwarders } from './utils';

/**
 * curried function queries all chronicle and cdp forwarder deployments and returns one normalized array
 */
const queryDeployments = curry((query, querySelector, queryVariables) => {
    const response = useQuerySelector(
        query,
        { variables: queryVariables },
        querySelector,
    );

    let data;
    if (response.data) {
        const chronicleForwarders = propOr([], 'forwarders', response.data);
        const cdpForwarders = propOr([], 'cdpForwarders', response.data);
        data = [
            ...normalizeChronicleForwarders(chronicleForwarders),
            ...normalizeCdpForwarders(cdpForwarders),
        ];
    }

    return mergeDeepLeft({ data }, response);
});

const useDeployments = queryDeployments(QUERY_ALL_DEPLOYMENTS, identity);
const useOrgDeployments = queryDeployments(
    QUERY_ORG_DEPLOYMENTS,
    prop('organization'),
);

export { useDeployments, useOrgDeployments };
export default queryDeployments;
