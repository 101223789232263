import Clusters from '../admin-portal/clusters/Clusters';
import Cluster from '../admin-portal/clusters/Cluster';

import { baseAdminPath } from './home-routes';

const basePath = `${baseAdminPath}/clusters`;

const routes = {};

routes.Clusters = {
    path: `${basePath}`,
    exact: true,
    admin: true,
    secure: true,
    component: Clusters,
};

routes.Cluster = {
    path: `${basePath}/:clusterId`,
    exact: true,
    admin: true,
    secure: true,
    component: Cluster,
};

export default routes;
