import { useQuery, useMutation } from '@apollo/client';
import { pathOr } from 'ramda';
import { useParams } from 'react-router-dom';

import {
    QUERY_CHRONICLE_FW_IDENTITY,
    UPDATE_CHRONICLE_FW_IDENTITY,
    DELETE_CHRONICLE_FW_IDENTITY,
} from './ChronicleForwarderIdentity-graphql';

const useQueryChronicleForwarderIdentity = (id) => {
    const response = useQuery(QUERY_CHRONICLE_FW_IDENTITY, {
        variables: { id },
    });
    return response;
};

const useUpdateChronicleForwarderIdentity = () => {
    const [mutate, response] = useMutation(UPDATE_CHRONICLE_FW_IDENTITY);
    return [mutate, response];
};

const useDeleteChronicleForwarderIdentity = () => {
    const [mutate, response] = useMutation(DELETE_CHRONICLE_FW_IDENTITY);
    return [mutate, response];
};

function useChronicleForwarderIdentity() {
    const { identityId } = useParams();
    const queryChronicleForwarderIdentityResponse = useQueryChronicleForwarderIdentity(
        identityId,
    );
    const [
        updateIdentity,
        updateIdentityResponse,
    ] = useUpdateChronicleForwarderIdentity();
    const [
        deleteIdentity,
        deleteIdentityResponse,
    ] = useDeleteChronicleForwarderIdentity();
    // models
    const chronicleForwarderIdentity = pathOr(
        {},
        ['data', 'chronicleForwarderIdentity'],
        queryChronicleForwarderIdentityResponse,
    );
    const models = { chronicleForwarderIdentity };

    //operations
    const update = (values) => {
        updateIdentity({
            variables: {
                id: identityId,
                props: values,
            },
        });
    };
    const deleteKey = () => {
        deleteIdentity({
            variables: {
                id: identityId,
            },
        });
    };
    const operations = { update, deleteKey };

    // meta
    const loading = queryChronicleForwarderIdentityResponse.loading;
    const loadError = queryChronicleForwarderIdentityResponse.error;
    const saving = updateIdentityResponse.loading;
    const saveError = updateIdentityResponse.error;
    const saveSuccess = Boolean(updateIdentityResponse.data);
    const deleting = deleteIdentityResponse.loading;
    const deleteError = deleteIdentityResponse.error;
    const deleteSuccess = Boolean(deleteIdentityResponse.data);
    const meta = {
        loading,
        loadError,
        saving,
        saveSuccess,
        saveError,
        deleting,
        deleteError,
        deleteSuccess,
    };

    return {
        meta,
        models,
        operations,
    };
}

export default useChronicleForwarderIdentity;
