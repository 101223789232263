import { path } from 'ramda';

import { QUERY_ALL_CLUSTERS } from '../../graphql/queries';
import useQuerySelector from '../useQuerySelector';

function useQueryAllClusters() {
    const response = useQuerySelector(
        QUERY_ALL_CLUSTERS,
        {},
        path(['clusters']),
    );

    return response;
}

export default useQueryAllClusters;
