import React, { useState } from 'react';
import { Dialog } from 'evergreen-ui';
import { pathOr, propOr, map, mergeDeepLeft } from 'ramda';
import { Redirect } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import useTenant from '../../../../hooks/useTenant';
import useQueryTenantCdpIdentities from '../../../../hooks/useQueryTenantCdpIdentities';
import useQueryTenantClusters from '../../../../hooks/useQueryTenantClusters';
import { anyIsLoading, isNotEmptyOrNil } from '../../../../utils/common';
import {
    dissocTypename,
    dissocTypenamesFromCollectorsArray,
} from '../../../../utils/forwarders';
import CommonForwarderFormInputs from '../../../../components/CommonForwarderFormInputs';
import useCreateCDPForwarderMutation from '../../../../hooks/useCreateCDPForwarderMutation';

const replaceTagId = (collector) => {
    return mergeDeepLeft({ tagId: uuidv1() }, collector);
};

const replaceCollectorMetadata = (collector) => {
    const { common, ...rest } = collector;
    const metadata = { labels: [], namespace: '' };

    return {
        ...rest,
        common: {
            ...common,
            metadata,
        },
    };
};

const replaceAllTagIds = map(replaceTagId);
const replaceAllMetadata = map(replaceCollectorMetadata);

function CloneModal({ isShown, onCloseComplete, cdpForwarder }) {
    const [tenant] = useTenant();
    const tenantId = propOr('', 'id', tenant);

    const queryTenantCdpIdentitiesRes = useQueryTenantCdpIdentities(tenantId);
    const queryTenantClustersRes = useQueryTenantClusters(tenantId);

    const [forwarderName, setForwarderName] = useState('');
    const [selectedCluster, setSelectedCluster] = useState('');
    const [selectedIdentity, setSelectedIdentity] = useState('');

    const cdpIdentities = propOr([], 'data', queryTenantCdpIdentitiesRes);
    const clusters = propOr([], 'data', queryTenantClustersRes);

    const { configuration } = cdpForwarder;
    const infrastructure = dissocTypename(configuration.infrastructure);
    const destinationRef = propOr([], 'destinationRef', configuration);
    const _collectors = dissocTypenamesFromCollectorsArray(
        configuration.collectors,
    );
    const collectors = replaceAllMetadata(replaceAllTagIds(_collectors));

    const copiedConfiguration = {
        infrastructure,
        collectors,
        destinationRef,
    };

    const [
        createCDPForwarder,
        createCDPForwarderResponse,
    ] = useCreateCDPForwarderMutation({
        forwarderName,
        forwarderConfiguration: copiedConfiguration,
        identityId: selectedIdentity,
        clusterId: selectedCluster,
        organizationId: tenantId,
    });

    if (isNotEmptyOrNil(createCDPForwarderResponse.data.deployment)) {
        const deploymentLogId = pathOr(
            '',
            ['data', 'deployment', 'cdpForwarder', 'deployCDPApp', 'id'],
            createCDPForwarderResponse,
        );
        const forwarderId = pathOr(
            '',
            ['data', 'cdpForwarder', 'createCDPForwarder', 'id'],
            createCDPForwarderResponse,
        );
        onCloseComplete();
        return (
            <Redirect
                to={`/admin/forwarders/cdp/${tenantId}/${deploymentLogId}/${forwarderId}`}
            />
        );
    }

    return (
        <>
            <Dialog
                isShown={isShown}
                onCloseComplete={onCloseComplete}
                onConfirm={createCDPForwarder}
                isConfirmLoading={anyIsLoading([createCDPForwarderResponse])}
            >
                <CommonForwarderFormInputs
                    name={forwarderName}
                    setName={setForwarderName}
                    clusters={clusters}
                    selectedCluster={selectedCluster}
                    setSelectedCluster={setSelectedCluster}
                    identities={cdpIdentities}
                    selectedIdentity={selectedIdentity}
                    setSelectedIdentity={setSelectedIdentity}
                />
            </Dialog>
        </>
    );
}

export default CloneModal;
