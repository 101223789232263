import { gql } from '@apollo/client';

export const CLOUD_SYSLOG_FW_FRAGMENT = gql`
    fragment CloudSyslogFwFragment on ChronicleCloudSyslogForwarder {
        configuration {
            client
            syslog {
                common {
                    batchEveryBytes
                    batchEverySeconds
                    dataType
                    enabled
                }
                port
                cloudTls
                connectionTimeoutSec
            }
            infrastructure {
                autoscaling {
                    enabled
                }
                hostname
                replicaCount
                resources {
                    limits {
                        cpu
                        memory
                    }
                    requests {
                        cpu
                        memory
                    }
                }
                serviceType
                workerCount
            }
        }
        kubernetesService
        identityId
        organizationId
        name
    }
`;

export const CREATE_SYSLOG_CLOUD_FW = gql`
    mutation createSyslogCloudFw($props: ChronicleCloudSyslogForwarderInput!) {
        createChronicleCloudSyslogForwarder(props: $props) {
            id
            ...CloudSyslogFwFragment
        }
    }
    ${CLOUD_SYSLOG_FW_FRAGMENT}
`;

export const REMOVE_SYSLOG_CLOUD_FW = gql`
    mutation removeSyslogCloudFw($id: ID!) {
        removeChronicleCloudSyslogForwarder(id: $id)
    }
`;

export const UPDATE_SYSLOG_CLOUD_FW = gql`
    mutation updateSyslogCloudFw(
        $id: ID!
        $props: ChronicleCloudSyslogForwarderInput!
    ) {
        updateChronicleCloudSyslogForwarder(id: $id, props: $props) {
            id
            ...CloudSyslogFwFragment
        }
    }
    ${CLOUD_SYSLOG_FW_FRAGMENT}
`;

export const QUERY_SYSLOG_CLOUD_FW = gql`
    query syslogCloudFw($id: ID!) {
        chronicleCloudSyslogForwarder(id: $id) {
            id
            ...CloudSyslogFwFragment
        }
    }
    ${CLOUD_SYSLOG_FW_FRAGMENT}
`;
