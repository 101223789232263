import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Heading, Button, Pane, Text } from 'evergreen-ui';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import dayjs from 'dayjs';

import FormSelectField from '../../../components/FormSelectField';
import HandleQueryResponse from '../../../components/HandleQueryResponse';
import PageContainer from '../../../components/PageContainer';
import {
    useMutateCluster,
    useQueryClusterAndOrganizations,
} from './Cluster-hooks';

const schema = yup.object().shape({
    organization: yup
        .string()
        .required()
        .matches(/^((?!default).)*$/, 'organization is a required field'), // ensure default option value is not selected on submit
});

const FormContainer = styled(Pane)`
    padding: 2vh;
    min-height: 200px;
    max-width: 60%;
    border: 1px solid #e4e7eb;
    box-shadow: rgb(227, 233, 243) 0px 2px 4px;
`;

function Cluster() {
    const { clusterId } = useParams();
    const response = useQueryClusterAndOrganizations(clusterId);
    const [mutate] = useMutateCluster();
    const { control, errors, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    const cluster = pathOr({}, ['data', 'cluster'], response);
    const organizations = pathOr([], ['data', 'organizations'], response);
    const sortedOrganizations = organizations
        .slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    const initialValues = {
        organization: pathOr(
            'default',
            ['data', 'cluster', 'organization', 'id'],
            response,
        ),
    };

    const onSubmit = (data) =>
        mutate({
            variables: {
                id: cluster.id,
                props: { organizationId: data.organization },
            },
        });

    return (
        <PageContainer>
            <HandleQueryResponse response={response}>
                <Pane paddingLeft="3vw" paddingRight="3vw">
                    <Heading size={800} marginBottom="1vh">
                        {cluster.name}
                    </Heading>
                    <Pane display="flex" flexDirection="row" marginBottom="2vh">
                        <Heading size={400}>
                            ucn: <Text>{cluster.ucn}</Text>
                        </Heading>
                        <Heading size={400} marginLeft="1vw">
                            last update:{' '}
                            <Text>
                                {dayjs(cluster.updatedAt).format('MM/DD/YYYY')}
                            </Text>
                        </Heading>
                        <Heading size={400} marginLeft="1vw">
                            updated by: <Text>{cluster.updatedBy}</Text>
                        </Heading>
                    </Pane>
                    <FormContainer>
                        <Heading size={400} marginBottom="2vh">
                            Organization
                        </Heading>
                        <FormSelectField
                            name="organization"
                            control={control}
                            defaultValue={initialValues.organization}
                            error={pathOr(
                                null,
                                ['organization', 'message'],
                                errors,
                            )}
                        >
                            <option value="default" key="default">
                                select an organization
                            </option>
                            {sortedOrganizations.map((organization) => (
                                <option
                                    value={organization.id}
                                    key={organization.id}
                                >
                                    {organization.name}
                                </option>
                            ))}
                        </FormSelectField>
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            appearance="primary"
                            intent="success"
                        >
                            Save
                        </Button>
                    </FormContainer>
                </Pane>
            </HandleQueryResponse>
        </PageContainer>
    );
}

export default Cluster;
